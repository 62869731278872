import MultimodalSOPService from "@/components/DMMSOP/DManageSmartInstruction/services/MultimodalSOPService";

// Initial state
const state = {
  currentTask: {
    title: "",
    description: "",
    coverImage: null,
    metadata: {},
    published: false,
    steps: [],
    introduction: null,
    conclusion: null,
    workflow_verification: null,
  },
  steps: [],
  isLoading: false,
  error: null,
};

// Getters
const getters = {
  getCurrentTask: (state) => state.currentTask,
  getSteps: (state) => state.steps,
  getIsLoading: (state) => state.isLoading,
  getError: (state) => state.error,
};

// Actions
const actions = {
  async initializeTask({ commit }, { workspaceId, type, workflowId }) {
    try {
      commit("SET_LOADING", true);
      if (workflowId) {
        const response = await MultimodalSOPService.getSOPById({
          id: workflowId,
          includeSubData: true,
        });

        if (response?.data?.item) {
          const task = response.data.item;
          commit("SET_CURRENT_TASK", task);
          commit("SET_STEPS", task.multimodalSopItems || []);
        }
      }
    } catch (error) {
      console.error("Error initializing task:", error);
      commit("SET_ERROR", error);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async saveTask({ state, commit }, { workspaceId, type, workflowId }) {
    try {
      commit("SET_LOADING", true);
      const taskToSave = {
        workspaceId,
        organizationId: state.currentTask.organizationId,
        type: "manually",
        languageCode: state.currentTask.languageCode || "en-us",
        title: state.currentTask.title,
        description: state.currentTask.description,
        poster: state.currentTask.coverImage?.url,
        multimodalSopItems: state.steps.map((step) => ({
          type: step.type,
          title: step.title,
          description: step.description || step.content,
          attachments: step.attachments || [],
        })),
      };

      const response = await MultimodalSOPService.updateSOP({
        id: workflowId,
        payload: taskToSave,
      });

      if (response?.data?.item) {
        commit("SET_CURRENT_TASK", response.data.item);
      }
    } catch (error) {
      console.error("Error saving task:", error);
      commit("SET_ERROR", error);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  setCurrentTask({ commit }, taskData) {
    if (!taskData) return;
    const task = JSON.parse(JSON.stringify(taskData));
    commit("SET_CURRENT_TASK", task);
    if (task.multimodalSopItems && Array.isArray(task.multimodalSopItems)) {
      commit("SET_STEPS", task.multimodalSopItems);
    }
  },

  setSteps({ commit }, steps) {
    if (!steps || !Array.isArray(steps)) return;
    const stepsCopy = JSON.parse(JSON.stringify(steps));
    commit("SET_STEPS", stepsCopy);
  },

  clearCurrentTask({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        commit("RESET_STATE");
        resolve();
      } catch (error) {
        console.error("Error in clearCurrentTask action:", error);
        reject(error);
      }
    });
  },

  resetState({ commit }) {
    commit("RESET_STATE");
  },

  setLoading({ commit }, isLoading) {
    commit("SET_LOADING", isLoading);
  },

  setError({ commit }, error) {
    commit("SET_ERROR", error);
  },

  async updateWorkflowVerification({ state, commit }, { content, description }) {
    try {
      if (!state.currentTask) return false;

      // Create a deep copy of the current task
      const updatedTask = JSON.parse(JSON.stringify(state.currentTask));

      // Find workflow verification in steps if it exists there
      const workflowVerificationIndex = updatedTask.multimodalSopItems?.findIndex(
        (step) => step.type === "workflow_verification"
      );

      if (workflowVerificationIndex !== -1) {
        // Update in steps array
        if (content !== undefined) {
          updatedTask.multimodalSopItems[workflowVerificationIndex].content = content;
        }
        if (description !== undefined) {
          updatedTask.multimodalSopItems[workflowVerificationIndex].description = description;
        }
      } else {
        // Update in root level
        if (!updatedTask.workflow_verification) {
          updatedTask.workflow_verification = {
            type: "workflow_verification",
            content: "",
            description: "",
          };
        }

        if (content !== undefined) {
          updatedTask.workflow_verification.content = content;
        }
        if (description !== undefined) {
          updatedTask.workflow_verification.description = description;
        }
      }

      updatedTask.updatedAt = new Date().toISOString();
      commit("SET_CURRENT_TASK", updatedTask);

      // If workflow verification was in steps, update steps as well
      if (workflowVerificationIndex !== -1) {
        commit("SET_STEPS", updatedTask.multimodalSopItems);
      }

      return true;
    } catch (error) {
      console.error("Error updating workflow verification:", error);
      return false;
    }
  },
};

// Mutations
const mutations = {
  SET_CURRENT_TASK(state, task) {
    state.currentTask = {
      ...state.currentTask,
      ...task,
      published: Boolean(task.published),
    };
  },

  SET_STEPS(state, steps) {
    try {
      state.steps = steps ? [...steps] : [];
    } catch (error) {
      console.error("Error in SET_STEPS mutation:", error);
      state.steps = [];
    }
  },

  RESET_STATE(state) {
    try {
      state.currentTask = {
        title: "",
        description: "",
        coverImage: null,
        metadata: {},
        published: false,
        steps: [],
        introduction: null,
        conclusion: null,
        workflow_verification: null,
      };
      state.steps = [];
      state.isLoading = false;
    } catch (error) {
      console.error("Error in RESET_STATE mutation:", error);
    }
  },

  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
