export const REFRESH_RECOMMENDATION_STRATEGY = {
  ALL: 1,
  CONTINUE_WATCHING_ONLY: 2,
};

/** map to BE recommend type */
export const RECOMMEND_TYPE = {
  CONTINUE_WATCHING: "continue-watching",
  TRENDING_WORKFLOWS: "hotitem",
  BASE_ON_HISTORY: "similarity",
  OTHERS_ALSO_VIEWED: "view-also-view",
  MOST_VIEWED: "most-viewed",
};
