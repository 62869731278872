import deephowApiServerWrapper from "@/server/api-server";
import services from "@/constants/env/services";
import axios from "axios";
import authChoice from "../../auth";
import { otherApisWrapperGenerator } from "./lib";

const analyticsServer = axios.create({
  baseURL: `${services.analytics}/v1`,
});

analyticsServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

const apiWrapper = otherApisWrapperGenerator(analyticsServer);

export const fetchWorkspaceStates = ({ page = 1, size = 500, workspaces }) =>
  apiWrapper("post", `workspaces/list?page=${page}&size=${size}`, { workspaces });

export const getMetabaseUrl = (params) => apiWrapper("post", "metabase/embedded-url", params);

export const getSkillPlaylistProgress = (params) => apiWrapper("post", "skills/assigned", params);

export const getSkillWorkflowProgress = ({ playlistId, ...params }) =>
  apiWrapper("post", `skills/${playlistId}/parts`, params);

export const getManySkillPlaylistProgress = (params) => apiWrapper("post", `skills/users-progress`, params);

export const getGroupUserStats = ({ groupId }) => apiWrapper("post", `workspaces/${groupId}/users`);

export const getOneWorkflowOverview = ({ workflowId }) => apiWrapper("post", `workflows/${workflowId}/overview`);

export const getOneUserOverview = ({ uid }) => apiWrapper("post", `users/${uid}/overview`);

export const getTopGroups = () => apiWrapper("post", "workspaces/top");

export const getTopUsers = () => apiWrapper("post", "users/top");

export const getWorkflowsOverview = (params) => apiWrapper("post", "workflows/overview", params);

export default analyticsServer;

/**  migrate to use deephow api */

export const getOrgOverview = () => deephowApiServerWrapper("get", "/v1/analytics/organizations/overview");

export const getOrgEngagement = () => deephowApiServerWrapper("get", "/v1/analytics/organizations/engagement");

export const getQuizOverview = (workspaceId = null) =>
  deephowApiServerWrapper("get", "/v1/analytics/quizzes/overview", { params: { workspaceId } });

export const getQuizWorkflows = (params) =>
  deephowApiServerWrapper("get", "/v1/analytics/quizzes/workflow-list", { params });

export const getQuizWorkflowOverview = (workflowId) =>
  deephowApiServerWrapper("get", `/v1/analytics/quizzes/${workflowId}/overview`);

export const getQuizWorkflowUsers = ({ quizId, ...params }) =>
  deephowApiServerWrapper("get", `/v1/analytics/quizzes/${quizId}/user-list`, { params });

export const getQuizWorkflowUser = ({ quizId, userId }) =>
  deephowApiServerWrapper("get", `/v1/analytics/quizzes/${quizId}/user/${userId}/result-history`);

// Analytics Users
export const getAnalyticsUserOverview = (params) =>
  deephowApiServerWrapper("get", "/v1/analytics/users/overview", { params });

export const getAnalyticsUserList = (params) => deephowApiServerWrapper("get", "/v1/analytics/users/list", { params });

export const getUserViewHistory = ({ userId }) => apiWrapper("post", `users/${userId}/viewhistory`);

export const getWorkflowsTrendList = (params) =>
  deephowApiServerWrapper("get", "/v1/analytics/workflows-trend/list", { params });

export const getWorkflowsTrendListExport = (params) =>
  deephowApiServerWrapper("post", "/v1/analytics/workflows-trend/file", params);

export const getWorkflowsDetailList = (params) =>
  deephowApiServerWrapper("get", "/v1/analytics/workflows-details/list", { params });

export const getWorkflowsDetailListExport = (params) =>
  deephowApiServerWrapper("post", "/v1/analytics/workflows-details/file", params);

export const getMMSOPsTrendList = (params) =>
  deephowApiServerWrapper("get", "/v1/analytics/multimodal-sops-trend/list", { params });

export const getMMSOPsTrendListExport = (params) =>
  deephowApiServerWrapper("post", "/v1/analytics/multimodal-sops-trend/file", params);

export const getMMSOPsDetailList = (params) =>
  deephowApiServerWrapper("get", "/v1/analytics/multimodal-sops-details/list", { params });

export const getMMSOPsDetailListExport = (params) =>
  deephowApiServerWrapper("post", "/v1/analytics/multimodal-sops-details/file", params);

export const getQuizPerformanceList = (params) =>
  deephowApiServerWrapper("get", "/v1/analytics/quizzes/quiz-performance-reports/list", { params });

export const getQuizPerformanceListExport = (params) =>
  deephowApiServerWrapper("post", "/v1/analytics/quizzes/quiz-performance-reports/file", params);

export const getSkillsPerformanceList = (params) =>
  deephowApiServerWrapper("get", "/v1/analytics/skill-performance/list", { params });

export const getSkillsPerformanceListExport = (params) =>
  deephowApiServerWrapper("post", "/v1/analytics/skill-performance/file", params);
