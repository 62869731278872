import { BACKEND_TYPE, ENV } from "@/constants/env";

const DEV_SERVICES = {
  global: {
    admin: "https://dev-admin-server.deephow.app",
    analytics: "https://dev-analytics-server.deephow.app",
    azureSpeechServer: "https://dev-azure-speech-api.deephow.app",
    chatServer: "https://dev-chat-api.deephow.app",
    crossModalitySearch: "https://dev-cross-modality-search.deephow.app",
    dataExporter: "https://dev-analytics-exporter-server.deephow.app",
    database: "",
    deephowAI: "https://dev-deephow-ai-api.deephow.app",
    deephowApiServer: "https://dev-deephow-api.deephow.app",
    externalApi: "https://dev-deephow-external-api.deephow.app",
    language: "https://dev-language-server.deephow.app",
    qrcode: "https://dev-qrcode-server.deephow.app",
    search: "https://dev-text-search-server.deephow.app",
    sign: "https://dev-sign-server.deephow.app",
    summarizationServer: "https://dev-summarization-api.deephow.app",
    ulApiServer: "https://dev-ul-integration.deephow.app",
    video: "https://dev-video-server.deephow.app",
    visualizationServer: "https://dev-visualization-api.deephow.app",
    websocket: "",
    workflow: "https://dev-workflow-server.deephow.app",
  },
  china: {
    admin: "https://dev-admin-server.deephow.net",
    analytics: "https://dev-analytics-server.deephow.net",
    azureSpeechServer: "",
    chatServer: "",
    crossModalitySearch: "",
    dataExporter: "https://dev-analytics-exporter-server.deephow.net",
    database: "https://dev-database-server.deephow.net",
    deephowAI: "https://dev-deephow-ai-api.deephow.net",
    deephowApiServer: "https://dev-deephow-api.deephow.net",
    externalApi: "https://dev-external-api.deephow.net",
    language: "https://dev-language-server.deephow.net",
    qrcode: "https://dev-qrcode-server.deephow.net",
    search: "https://dev-text-search-server.deephow.net",
    sign: "https://dev-sign-server.deephow.net",
    summarizationServer: "",
    ulApiServer: "https://ul-integration-3oqy6iorxq-uc.a.run.app",
    video: "https://dev-video.deephow.net:8080",
    visualizationServer: "",
    websocket: "wss://dev-websocket-server.deephow.net/wss",
    workflow: "https://dev-workflow-server.deephow.net:8081",
  },
};

const PROD_SERVICES = {
  global: {
    admin: "https://admin-server.deephow.app",
    analytics: "https://analytics-server.deephow.app",
    azureSpeechServer: "https://azure-speech-api.deephow.app",
    chatServer: "https://chat-api.deephow.app",
    crossModalitySearch: "https://cross-modality-search.deephow.app",
    database: "",
    dataExporter: "https://analytics-exporter-server.deephow.app",
    deephowAI: "https://deephow-ai-api.deephow.app",
    deephowApiServer: "https://deephow-api.deephow.app",
    externalApi: "https://deephow-external-api.deephow.app",
    language: "https://language-server.deephow.app",
    qrcode: "https://qrcode-server.deephow.app",
    search: "https://text-search-server.deephow.app",
    sign: "https://sign-server.deephow.app",
    summarizationServer: "https://summarization-api.deephow.app",
    ulApiServer: "https://ul-integration.deephow.app",
    video: "https://video-server.deephow.app",
    visualizationServer: "https://visualization-api.deephow.app",
    websocket: "",
    workflow: "https://workflow-server.deephow.app",
  },
  china: {
    admin: "https://admin-server.deephow.net",
    analytics: "https://analytics-server.deephow.net",
    azureSpeechServer: "",
    chatServer: "",
    crossModalitySearch: "",
    dataExporter: "https://analytics-exporter-server.deephow.net",
    database: "https://database-server.deephow.net",
    deephowAI: "https://deephow-ai-api.deephow.net",
    deephowApiServer: "https://deephow-api.deephow.net",
    externalApi: "https://external-api.deephow.net",
    language: "https://language-server.deephow.net",
    qrcode: "https://qrcode-server.deephow.net",
    search: "https://text-search-server.deephow.net",
    sign: "https://sign-server.deephow.net",
    summarizationServer: "",
    ulApiServer: "https://ul-integration-kfk24stg4a-uc.a.run.app",
    video: "https://video-server.deephow.net:8080",
    visualizationServer: "",
    websocket: "wss://websocket-server.deephow.net/wss",
    workflow: "https://workflow-server.deephow.net:8081",
  },
};

const SANDBOX_SERVICES = {
  global: {
    ...PROD_SERVICES.global,
    analytics: "https://analytics-server-sandbox.deephow.app",
    deephowAI: "https://deephow-ai-api-sandbox.deephow.app",
    deephowApiServer: "https://deephow-api-sandbox.deephow.app",
  },
  china: {
    ...PROD_SERVICES.china,
  },
};

const SERVICES_ENV_MAP = {
  dev: DEV_SERVICES,
  prod: PROD_SERVICES,
  sandbox: SANDBOX_SERVICES,
};

const SERVICES = SERVICES_ENV_MAP[ENV][BACKEND_TYPE];

export default SERVICES;
