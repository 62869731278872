export const SKILL_DETAIL_QUIZ_STATUS = {
  PASSED: "You passed",
  FAILED: "Did not pass",
};

export const QUIZ_STATUS = {
  FAILED: "failed",
  PASSED: "passed",
};

export const SCREEN_TYPE = {
  CLOSE: "CLOSE",
  TAKE_QUIZ: "TAKE_QUIZ",
  REVIEW_QUIZ: "REVIEW_QUIZ",
};

export const SKILL_ASSESSMENT_CARD_STATUS = {
  NONE: "NONE",
  FAILED: "FAILED",
  LOCKED: "LOCKED",
  PASSED: "PASSED",
};
