<template lang="html">
  <section class="mixin-db">
    <!-- <h1>mixin-db Component</h1> -->
  </section>
</template>

<script lang="js">
import { FIREBASE_COLLECTION_VERSIONING} from "@/constants/env/firebase";
import db from '../../db.js';
import { loggingError } from '@/server/error-log-server.js';
  export default  {
    name: 'mixin-db',
    methods: {
    deleteDocument(collection, documentId){
      return db.deleteDocument.call(this, collection, documentId);
    },
    duplicateDocument(collection, documentId){
        return db.duplicateDocument.call(this, collection, documentId);
      },
    duplicateDocumentToOrg(collection, documentId, organization){
      return db.duplicateDocumentToOrg.call(this, collection, documentId, organization);
      },
      getDocumentByQuery(collection, comparisons, methods){
        return db.getDocumentByQuery.call(this, collection, comparisons, methods);
      },
      getDocumentByQuery2(collection, comparisons, methods, callback){
        return db.getDocumentByQuery2.call(this, collection, comparisons, methods, callback);
      },
      getDocumentListener(collection, documentId, callback){
          return db.getDocumentListener.call(this, collection, documentId, callback);
      },
      getDocumentQueryListener(collection, comparisons, methods, callback){
        return db.getDocumentQueryListener.call(this, collection, comparisons, methods, callback);
      },
      getDocumentByFieldValue(collection, field, value){
        return db.getDocumentByFieldValue.call(this, collection, field, value);
      },
      getDocumentByArrayElement(collection, field, element){
        return db.getDocumentByArrayElement.call(this, collection, field, element);
      },
      getAllDocument(collection){
        return db.getAllDocument.call(this, collection);
      },
      getDocument(collection, documentId){
        return db.getDocument.call(this, collection, documentId)
      },
      addDocument(collection, documentId, data){
        return db.addDocument.call(this, collection, documentId, data)
      },
      async updateDocumentAndVersion(collection, documentId, data){
        //IMPORTANT: THE $collection_versions collection must be created with proper firestore security rules
        //before attempting to use this function; otherwise the whole batch transaction will fail
        const doc = await this.getDocument(collection, documentId).catch(err=>{
          console.log("failed to check totalVersions for versioning.")
          loggingError(err);
        })
        //stop versioning if max is reached
        if(doc && (!doc.totalVersions || doc.totalVersions < FIREBASE_COLLECTION_VERSIONING.maxVersionsAllowed)){
          const versionDocId = this.generateDocId(collection + "_versions");
          const versionDoc = doc;
          versionDoc.id = versionDocId;
          versionDoc.oid = documentId; //original id of the document
          versionDoc.vid = versionDocId; //version id to avoid duplicated copies if the version is later restore and modify
          versionDoc.vTimestamp = await this.getServerTimestamp();
          data.totalVersions = (data.totalVersions) ? parseInt(data.totalVersions)+1 : 1;

          const version = {
            type: "set",
            collection: collection + "_versions",
            id: versionDocId,
            data: versionDoc
          }
          const update = {
              type: "update",
              collection: collection,
              id: documentId,
              data: data
          }
          return db.batchWrites.call(this, [version, update]);
        }else{
          return db.updateDocument.call(this, collection, documentId, data);
        }
      },
      updateDocument(collection, documentId, data){
        if(FIREBASE_COLLECTION_VERSIONING.collections.includes(collection)){
          return this.updateDocumentAndVersion(collection, documentId, data);
        }else{
          return db.updateDocument.call(this, collection, documentId, data);
        }
      },
      generateDocId(collection) {
        return db.generateDocId.call(this, collection);
      },
      updateNestedObject(collection, documentId, data){
        return db.updateNestedObject.call(this, collection, documentId, data);
      },
      updateMap(collection, documentId, data) {
        return db.updateMap.call(this, collection, documentId, data);
      },
      batchWrites(writes){
        return db.batchWrites.call(this, writes);
      },
      getServerTimestamp(collection) {
        return db.getServerTimestamp.call(this);
      },
      updateDocumentArrayField(collection, documentId, data){
        return db.updateDocumentArrayField.call(this, collection, documentId, data);
      },
      updateCounter(collection, documentId, data){
        return db.updateCounter.call(this, collection, documentId, data);
      },
      updateDocumentArrayUnion(collection, documentId, data){
        return db.updateDocumentArrayUnion.call(this, collection, documentId, data);
      },
      updateDocumentArrayRemove(collection, documentId, data){
        return db.updateDocumentArrayRemove.call(this, collection, documentId, data);
      }
    },
}
</script>

<style scoped></style>
