<template>
  <div class="workspaceGroupTitle" :class="size">
    <span class="workspaceGroupTitle__title" :style="{ width: `${maxWidth}px` }">{{ title }} </span>
  </div>
</template>
<script>
export default {
  name: "WorkspaceGroupTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "medium", // small, medium, large
    },
    color: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
      default: "auto",
    },
  },
};
</script>

<style lang="scss" scoped>
.workspaceGroupTitle {
  display: flex;
  align-items: center;
  cursor: pointer;
  &.small {
    --icon-size: 17px;
    --title-size: 16px;
    --title-margin: 12px;
    --title-font-weight: 500;
  }
  &.medium {
    --icon-size: 17px;
    --title-size: 18px;
    --title-margin: 12px;
    --title-font-weight: 700;
  }

  &.large {
    --icon-size: 25px;
    --title-size: 48px;
    --title-margin: 0px;
    --title-font-weight: 700;
  }
  svg {
    height: var(--icon-size);
    width: var(--icon-size);
    flex-shrink: 0;
  }
  &__title {
    color: white;
    font-size: var(--title-size);
    font-weight: var(--title-font-weight);
    margin-bottom: -3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }
}
</style>
