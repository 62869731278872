<template>
  <div :class="['App', { 'App__header-alert--open': isOpenGlobalRestrictedIpAlert }]">
    <a href="#main-content" class="skip-link" tabindex="0">Skip to content</a>
    <v-app id="main-content">
      <template v-if="canShowRouterView">
        <DRestrictedIpOrgAdminAlert v-if="isOpenGlobalRestrictedIpAlert" />
        <d-auth-header v-if="!isLogin" />
        <div class="App__body">
          <navigator-mobile-toolbar v-if="isMobileDevice && !$route.meta.withoutFooterNav && isLogin" />
          <d-side-menu v-if="canShowSideMenu" :access="access" />
          <div class="App__content">
            <d-header v-if="isLogin" @open-user-menu="onToggleUserMenu" />
            <keep-alive>
              <router-view
                v-if="$route.meta.keepAlive"
                :isMobileDevice="isMobileDevice"
                :isTabletDevice="isTabletDevice"
                :isIframe="isIframe"
              ></router-view>
            </keep-alive>
            <router-view
              v-if="!$route.meta.keepAlive"
              :isMobileDevice="isMobileDevice"
              :isTabletDevice="isTabletDevice"
              :isIframe="isIframe"
            ></router-view>
          </div>

          <template v-if="isLogin">
            <!-- uploader -->
            <uploader
              v-if="showUploader && uploadType !== UPLOAD_TYPE.ZOOM_IMPORT"
              :showUploader="showUploader"
              :uploadType="uploadType"
              :isRecordingScreen="isRecordingScreen"
              :userGroupList="groups"
              :defaultWorkspace="uploadDefaultWorkspace"
              :class="{ 'hide-uploader': isRecordingScreen }"
              @close-uploader="closeUploader"
              @upload-success="goToUploadedWorkspace"
              @startRecordScreen="updateIsRecordingScreen(true)"
              @endRecordScreen="updateIsRecordingScreen(false)"
              @update-current-group="updateCurrentGroupAfterUpload($event)"
              @go-to-editor="goToEditor()"
            ></uploader>
            <!-- zoom uploader -->
            <zoom-uploader
              v-if="showUploader && uploadType === UPLOAD_TYPE.ZOOM_IMPORT"
              :userGroupList="groups"
              @close-uploader="closeUploader"
              @upload-success="goToUploadedWorkspace"
            />
            <create-mm-sop-pop v-if="showCreateMMSOPPop" @close="closeCreateMMSOPPop"></create-mm-sop-pop>

            <user-menu
              v-if="getter_is_user_menu_open"
              :isMobileDevice="isMobileDevice"
              :isTabletDevice="isTabletDevice"
              @close-user-menu="closeUserMenu"
            />
            <user-profile-main
              v-if="userProfileConfig.isOpen"
              :isMobileDevice="isMobileDevice"
              :isTabletDevice="isTabletDevice"
            />
            <d-idle
              v-if="customizedPermissions != null"
              :allowAutoLogout="customizedPermissions.allowAutoLogout"
              :autoLogoutTimeout="customizedPermissions.autoLogoutTimeout"
              @update-allowAutoLogout="customizedPermissions.allowAutoLogout = $event"
              @logout="handleLogout"
            />
            <d-quiz-screen />
          </template>
        </div>
      </template>
      <d-check-restricted-ip v-else />
      <new-version-snackbar :isMobileDevice="isMobileDevice" />
      <d-global-alert />
      <d-troubleshooting-popup v-if="isTroublshootingOpen" />
      <d-use-app-popup v-if="showUseAppPopup" @close="showUseAppPopup = false" />
      <d-cookie-accept-popup
        v-if="showAcceptCookiePopup"
        @close="closeUserConsentsPopup"
        :consents="consents"
        :type="consentsPopupType"
      />
      <d-gen2-forbidden-popup v-model="showGen2ForbiddenAlert" @close="forbiddenPopupHandler" />
      <version-history-popup />
    </v-app>
  </div>
</template>

<script>
import DAuthHeader from "@/components/DHeader/DAuthHeader.vue";
import DHeader from "@/components/DHeader/DHeader.vue";
import DSideMenu from "@/components/DSideMenu/DSideMenu.vue";

import MixinAnalytics from "@/components/MixinAnalytics.vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";

import { IS_MOBILE, IS_TABLET, IS_USE_IFRAME } from "@/constants/device-version";
import { IS_MAVEN_WHITELIST_DOMAIN } from "@/constants/envVersion";
import { playerRoutes } from "@/constants/routers";

import {
  getLogoutImmediatelyLocalStorage,
  removeLogoutImmediatelyLocalStorage,
  updateBrowerTabLocalStorage,
} from "@/js/idle/idle.js";
import { setIsIpPassedLocalStorage } from "@/js/login/checkRestrictedIp";
import { isIpadOrIphoneDevice } from "@/js/mobile.js";
import {
  getEmployeeIdAccountFromLocalStorage,
  isEmployeeIdAccountInLocalStorage,
  setEmployeeIdAccountInLocalStorage,
} from "@/js/user/employee-id.js";
import {
  getSharedAccountFromLocalStorage,
  isSharedAccountInLocalStorage,
  setSharedAccountInLocalStorage,
} from "@/js/user/share-account.js";
import { IS_FROM_RUSTICI } from "@/js/video-player/rustici";
import { MIGRATE_TO_CURRENT_UI_LOCALE_MAP } from "@/lang/i18n";
import { fetchWorkspaceList, getUserConsents } from "@/server/api-server";
import { refreshEmployeeIdLogin, refreshSharedAccountLogin } from "@/server/basic-authentication-server.js";
import { loggingError } from "@/server/error-log-server.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import auth from "../auth.js";
import Analytics from "@/js/analytics/analytics";
import eventbus from "@/main";

import { ROUTE_NAME, ROUTE_PATH } from "@/router/route.js";
import { SKILL_BUILDER_ROUTE_NAMES } from "@/router/routes/SkillRoute.js";

import { USER_ROLE } from "@/constants/userRole.js";
import { UPLOAD_TYPE } from "@/constants/uploadType.js";
import { EVENT } from "@/constants/globalEvents.js";
import { IS_CHINA } from "@/constants/env";

export default {
  name: "App",
  components: {
    DHeader,
    DAuthHeader,
    DSideMenu,
    DQuizScreen: () => import("@/components/DQuiz/DQuizScreen"),
    UserMenu: () => import("@/components/DUser/UserMenu"),
    Uploader: () => import("@/components/DUploader/Uploader.vue"),
    ZoomUploader: () => import("@/components/DUploader/ZoomUploader.vue"),
    CreateMmSopPop: () => import("@/components/DMMSOP/DCreateSmartInstruction/CreateMMSOP.vue"),
    DGlobalAlert: () => import("@/components/ui_components/DGlobalAlert"),
    UserProfileMain: () => import("@/components/DUserProfile/UserProfileMain"),
    NewVersionSnackbar: () => import("@/components/DPlayer/NewVersionSnackbar"),
    DIdle: () => import("@/components/ui_components/DIdle.vue"),
    DUseAppPopup: () => import("@/components/DPopup/DUseAppPopup.vue"),
    DCookieAcceptPopup: () => import("@/components/DPopup/DCookieAcceptPopup.vue"),
    DGen2ForbiddenPopup: () => import("@/components/DPopup/DGen2ForbiddenPopup.vue"),
    DTroubleshootingPopup: () => import("@/components/DPopup/DTroubleshootingPopup.vue"),
    DCheckRestrictedIp: () => import("@/components/DLayout/DCheckRestrictedIp.vue"),
    DRestrictedIpOrgAdminAlert: () => import("@/components/DLayout/DRestrictedIpOrgAdminAlert.vue"),
    VersionHistoryPopup: () => import("./components/DEditorBuilder/VersionHistory/VersionHistoryPopup.vue"),
    NavigatorMobileToolbar: () => import("@/components/NavigatorMobileToolbar.vue"),
  },
  mixins: [MixinDB, MixinUser, MixinAnalytics],
  mounted() {
    window.addEventListener("offline", this.onLeavePage);
    window.addEventListener(this.terminationEvent, this.onLeavePage);
  },
  data() {
    return {
      UPLOAD_TYPE,
      auth,
      terminationEvent: "onpagehide" in self ? "pagehide" : "unload",
      access: {
        editor: false,
        player: true,
        admin: false,
        skills: false,
      },
      // TODO: replace "isTabletDevice" and "isMobileDevice"
      // when building new header and side menu by Content Structure project
      isMobileDevice: IS_MOBILE,
      isTabletDevice: IS_TABLET,
      isIframe: IS_USE_IFRAME,

      /** Uploader */
      showUploader: false,
      uploadType: "",
      uploadDefaultWorkspace: undefined,

      /** Create MMSOP */
      showCreateMMSOPPop: false,

      customizedPermissions: null,
      playerRoutes,
      showUseAppPopup: false,
      showGen2ForbiddenAlert: false,
      consents: [],
    };
  },
  watch: {
    "$i18n.locale": {
      immediate: true,
      handler(newLocale) {
        document.documentElement.lang = newLocale;
      },
    },
    $route(to, from) {
      if (to.name === "SharedMain") {
        /** auto logout: to initialize browserTabList */
        updateBrowerTabLocalStorage([]);
      }
      this.redirectRouter();
      this.handleCheckIp();
    },
    userProfile() {
      this.getUserLocale();
      this.fetchUserLikes();
    },
    isLogin(val) {
      this.handleCheckIp();
      if (val) {
        this.getWorkflowWithQuiz();
        this.initWorkspaceGroupList();
        this.initAssignedPrivateWorkspaceList();
        this.loadWorkspaceDictionary();
      }
    },
    getter_user_ip_not_allowed(getter_user_ip_not_allowed) {
      if (getter_user_ip_not_allowed) {
        this.redirectRouter();
      }
    },
  },
  created() {
    /** direct link to iOS APP from iPhone or iPad */
    const isUsingIpadOrIphoneDevice = isIpadOrIphoneDevice();
    const regexPublicLink = new RegExp(/\/p\//);
    const isPublicLink = regexPublicLink.test(location.pathname);
    if (isUsingIpadOrIphoneDevice && !isPublicLink) this.showUseAppPopup = true;

    const self = this;
    self.auth().onAuthStateChanged(async (user) => {
      if (user && user.email) {
        /**
         * In the China version, unlike the global version, either Shared Account or Employee ID users stay logged in even we use 'disposablePwd' for login.
         * Due to this behavior, when the user is a Shared Account, we need to verify if the Shared Devices permission is active to maintain the status.
         */
        if (
          !isEmployeeIdAccountInLocalStorage() &&
          isSharedAccountInLocalStorage() &&
          !(await this.checkAndHandleSharedDevicesPermission(true))
        )
          return;

        const credentialString = localStorage.getItem("ssoCredential");
        if (credentialString) {
          const credential = JSON.parse(credentialString);
          const response = await self.validateOAuth(credential);
          if (response === "error") {
            self.signOutWithError();
            return;
          }

          if (response.data.useCustomToken) {
            self
              .auth()
              .signInWithCustomToken(response.data.userToken)
              .catch(function (error) {
                self.isLoggingInSso = false;
                loggingError(error);
                alert("Login failed");
                return;
              });
          }
        }

        /** To confirm user need to logout and force to trigger */
        if (getLogoutImmediatelyLocalStorage() === "true") {
          self.handleLogout();
          removeLogoutImmediatelyLocalStorage();
          return;
        }

        Analytics.setUserUid(user.uid);

        self.initializeUser(user).then(async function () {
          const onAccessReady = async function () {
            self.fetchEditableGroups();
          };
          self.setupWorkspaceColorsConfig({
            mixinDbRef: self,
          });
          self.getConsents();
          if (self.$route.query.redirect) {
            onAccessReady();
            self.$router.push(self.$route.query.redirect);
          } else {
            await onAccessReady();
            self.redirectRouter();
          }
          localStorage.removeItem("ssoCredential");
        });
      } else {
        /**
         * Case 1: Employee ID user or Shared Account user back to DeepHow.
         * Employee ID user possible to login by itself or Shared Account,
         * so we should check Employee ID in localStorage first,
         * then decide to login or continue to check Shared Account in localStorage.
         * Also, if Shared Devices option is disabled, we should logout Shared Account and clear localStorage.
         *
         * Case 2: Employee ID user logout.
         * If a Employee ID user logged in by Shared Account then logout,
         * the Shared Account user will be logged in automatically by checking the localStorage.
         */

        // Employee ID User Re-login.
        if (isEmployeeIdAccountInLocalStorage()) return this.employeeIdAccountReLogin();

        // Shared Account User Re-login.
        if (!isSharedAccountInLocalStorage() || !(await this.checkAndHandleSharedDevicesPermission())) return;
        this.sharedAccountReLogin();
      }
    });
    eventbus.$on(EVENT.SHOW_CREATE_WORKFLOW_POP, this.openUploader);
    eventbus.$on(EVENT.SHOW_CREATE_MM_SOP_POP, this.openCreateMMSOPPop);
  },
  computed: {
    ...mapState("editor", ["groups"]),
    ...mapState("global", ["userProfileConfig", "isTroublshootingOpen"]),
    ...mapState("auth", ["isLogin", "userProfile"]),
    ...mapState("permission", ["mavenEnable"]),
    ...mapState("userConsents", ["consentsPopupType", "showAcceptCookiePopup"]),
    ...mapGetters(["getter_is_allowed_using_maven_features"]),
    ...mapGetters("permission", ["getter_shared_account_enabled"]),
    ...mapGetters("auth", [
      "user_access",
      "getter_is_gen2",
      "getter_user_id",
      "getter_user_role",
      "getter_user_locale",
      "getter_user_organization",
      "getter_user_ip_passed",
      "getter_user_ip_not_allowed",
      "getter_is_shared_account",
    ]),
    ...mapGetters("global", ["getter_is_user_menu_open"]),
    isAdminPage() {
      return /^Admin/.test(this.$route.name);
    },
    isNeedAuthPage() {
      return this.$route.meta?.auth || this.$route.matched.some((route) => route.meta.auth);
    },
    isOpenGlobalRestrictedIpAlert() {
      if (["404", "403"].includes(this.$route.name)) return false;
      return this.isNeedAuthPage && this.getter_user_ip_not_allowed;
    },
    isRecordingScreen() {
      return this.$store.state.isRecordingScreen;
    },
    canShowSideMenu() {
      return this.isLogin && !this.isIframe && this.isNeedAuthPage;
    },
    canShowRouterView() {
      if (!this.$route.name) return false;
      if (!this.isNeedAuthPage) return true;
      if (!this.isLogin) return false;
      if (this.getter_user_ip_passed) return true;
      if (this.isAdminPage && this.getter_user_role === USER_ROLE.ADMIN_ORG) return true;
      return false;
    },
  },
  beforeDestroy() {
    window.removeEventListener("offline", this.onLeavePage);
    window.removeEventListener(this.terminationEvent, this.onLeavePage);
  },
  methods: {
    ...mapActions("editor", ["fetchEditableGroups"]),
    ...mapActions("workspaceColor", ["setupWorkspaceColorsConfig"]),
    ...mapActions("permission", ["getOrgPermissionsSettings", "setIsRedirectedByIpRestriction"]),
    ...mapActions("userConsents", ["openUserConsentsPopup", "closeUserConsentsPopup"]),
    ...mapActions("userLikes", ["fetchUserLikes"]),
    ...mapActions("userQuiz", ["getWorkflowWithQuiz"]),
    ...mapActions("workspaceGroups", ["getWorkspaceGroupList"]),
    ...mapActions("navigator", ["storeWorkspacesAssignedPrivate"]),
    ...mapActions("workspace", ["loadWorkspaceDictionary", "setEditorWorkspace"]),
    ...mapActions("auth", ["getUserProfile"]),
    ...mapMutations("global", ["TOGGLE_USER_MENU"]),
    handleCheckIp() {
      if (!this.isLogin) return;
      if (!this.isNeedAuthPage) return;
      this.$store.dispatch("auth/checkIp");
    },
    onLeavePage() {
      setIsIpPassedLocalStorage(false);
    },
    async redirectRouter() {
      if (!this.isLogin) {
        return;
      }
      try {
        if (this.getter_is_gen2 && !IS_MAVEN_WHITELIST_DOMAIN) {
          if (this.showGen2ForbiddenAlert) return;
          this.showGen2ForbiddenAlert = true;
          return;
        }

        if (!this.isAdminPage && this.getter_user_ip_not_allowed && this.getter_user_role === USER_ROLE.ADMIN_ORG) {
          this.setIsRedirectedByIpRestriction(true);
          this.$router.push("/admin/settings");
          return;
        }

        // for Shared Account

        if (this.getter_is_shared_account && this.getter_shared_account_enabled) {
          this.$router.push("/shared");
          return;
        }
        if (this.$route.query.redirect) {
          this.$router.push(self.$route.query.redirect);
          return;
        }
        const defaultHomePage = ROUTE_PATH.HOME;
        if (this.$route.path === "/") {
          this.$router.push(defaultHomePage);
          return;
        }

        /**
         * Skill Builder Navigation Guard:
         * If the user does not have access to the skill builder, redirect to the home page
         */
        const isGoToSkillBuilder = SKILL_BUILDER_ROUTE_NAMES.includes(this.$route.name);
        if (isGoToSkillBuilder && !this.user_access.skills) {
          this.$router.replace(defaultHomePage);
          return;
        }

        const gen2ForbiddenPathRegex = /\/(skill|navigator|analytics)/;
        if (this.getter_is_gen2 && gen2ForbiddenPathRegex.test(this.$route.path)) {
          this.$router.push(defaultHomePage);
          return;
        }
        const currentRoute = this.$route.name;
        const canAccessByMobileAndTablet = [
          ...this.playerRoutes,
          "PlayerMain",
          "PublicPlayerMain",
          "PlayerFullscreenPublic",
        ].includes(currentRoute);
        const isPlayer = this.playerRoutes.includes(currentRoute);

        if (currentRoute === ROUTE_NAME.MAVEN && !this.mavenEnable) {
          this.$router.push(defaultHomePage);
        }
      } catch (e) {
        console.log("redirectRouter error", e.message);
        loggingError(e);
      }
    },
    async initializeUser(user) {
      if (!user.email) return;
      try {
        await this.getUserProfile();
        await this.getUserRole(user.uid);
        await this.getOrgPermissionsSettings({ organization: this.getter_user_organization });

        this.setUserAccess();
        this.access = this.user_access;
        if (IS_CHINA) {
          this.setUserStorageAccess();
          if (!this.setUserStorageAccessInterval) {
            //TODO make this better , ex: all event trigger based
            this.setUserStorageAccessInterval = setInterval(this.setUserStorageAccess, 1 * 60 * 60 * 1000); // per hour to prevent sts token expired
          }
        }
        this.customizedPermissions = this.getCustomizedPermissions();
        // TODO_REMOVE_WAIT_REFACTOR
        // should set is_login after set data to Vue.prototype,
        // because it too many code use this.$xxx
        this.$store.commit("auth/SET_IS_LOGIN", true);
      } catch (error) {
        this.handleLogout();
      }
    },
    getUserLocale() {
      const userLocale = this.getter_user_locale;
      if (userLocale) {
        const currentLocale = MIGRATE_TO_CURRENT_UI_LOCALE_MAP[userLocale] || userLocale;
        this.$i18n.locale = currentLocale;
        localStorage.setItem("locale", currentLocale);
      } else {
        this.updateUserLocale(this.$i18n.locale);
      }
    },
    async updateUserLocale(lang) {
      //save to database
      try {
        if (lang) {
          await this.updateDocument("users", this.getter_user_id, {
            locale: lang,
          });
          this.getUserProfile();
          localStorage.setItem("locale", lang);
        }
      } catch (error) {
        loggingError(error);
        console.error({ error });
      }
    },
    updateCurrentGroupAfterUpload(group) {
      this.setEditorWorkspace(group.id);
    },
    updateIsRecordingScreen(booType) {
      this.$store.commit("updateIsRecording", booType);
    },
    handleLogout() {
      this.customizedPermissions = null;
      this.logout();
      this.$router.push("/");
    },
    forbiddenPopupHandler() {
      this.showGen2ForbiddenAlert = false;
      this.handleLogout();
    },

    /** Uploader */
    openUploader({ uploadType, defaultWorkspace } = {}) {
      this.showUploader = true;
      this.uploadType = uploadType;
      this.uploadDefaultWorkspace = defaultWorkspace;
    },
    closeUploader() {
      this.uploadType = "";
      this.showUploader = false;
    },
    goToUploadedWorkspace(workspaceId) {
      const isAlreadyInWorkspace =
        this.$route.name === ROUTE_NAME.WORKSPACE_WORKFLOW && this.$route.params.workspaceId === workspaceId;
      if (isAlreadyInWorkspace) {
        eventbus.$emit(EVENT.REFRESH_WORKSPACE_WORKFLOWS);
      } else {
        this.$router.push({
          name: ROUTE_NAME.WORKSPACE_WORKFLOW,
          params: { workspaceId },
        });
      }
    },

    /** Create MMSOP */
    openCreateMMSOPPop() {
      this.showCreateMMSOPPop = true;
    },
    closeCreateMMSOPPop() {
      this.showCreateMMSOPPop = false;
    },

    async sharedAccountReLogin() {
      try {
        const sharedAccountData = JSON.parse(getSharedAccountFromLocalStorage());
        const { ok, data } = await refreshSharedAccountLogin(sharedAccountData.refreshToken);
        if (!ok) {
          await this.logoutSharedAccountUser(); // From MixinUser.
          return;
        }
        const refreshedSharedAccountData = { ...sharedAccountData, refreshToken: data.item.refreshToken };
        setSharedAccountInLocalStorage(JSON.stringify(refreshedSharedAccountData));
        const { user } = await auth().signInWithEmailAndPassword(sharedAccountData.email, data.item.disposablePwd);
        if (user) this.$router.push("/shared");
      } catch (error) {
        await this.logoutSharedAccountUser(); // From MixinUser.
      }
    },
    async employeeIdAccountReLogin() {
      try {
        const employeeIdAccountData = JSON.parse(getEmployeeIdAccountFromLocalStorage());
        const { ok, data } = await refreshEmployeeIdLogin(employeeIdAccountData.refreshToken);
        if (!ok) {
          this.logoutEmployeeIdAccountUser(); // From MixinUser.
          return;
        }
        const refreshedEmployeeIdAccountData = { ...employeeIdAccountData, refreshToken: data.item.refreshToken };
        setEmployeeIdAccountInLocalStorage(JSON.stringify(refreshedEmployeeIdAccountData));
        const { user } = await auth().signInWithEmailAndPassword(employeeIdAccountData.email, data.item.disposablePwd);
        if (user) this.$router.push(ROUTE_PATH.HOME_OVERVIEW);
      } catch (error) {
        this.logoutEmployeeIdAccountUser(); // From MixinUser.
      }
    },
    async checkAndHandleSharedDevicesPermission(hasUser) {
      const sharedAccountData = JSON.parse(getSharedAccountFromLocalStorage());
      const clientKey = this.generateClientKeyForEmployeeId({ employeeId: sharedAccountData.account });
      await this.getOrgPermissionsSettings({ clientKey });
      if (this.getter_shared_account_enabled) return true;
      await this.logoutSharedAccountUser(hasUser); // From MixinUser.
    },
    async getConsents() {
      if (IS_FROM_RUSTICI) return;
      const res = await getUserConsents();
      const { item: consents } = res.data;
      consents.forEach((consent) => {
        if (!consent.signed) {
          consent.signedInfo = { isAgree: false };
        }
      });
      this.consents = consents;
      const everyConsentIsSign = consents.every((consent) => consent.signed);
      const allMandatoryConsnetIsAgree = !consents.some(
        (consent) => consent.signed && consent.mandatory && !consent.signedInfo.isAgree
      );
      if (everyConsentIsSign && allMandatoryConsnetIsAgree) return;
      this.openUserConsentsPopup({ type: "banner" });
    },

    async initWorkspaceGroupList() {
      if ([USER_ROLE.ADMIN_SUPER, USER_ROLE.ADMIN_ORG, USER_ROLE.ADMIN_WORKSPACE].includes(this.getter_user_role)) {
        try {
          await Promise.all([
            this.getWorkspaceGroupList({ workspacesType: "admin" }),
            this.getWorkspaceGroupList({ workspacesType: "edit" }),
          ]);
        } catch (error) {
          console.error({ error });
          loggingError(error);
        }
      } else if (this.getter_user_role === USER_ROLE.PUBLISHER) {
        try {
          await this.getWorkspaceGroupList({ workspacesType: "edit" });
        } catch (error) {
          console.error({ error });
          loggingError(error);
        }
      }
    },
    async initAssignedPrivateWorkspaceList() {
      try {
        const { ok, data, errorMessage } = await fetchWorkspaceList({ type: "view" });
        if (!ok) throw new Error(errorMessage);
        const assignedPrivateWorkspaceList = data.items
          .filter((workspace) => workspace.private)
          .map((workspace) => workspace.id);
        this.storeWorkspacesAssignedPrivate(assignedPrivateWorkspaceList);
      } catch (error) {
        console.error({ error });
        loggingError(error);
      }
    },
    onToggleUserMenu() {
      this.TOGGLE_USER_MENU({ isOpen: !this.getter_is_user_menu_open });
    },
    closeUserMenu() {
      this.TOGGLE_USER_MENU({ isOpen: false });
    },
  },
};
</script>

<style lang="scss">
html {
  overflow-y: hidden !important;
}
body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #0c0c0e;
  font-family: "Roboto", Helvetica, Arial, Sans-serif;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8d909f !important;
}

#app {
  background-color: #242527;
}
.App {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  font-family: "Roboto", Helvetica, Arial, Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #242527;
  /* Removing the White Bars in Safari on iPhone iOS 11 */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

  --total-header-height: calc(var(--header-height) + var(--header-alert-height));
  --header-alert-height: 0px;
  &__header-alert--open {
    --header-alert-height: 40px;
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    background: #121314;
  }
  &__content {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
  }
}

button:focus {
  outline: none;
}

/* make v-dialog background color darker */
.v-overlay--active:before {
  opacity: 0.8 !important;
}
.v-overlay:before {
  background-color: #000000 !important;
}

.dropdown-list-item:hover {
  background-color: #52545d !important;
}

.uploader-menu .v-list {
  background: #2c2d32 !important;
  color: white !important;
}
.app-no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.v-list-app {
  background: #2c2d32 !important;
  color: white;
}
.hide-uploader {
  opacity: 0;
  pointer-events: none !important;
  z-index: -1;
}
.app-all-white-spaces {
  /* show all the white spaces for names */
  white-space: pre-wrap !important;
}

#app .max-height-full {
  max-height: 100%;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #005fcc;
  color: white;
  padding: 8px;
  z-index: 100;
  text-decoration: none;
}

.skip-link:focus {
  top: 0;
}
</style>
