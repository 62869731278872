import Vue from "vue";
import Router from "vue-router";

import Auth from "@/components/Auth";
import PlayerFullscreenPublic from "@/components/DWorkflowPlayer/player/PlayerFullscreenPublic";
import PlayerMain from "@/components/DWorkflowPlayer/player/PlayerMain.vue";
import PlayerRustici from "@/components/DWorkflowPlayer/player/PlayerRustici.vue";
import UserInviteCreate from "@/components/UserInviteCreate";
import UserNewPassword from "@/components/UserNewPassword";

import auth from "../../auth.js";
import { ROUTE_NAME, ROUTE_PATH } from "./route.js";
import AdminRoute from "./routes/AdminRoute";
import AnalyticsRoute from "./routes/AnalyticsRoute";
import EditorRoute from "./routes/EditorRoute";
import HomeRoute from "./routes/HomeRoute.js";
import OwnedByMeRoute from "./routes/OwnedByMeRoute";
import MavenRoute from "./routes/MavenRoute";
import SkillRoute from "./routes/SkillRoute";
import WorkspaceRoute from "./routes/WorkspaceRoute.js";
import SearchRoute from "./routes/SearchRoute.js";

const UserEmailExpiredResetPassowrd = () => import("@/components/DUser/UserEmailExpiredResetPassowrd");
const DZoomLanding = () => import("@/components/DUser/DZoomLanding");
const MediaPicker = () => import("@/components/MediaPicker");
const SharedMain = () => import("@/components/DLogin/Shared/SharedMain");
const WorkflowPlayerMain = () => import("@/components/DWorkflowPlayer/WorkflowPlayerMain");
const UserProfileMain = () => import("@/components/DUserProfile/UserProfileMain");
Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new Router({
  mode: "history",
  routes: [
    ...HomeRoute,
    ...WorkspaceRoute,
    ...OwnedByMeRoute,
    ...EditorRoute,
    ...AnalyticsRoute,
    ...AdminRoute,
    ...MavenRoute,
    ...SkillRoute,
    ...SearchRoute,
    {
      path: "/playermainfullscreen/:workflowId/:stepId?",
      name: "PlayerMainFullscreen",
      component: PlayerMain,
      meta: { auth: true },
      props: true,
    },
    {
      path: "/rustici/:workflowId/:stepId?",
      name: "PlayerRustici",
      component: PlayerRustici,
      props: true,
    },
    {
      path: "/playermain/:workflowId/:stepId?",
      name: "PlayerMain",
      component: WorkflowPlayerMain,
      meta: { auth: true },
      props: true,
    },
    {
      path: "/playerembed/:workflowId/:stepId?",
      name: "PlayerEmbed",
      component: PlayerMain,
      meta: { auth: true },
      props: true,
    },
    {
      path: "/PlayerPreview/:workflowId/:stepId?",
      meta: { auth: true },
      props: true,
      redirect: (to) => {
        const { workflowId, stepId } = to.params;
        let target = { name: "404" };
        if (window.top.location.href.includes("buildermain")) {
          target = { name: "PlayerEmbed", params: { workflowId, stepId, isPreviewVideoMode: true } };
        }
        return target;
      },
    },
    {
      path: "/mediapicker",
      name: "MediaPicker",
      component: MediaPicker,
      meta: { auth: true },
      props: false,
    },
    {
      path: "/shared",
      name: "SharedMain",
      component: SharedMain,
      meta: { auth: true },
      props: false,
    },
    {
      path: `${ROUTE_PATH.USER_PROFILE}/:uid`,
      name: ROUTE_NAME.USER_PROFILE,
      component: UserProfileMain,
      meta: { auth: true },
      props: false,
    },
    {
      path: "*",
      redirect: "/",
    },
    //// Public Routes ////
    {
      path: "/p/:workflowId/:stepId?",
      name: "PublicPlayerMain",
      component: WorkflowPlayerMain,
      meta: { auth: false },
      props: true,
    },
    {
      path: "/playerfullscreenpublic/:workflowId/:stepId?",
      name: "PlayerFullscreenPublic",
      component: PlayerFullscreenPublic,
      meta: { auth: false },
      props: true,
    },
    {
      path: "/userinvitecreate/:token/:name?/:lang?",
      name: "UserInviteCreateLang",
      component: UserInviteCreate,
      meta: { auth: false },
    },
    {
      path: "/userinvitecreate/:token/:name?",
      name: "UserInviteCreate",
      component: UserInviteCreate,
      meta: { auth: false },
    },
    {
      path: "/usernewpassword/:token/:lang?",
      name: "UserNewPasswordLang",
      component: UserNewPassword,
      meta: { auth: false },
    },
    {
      path: "/usernewpassword/:token",
      name: "UserNewPassword",
      component: UserNewPassword,
      meta: { auth: false },
    },
    {
      path: "/emailexpired/:type?/:lang?/:email?",
      name: "UserEmailExpiredResetPassowrd",
      component: UserEmailExpiredResetPassowrd,
      meta: { auth: false },
    },
    {
      path: "/zoomlanding/:status/:lang?/:subdomain?",
      name: "DZoomLanding",
      component: DZoomLanding,
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/components/DNotFound/DNotFound"),
    },
    {
      path: "/403",
      name: "403",
      component: () => import("@/components/DNotFound/DNoPermission"),
    },
    {
      path: "/",
      name: "Auth",
      component: Auth,
      meta: { auth: false },
    },
  ],
});
router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some((route) => route.meta.auth);
  const currentUser = auth().currentUser;
  if (!authRequired || (currentUser && currentUser.uid)) {
    next();
    return;
  }
  auth().onAuthStateChanged((user) => {
    if (user) {
      next();
    } else {
      next({ name: "Auth", query: { redirect: to.path } });
    }
  });
});

export default router;
