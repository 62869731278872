import {
  SHAPE,
  STROKE_TYPE,
  ANNOTATION_TYPE,
  ANNOTATION_COLOR,
  ANNOTATION_LINE_WIDTH_VALUE,
} from "@/constants/annotationStatus";

const getDefaultConfig = ({
  type,
  top = 0,
  left = 0,
  isRedraw = false,
  id = Math.random().toString(16).slice(2),
  scaleX = 1,
  scaleY = 1,
  endTime = 0,
  startTime = 0,
  additionalConfig = {},
}) => ({
  type,
  top,
  left,
  isRedraw,
  id,
  endTime,
  startTime,
  scaleX,
  scaleY,
  ...additionalConfig,
});

export const getTextDefaultConfig = (options) => {
  const {
    fill = ANNOTATION_COLOR.RED,
    fontWeight = "normal",
    fontStyle = "normal",
    backgroundColor = "",
    text = "text",
    fontFamily = "Noto Serif",
  } = options;

  return getDefaultConfig({
    type: ANNOTATION_TYPE.TEXT,
    additionalConfig: {
      fill,
      fontWeight,
      fontStyle,
      backgroundColor,
      text,
      fontFamily,
    },
    ...options,
  });
};

export const getArrowDefaultConfig = (options) => {
  const {
    stroke = ANNOTATION_COLOR.RED,
    strokeWidth = ANNOTATION_LINE_WIDTH_VALUE.NORMAL,
    startX = 50,
    startY = 50,
    endX = 200,
    endY = 100,
  } = options;

  return getDefaultConfig({
    type: ANNOTATION_TYPE.ARROW,
    additionalConfig: {
      stroke,
      strokeWidth,
      startX,
      startY,
      endX,
      endY,
    },
    ...options,
  });
};

export const getBlurDefaultConfig = (options) => {
  const { shape = SHAPE.RECTANGLE, width, height } = options;
  const radius = options.radius ?? Math.min(width, height) / 2;
  const shapeConfig = shape === SHAPE.CIRCLE ? { radius } : { width, height };
  return getDefaultConfig({
    type: ANNOTATION_TYPE.BLUR,
    additionalConfig: {
      shape,
      fill: "#ffffff50",
      ...shapeConfig,
    },
    ...options,
  });
};

export const getShapeDefaultConfig = (options) => {
  const {
    shape = SHAPE.RECTANGLE,
    stroke = ANNOTATION_COLOR.RED,
    fill = ANNOTATION_COLOR.TRANSPARENT,
    width,
    height,
    strokeType = STROKE_TYPE.SOLID,
  } = options;
  const radius = options.radius ?? Math.min(width, height) / 2;
  const strokeMap = {
    [STROKE_TYPE.NONE]: { strokeWidth: 0, strok: ANNOTATION_COLOR.TRANSPARENT },
    [STROKE_TYPE.SOLID]: { strokeWidth: 2, stroke },
    [STROKE_TYPE.DASHED]: { strokeWidth: 2, strokeDashArray: [4, 4], stroke },
  };

  const shapeConfig = shape === SHAPE.CIRCLE ? { radius } : { width, height };
  return getDefaultConfig({
    type: ANNOTATION_TYPE.SHAPE,
    additionalConfig: {
      shape,
      fill,
      strokeType,
      ...shapeConfig,
      ...strokeMap[strokeType],
    },
    ...options,
  });
};

export const getImageDefaultConfig = (options) => {
  const { width, height, imageUrl } = options;

  return getDefaultConfig({
    type: ANNOTATION_TYPE.IMAGE,
    additionalConfig: {
      width,
      height,
      src: imageUrl,
    },
    ...options,
  });
};
