import { fetchWorkspaceList } from "@/server/api-server";
import { loggingError } from "@/server/error-log-server";
import { postWorkspace, putWorkspace } from "@/server/workspace-server";

export const namespaced = true;

export const state = {
  workspaceDictionary: {},

  playerWorkspace: undefined, // undefined | string; Empty string "" refers to select "All" workspace
  editorWorkspace: "",
};

export const getters = {
  getter_workspaces_with_workspace_groups_name: (state, getters, rootState, rootGetters) =>
    rootGetters["workspaceGroups/getter_can_view_workspace_groups"].flatMap((workspaceGroup) =>
      workspaceGroup.workspaces.map((workspace) => ({ workspaceGroupName: workspaceGroup.name, ...workspace }))
    ),
  getter_workspace_with_workspace_group_map: (state, getters) =>
    getters.getter_workspaces_with_workspace_groups_name.reduce(
      (result, workspace) => ({ ...result, [workspace.id]: workspace }),
      {}
    ),
  getter_workspace_dictionary: (state) => state.workspaceDictionary,

  getter_player_workspace: (state) => state.playerWorkspace,
  getter_editor_workspace: (state) => state.editorWorkspace,
};

export const mutations = {
  SET_WORKSPACE_DICTIONARY: (state, workspaceDictionary) => {
    state.workspaceDictionary = workspaceDictionary;
  },
  SET_PLAYER_WORKSPACE: (state, playerWorkspace) => {
    state.playerWorkspace = playerWorkspace;
  },
  SET_EDITOR_WORKSPACE: (state, editorWorkspace) => {
    state.editorWorkspace = editorWorkspace;
  },
};

export const actions = {
  createWorkspace: async ({ commit }, { data }) => await postWorkspace({ data }),
  patchWorkspace: async ({ commit }, { data }) => await putWorkspace({ data }),

  /** call this to reload dictionary whenever a workspace is updated */
  loadWorkspaceDictionary: async ({ commit }) => {
    try {
      const { ok, data: workspaceList } = await fetchWorkspaceList({ type: "view" });
      if (!ok) {
        commit("SET_WORKSPACE_DICTIONARY", {});
        return;
      }
      const workspaceDictionary = workspaceList.items.reduce((accu, workspace) => {
        accu[workspace.id] = workspace.name;
        return accu;
      }, {});
      commit("SET_WORKSPACE_DICTIONARY", workspaceDictionary);
    } catch (error) {
      console.error({ error });
      loggingError(error);
    }
  },

  setPlayerWorkspace({ commit }, playerWorkspace) {
    commit("SET_PLAYER_WORKSPACE", playerWorkspace);
  },
  setEditorWorkspace({ commit }, editorWorkspace) {
    commit("SET_EDITOR_WORKSPACE", editorWorkspace);
  },
};
