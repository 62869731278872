import { library } from "@fortawesome/fontawesome-svg-core";
// regular
import {
  faTimer,
  faForwardStep,
  faArrowLeft,
  faArrowProgress,
  faBallotCheck,
  faBell,
  faArrowPointer,
  faText,
  faPencil,
  faUpRight,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faCircleExclamation,
  faCircleInfo,
  faCirclePlay,
  faCirclePlus,
  faCircleQuestion,
  faComments,
  faCopy,
  faImage,
  faIndustryWindows,
  faLightbulb,
  faMagnifyingGlass,
  faMagnifyingGlassChart,
  faMessageCheck,
  faPenToSquare,
  faRectangleList,
  faRotate,
  faScissors,
  faSearch,
  faShare,
  faThumbsDown,
  faThumbsUp,
  faTrashCan,
  faTriangleExclamation,
  faXmark,
  faArrowRight,
  faGrid2,
  faList,
  faBookmark,
  faArrowsRotate,
  faStopwatch,
  faVideo,
  faArrowsRepeat,
  faInfo,
  faEye,
  faEyeSlash,
  faTrash,
  faPen,
  faLockKeyhole,
  faHourglassHalf,
  faArrowRightFromBracket,
  faMessage,
  faSignalBars,
  faUser,
  faRectangle,
  faRotateExclamation,
  faLayerGroup,
  faSliders,
  faListTimeline,
  faInfoCircle,
  faFileCirclePlus,
  faFileLines,
  faArrowRightArrowLeft,
  faFlag,
  faCode,
  faQrcode,
  faLink,
  faArrowUpRightFromSquare,
  faGear,
  faVolume,
  faVolumeSlash,
  faExpandWide,
  faCompressWide,
  faPlay,
  faClosedCaptioning,
  faCircleCheck,
  faCircleXmark,
  faLock,
  faTextSize,
  faCheck,
  faSquare,
  faTriangle,
  faCircle,
  faDroplet,
  faMinus,
  faBan,
  faHouse,
  faBullseyeArrow,
  faMagnifyingGlassWaveform,
} from "@fortawesome/pro-regular-svg-icons";

const regular = {
  addIcons() {
    library.add(
      faTimer,
      faForwardStep,
      faBell,
      faArrowPointer,
      faText,
      faPencil,
      faUpRight,
      faArrowProgress,
      faBallotCheck,
      faChevronLeft,
      faChevronRight,
      faSearch,
      faRectangleList,
      faXmark,
      faCirclePlay,
      faMessageCheck,
      faShare,
      faCopy,
      faThumbsUp,
      faThumbsDown,
      faTrashCan,
      faTriangleExclamation,
      faCircleExclamation,
      faIndustryWindows,
      faArrowRight,
      faLightbulb,
      faCirclePlus,
      faPenToSquare,
      faArrowLeft,
      faChevronsLeft,
      faChevronsRight,
      faMagnifyingGlass,
      faMagnifyingGlassChart,
      faCircleInfo,
      faComments,
      faImage,
      faScissors,
      faRotate,
      faCircleQuestion,
      faGrid2,
      faList,
      faBookmark,
      faArrowsRotate,
      faStopwatch,
      faVideo,
      faArrowsRepeat,
      faInfo,
      faEye,
      faEyeSlash,
      faTrash,
      faPen,
      faLockKeyhole,
      faHourglassHalf,
      faArrowRightFromBracket,
      faMessage,
      faSignalBars,
      faUser,
      faRectangle,
      faRotateExclamation,
      faLayerGroup,
      faSliders,
      faListTimeline,
      faInfoCircle,
      faFileCirclePlus,
      faFileLines,
      faArrowRightArrowLeft,
      faFlag,
      faCode,
      faQrcode,
      faLink,
      faArrowUpRightFromSquare,
      faGear,
      faVolume,
      faVolumeSlash,
      faExpandWide,
      faCompressWide,
      faPlay,
      faClosedCaptioning,
      faCircleCheck,
      faCircleXmark,
      faLock,
      faTextSize,
      faCheck,
      faSquare,
      faTriangle,
      faCircle,
      faDroplet,
      faMinus,
      faBan,
      faHouse,
      faBullseyeArrow,
      faMagnifyingGlassWaveform
    );
  },
};

export default regular;
