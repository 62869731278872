<template>
  <div class="NavigationMenu">
    <template v-for="(menuItem, idx) in menuList">
      <div
        v-if="menuItem.canAccess"
        :key="`${menuItem.key}-${idx}`"
        class="NavigationMenu__item"
        :class="{ 'NavigationMenu__item--active': activeMenuKey === menuItem.key }"
        :id="menuItem.id"
        @click="goToPage(menuItem.url)"
      >
        <font-awesome-icon :icon="menuItem.icon" class="NavigationMenu__item--icon" />
        <div>{{ menuItem.title }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { USER_ROLE } from "@/constants/userRole";
import { ROUTE_PATH } from "@/router/route";
import { ADMIN_ROUTE_NAMES } from "@/router/routes/AdminRoute";
import { ANALYTICS_ROUTE_NAMES } from "@/router/routes/AnalyticsRoute";
import { HOME_ROUTE_NAMES } from "@/router/routes/HomeRoute";
import { SKILL_ROUTE_NAMES } from "@/router/routes/SkillRoute";

import { OWNED_BY_ME_ROUTE_NAMES } from "@/router/routes/OwnedByMeRoute";
import { mapGetters } from "vuex";

export default {
  name: "NavigationMenu",
  props: {
    userRole: {
      type: String, // USER_ROLE
      required: true,
    },
    access: {
      type: Object, // defined in MixinUser
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["getter_user_ip_passed"]),

    activeMenuKey() {
      const currentRoute = this.$route.path;
      const currentRouteName = this.$route.name;
      return this.menuList.find((menu) => currentRoute === menu.url || menu.routeNames.includes(currentRouteName))?.key;
    },
    canUserRoleAccessOwnedByMe() {
      return [USER_ROLE.ADMIN_ORG, USER_ROLE.ADMIN_WORKSPACE, USER_ROLE.PUBLISHER].includes(this.userRole);
    },
    canUserRoleAccessSkill() {
      return [USER_ROLE.ADMIN_ORG].includes(this.userRole);
    },
    canUserRoleAccessAdminPages() {
      return [USER_ROLE.ADMIN_ORG, USER_ROLE.ADMIN_WORKSPACE].includes(this.userRole);
    },
    menuList() {
      return [
        {
          key: "home",
          title: this.$t("sidemenu.home"),
          icon: ["fas", "house"],
          id: "ug-home",
          url: ROUTE_PATH.HOME,
          routeNames: [...HOME_ROUTE_NAMES],
          canAccess: this.getter_user_ip_passed,
        },
        {
          key: "ownedByMe",
          title: this.$t("sidemenu.ownedByMe"),
          icon: ["fas", "pen"],
          id: "ug-ownedbyme",
          url: ROUTE_PATH.OWNED_BY_ME_WORKFLOWS,
          routeNames: [...OWNED_BY_ME_ROUTE_NAMES],
          canAccess: this.canUserRoleAccessOwnedByMe && this.getter_user_ip_passed,
        },
        {
          key: "skills",
          title: this.$t("sidemenu.skills"),
          icon: ["fas", "bullseye-arrow"],
          id: "ug-skill",
          url: ROUTE_PATH.SKILL_HOME,
          routeNames: [...SKILL_ROUTE_NAMES],
          canAccess: this.canUserRoleAccessSkill && this.getter_user_ip_passed && this.access.skills,
        },
        {
          key: "analytics",
          title: this.$t("sidemenu.analytics"),
          icon: ["fas", "chart-simple"],
          id: "ug-analytics",
          url: ROUTE_PATH.ANALYTICS_ORG,
          routeNames: [...ANALYTICS_ROUTE_NAMES],
          canAccess: this.getter_user_ip_passed && this.access.analytics,
        },
        {
          key: "admin",
          title: this.$t("sidemenu.admin"),
          icon: ["fas", "gear"],
          id: "ug-admin",
          url: ROUTE_PATH.ADMIN,
          routeNames: [...ADMIN_ROUTE_NAMES],
          canAccess: this.getter_user_ip_passed && this.access.admin,
        },
      ];
    },
  },
  methods: {
    goToPage(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.NavigationMenu {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: var(--spacing-04);

    padding: 12px 24px;
    color: var(--brand-white);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    &--active,
    &:hover {
      background-color: var(--dGrey3-color);
    }

    &--icon {
      flex: 0 0 auto;
      font-size: 16px;
      color: var(--brand-blue);
    }
  }
}
</style>
