import {
  fetchMultimodalSOPs,
  fetchMultimodalSOPById,
  createMultimodalSOP,
  updateMultimodalSOP,
  deleteMultimodalSOP,
  deleteMultimodalSOPItem,
  updateWholeMultimodalSOP,
} from "@/server/api-server";
import { createMultimodalSOPTracking } from "@/server/tracking-server";
import { formatQuizForBackend } from "../utils/quizUtils";
import { getBrowserVersion } from "@/js/getBrowserVersion.js";
import store from "@/store";
/**
 * @typedef {Object} MultimodalSOPItem
 * @property {('introduction'|'step'|'warning'|'quiz'|'tool'|'conclusion')} type
 * @property {string} title
 * @property {string} [description]
 * @property {Array} [attachments]
 * @property {Array} [questions]
 */

/**
 * @typedef {Object} MultimodalSOP
 * @property {string} id
 * @property {boolean} activate
 * @property {string} [workflowId]
 * @property {string} organizationId
 * @property {string} poster
 * @property {string} title
 * @property {string[]} multimodalSopItemId
 * @property {('workflow'|'pdf'|'manually')} type
 * @property {('pending'|'processing'|'completed'|'failure')} status
 * @property {string} createdBy
 * @property {string} updatedBy
 * @property {MultimodalSOPItem[]} [multimodalSopItem]
 */

export default class MultimodalSOPService {
  /**
   * Converts task manager data to multimodal SOP format
   * @param {Object} taskData - Task manager data
   * @param {Object} options - Additional options
   * @param {string} options.workspaceId - Workspace ID
   * @param {string} options.organizationId - Organization ID
   * @returns {Object} Multimodal SOP formatted data
   */
  static convertTaskToMultimodalSOP(taskData, { workspaceId, organizationId }, mode) {
    // Convert attachments to the new format
    // TODO: Add support for attachments
    const convertAttachments = (attachments = [], mode) => {
      return attachments
        .map((attachment) => {
          if (!attachment) return null;

          switch (attachment.type) {
            case "image":
              return {
                type: "diagram",
                diagram: attachment.data.gsUrl || attachment.data.url,
              };
            case "diagram":
              return {
                type: "diagram",
                diagram: attachment.data.gsUrl || attachment.data.url,
              };
            case "workflow":
              return {
                type: "workflow",
                workflow: mode ? attachment.data?.workflow || attachment.id : attachment.id,
              };
            case "step":
              return {
                type: "step",
                step: mode ? attachment.data?.step || attachment.id : attachment.id,
                workflow: mode
                  ? attachment.data?.workflow || attachment.workflowData?.workflow
                  : attachment.workflowData?.workflow || attachment.data?.workflow,
              };
            case "pdf":
              return {
                type: "pdf",
                pdf: {
                  link: attachment.data.gsUrl || attachment.data.url,
                  name: attachment.data.filename,
                  size: attachment.data.size,
                  thumbnail: attachment.data.thumbnail,
                },
              };
            case "document":
              return {
                type: "pdf",
                pdf: {
                  link: attachment.data.gsUrl || attachment.data.url,
                  name: attachment.data.filename,
                  size: attachment.data.size,
                  thumbnail: attachment.data.thumbnail,
                },
              };
            case "video":
              return {
                type: "videoClip",
                videoClip: {
                  link: attachment.data.gsUrl || attachment.data.url,
                  thumbnail: attachment.data.thumbnail,
                },
              };
            default:
              return null;
          }
        })
        .filter(Boolean);
    };
    // Convert a step to multimodal format
    const convertStep = (step, type = step.type) => {
      if (!step) return null;
      const description = step.description || step.content || "";

      // Check if ID starts with specific prefixes
      const shouldOmitId =
        step.id &&
        ["quiz", "warning", "step", "tool"].some((prefix) => step.id.toLowerCase().startsWith(prefix.toLowerCase()));

      const baseStep = {
        ...(shouldOmitId ? {} : { id: step.id }), // Only include ID if it doesn't start with specified prefixes
        type,
        title: step.title || type.charAt(0).toUpperCase() + type.slice(1),
        ...(description.trim() !== "" && { description }),
      };

      // For quiz type, format the quiz data using the common utility function
      if (type === "quiz" && step.quiz) {
        const formattedQuiz = formatQuizForBackend(step.quiz);

        return {
          ...baseStep,
          questions: formattedQuiz.questions.map((q) => ({
            type: q.type,
            title: q.title,
            correctAnswer: q.correctAnswer,
            options: q.options || {}, // Ensure options are included
          })),
        };
      }

      // For other types, add addons
      return {
        ...baseStep,
        addons: convertAttachments(step.attachments, mode),
      };
    };

    // Prepare multimodal SOP items array
    const multimodalSopItems = [];

    // Add introduction if exists
    if (taskData.introduction) {
      const introStep = convertStep(taskData.introduction, "introduction");
      if (introStep) multimodalSopItems.push(introStep);
    }

    // Add regular steps
    if (Array.isArray(taskData.steps)) {
      taskData.steps.forEach((step) => {
        const convertedStep = convertStep(step);
        if (convertedStep) multimodalSopItems.push(convertedStep);
      });
    }

    // Add conclusion if exists
    if (taskData.conclusion) {
      const conclusionStep = convertStep(taskData.conclusion, "conclusion");
      if (conclusionStep) multimodalSopItems.push(conclusionStep);
    }

    const fixPosterUrl = (poster) => {
      if (!poster) return null;
      return poster.gsUrl || poster.url || "";
    };

    // Build the complete multimodal SOP object
    const multimodalSOP = {
      workspaceId,
      organizationId,
      type: "manually",
      languageCode: taskData.languageCode || "en-us",
      title: taskData.title || "Untitled SOP",
      metadata: taskData.metadata,
    };

    // Only add poster if it exists and is not empty
    const posterUrl = fixPosterUrl(taskData.poster);
    if (posterUrl) {
      multimodalSOP.poster = posterUrl;
    }

    // Only add description if it's not empty
    if (taskData.description && taskData.description.trim() !== "") {
      multimodalSOP.description = taskData.description;
    }

    // Add multimodalSopItems
    if (taskData.mode === "load") {
      multimodalSOP.item = multimodalSopItems;
    } else {
      multimodalSOP.multimodalSopItems = multimodalSopItems;
    }

    return multimodalSOP;
  }

  /**
   * Fetches a paginated list of Multimodal SOPs
   * @param {Object} params
   * @param {string} params.workspaceId - Workspace ID
   * @param {number} [params.page=0] - Page number
   * @param {number} [params.size=10] - Items per page
   * @param {string} [params.orderBy="updatedAt"] - Sort field
   * @param {string} [params.order="desc"] - Sort order
   * @returns {Promise<{items: MultimodalSOP[], page: {dataCount: number, pageCount: number}}>}
   */
  static async getSOPs({ workspaceId, page = 0, size = 10, orderBy = "updatedAt", order = "desc" }) {
    try {
      const response = await fetchMultimodalSOPs({
        workspaceId,
        page,
        size,
        orderBy,
        order,
      });

      return {
        items: response.data.items || [],
        page: response.data.page || { dataCount: 0, pageCount: 0 },
      };
    } catch (error) {
      console.error("Failed to fetch Multimodal SOPs:", error);
      throw error;
    }
  }

  /**
   * Fetches a single Multimodal SOP by ID
   * @param {Object} params
   * @param {string} params.id - SOP ID
   * @param {boolean} [params.includeSubData=false] - Include detailed item data
   * @returns {Promise<MultimodalSOP>}
   */
  static async getSOPById({ id, includeSubData = false }) {
    try {
      const response = await fetchMultimodalSOPById({ id, includeSubData });
      return response;
    } catch (error) {
      console.error(`Failed to fetch Multimodal SOP with ID ${id}:`, error);
      throw error;
    }
  }

  /**
   * Creates a new Multimodal SOP
   * @param {Object} sop - SOP data
   * @returns {Promise<{id: string, status: string, multimodalSopItemId: string[]}>}
   */
  static async createSOP(sop) {
    try {
      const response = await createMultimodalSOP(sop);

      return response.data.item;
    } catch (error) {
      console.error("Failed to create Multimodal SOP:", error);
      throw error;
    }
  }

  /**
   * Updates an existing Multimodal SOP
   * @param {Object} params
   * @param {string} params.id - SOP ID
   * @param {Object} params.payload - Update data
   * @returns {Promise<MultimodalSOP>}
   */
  static async updateSOP({ id, payload }) {
    try {
      const response = await updateMultimodalSOP({ id, payload });
      return response.data.success;
    } catch (error) {
      console.error(`Failed to update Multimodal SOP with ID ${id}:`, error);
      throw error;
    }
  }

  static async updateWholeSOP({ id, payload }) {
    try {
      const response = await updateWholeMultimodalSOP({ id, payload });
      return response.data.success;
    } catch (error) {
      console.error(`Failed to update Multimodal SOP with ID ${id}:`, error);
      throw error;
    }
  }

  /**
   * Deletes a Multimodal SOP
   * @param {string} id - SOP ID
   * @returns {Promise<void>}
   */
  static async deleteSOP(id) {
    try {
      const response = await deleteMultimodalSOP(id);
      return response.data.success;
    } catch (error) {
      console.error(`Failed to delete Multimodal SOP with ID ${id}:`, error);
      throw error;
    }
  }

  static async deleteSOPItem(id) {
    if (!id) {
      console.error("❌ [MultimodalSOPService] No ID provided for SOP item deletion");
      throw new Error("ID is required to delete a SOP item");
    }

    try {
      const response = await deleteMultimodalSOPItem(id);

      return response;
    } catch (error) {
      console.error("❌ [MultimodalSOPService] Error deleting SOP item:", error);
      throw error;
    }
  }

  /**
   * Tracks the completion of a Multimodal SOP
   * @param {Object} params
   * @param {string} params.multimodalSopId - The ID of the multimodal SOP
   * @param {number} params.onEnterTime - Timestamp when user entered the SOP
   * @param {boolean} params.isRedirecting - Whether user will be redirected after completion
   * @returns {Promise<Object>} Tracking response
   */
  static async trackSOPCompletion({ multimodalSopId, onEnterTime, isRedirecting = false }) {
    try {
      const currentTime = Date.now();
      const deviceInfo = getBrowserVersion();

      const location = {};

      const payload = {
        multimodalSopId,
        deviceInfo,
        location,
        organization: store.getters["auth/getter_user_organization"],
        uid: store.getters["auth/getter_user_id"],
        email: store.getters["auth/getter_user_email"],
        lastUpdated: currentTime,
        lastSync: currentTime,
        onChangeItemFromMenu: [],
        onChangeLanguage: [],
        onChangeNavigationMode: [],
        onEnter: onEnterTime,
        selfCheckAt: currentTime,
        onExit: isRedirecting ? currentTime : null, // Set onExit same as selfCheckAt if redirecting
        duration: (currentTime - onEnterTime) / 1000, // transform to seconds
        items: {}, // Empty items map as per requirement
      };
      const response = await createMultimodalSOPTracking(payload);
      return response.data;
    } catch (error) {
      console.error("❌ [MultimodalSOPService] Error tracking SOP completion:", error);
      throw error;
    }
  }
}
