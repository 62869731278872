<template>
  <div class="DIconCreateSkill">
    <font-awesome-icon :icon="['fat', 'bullseye-arrow']" class="icon" />
    <font-awesome-icon :icon="['fat', 'circle-plus']" class="text-primary icon--bottom-right" />
  </div>
</template>

<script>
export default {
  name: "DIconCreateSkill",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.DIconCreateSkill {
  position: relative;
  .icon {
    color: var(--dGrey6-color);
    font-size: 100px;
  }
  .icon--bottom-right {
    position: absolute;
    bottom: -5px;
    left: -10px;
    font-size: 48px;
    background-color: var(--dGrey2-color);
    border-radius: 100%;
    padding: 5px;
  }
}
</style>
