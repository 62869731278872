<!-- Usage: Replace the DDialog header with Large Icon Header using "slot:header" -->
<!-- Example:
  <d-dialog>
    <template slot="header">
      <d-dialog-icon-header
        title="Hello Title!"
        subtitle="this is the description about what thie dialog does"
      >
        <template slot="icon"></template>
        <template slot="extra-info"></template>
      </d-dialog-icon-header>
    </template>
  </d-dialog>
-->
<!-- Note: If you use theme="light", make sure to apply "closeButtonStyle" to DDialog
  <d-dialog
    theme="light"
    :closeButtonStyle="{
      color: '#4a4a4a', // recommended color!
    }"
  >
    <template slot="header">
      <d-dialog-icon-header
        theme="light"
        title="Hello Title!"
      >
        <template slot="icon"></template>
      </d-dialog-icon-header>
    </template>
  </d-dialog>
-->
<template>
  <d-layout column class="DDialog__IconHeader" :class="theme">
    <d-layout column class="DDialog__IconHeaderIcon">
      <slot name="icon"></slot>
    </d-layout>

    <d-layout column class="DDialog__IconHeaderInfo">
      <div class="DDialog__IconHeaderTitle">
        <slot name="title">{{ title }}</slot>
      </div>
      <div v-if="showSubtitle" class="DDialog__IconHeaderSubtitle">
        <slot name="subtitle">{{ subtitle }}</slot>
      </div>
      <slot name="extra-info"></slot>
    </d-layout>
  </d-layout>
</template>

<script>
export default {
  name: "DDialogIconHeader",
  props: {
    theme: {
      type: String, // 'dark' | 'light'
      default: "dark",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
  computed: {
    showSubtitle() {
      return this.subtitle || this.$slots.subtitle;
    },
  },
};
</script>

<style lang="scss" scoped>
.DDialog__IconHeader {
  min-height: 208px;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  &Icon {
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
  }
  &Info {
    align-items: center;
    justify-content: center;
    row-gap: 10px;
  }
  &Title {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    text-align: center;
  }
  &Subtitle {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}

// dark theme (new ui style)
.DDialog__IconHeader.dark {
  .DDialog__IconHeaderTitle {
    color: var(--brand-white);
  }
  .DDialog__IconHeaderSubtitle {
    color: var(--dGrey6-color);
  }
}
//
.DDialog__IconHeader.light {
  background-color: var(--brand-white);
  margin: -24px;
  padding: 24px;
  .DDialog__IconHeaderTitle {
    color: #52545d;
  }
  .DDialog__IconHeaderSubtitle {
    color: var(--dGrey6-color);
  }
}
</style>
