<template>
  <d-teleport to=".App">
    <div class="AttachmentDiagramsPopup" :class="{ 'AttachmentDiagramsPopup--mobile': isSmallToolboxSize }">
      <div v-if="isSmallToolboxSize" class="AttachmentDiagramsPopup__topBar--mobile">
        <button class="AttachmentDiagramsPopup__topBar--closeBtnMobile" @click="$emit('close-popup')">
          <icon-base height="16" width="16" color="#ffffff">
            <d-icon-arrow-back />
          </icon-base>
        </button>
        <div class="AttachmentDiagramsPopup__numText" v-html="mobileDiagramsNum"></div>
        <div class="AttachmentDiagramsPopup__topBar--mobileLine"></div>
      </div>
      <div v-else class="AttachmentDiagramsPopup__topBar">
        <div class="AttachmentDiagramsPopup__topBar--title text-no-wrap text-truncate">
          <span>{{ $t("all.step") }} {{ stepNum }}.</span>
          {{ title }}
        </div>
        <button class="AttachmentDiagramsPopup__topBar--closeBtn" @click="$emit('close-popup')">
          <icon-base color="#ffffff" height="20" width="20">
            <d-icon-close-2 />
          </icon-base>
        </button>
      </div>
      <div class="AttachmentDiagramsPopup__content">
        <div
          class="AttachmentDiagramsPopup__content--imgDiv"
          :class="{ 'AttachmentDiagramsPopup__content--imgDivMobile': isSmallToolboxSize }"
        >
          <d-carousel
            :value="currentIndex"
            :pagingNumber="!isSmallToolboxSize"
            :pagingDots="isSmallToolboxSize"
            :items="getter_current_step_attachments_diagrams"
            @input="updateCurrentIndex($event)"
          />
        </div>
      </div>
    </div>
  </d-teleport>
</template>

<script>
import DTeleport from "@/components/ui_components/DTeleport.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DCarousel from "@/components/ui_components/DCarousel.vue";
import { getPagingNumber } from "@/js/common/formatText.js";
import { mapGetters } from "vuex";

export default {
  name: "AttachmentDiagramsPopup",
  components: {
    DTeleport,
    DIconArrowBack,
    DIconClose2,
    DCarousel,
  },
  props: {
    isSmallToolboxSize: Boolean,
    selectedDiagramIdx: Number,
    stepNum: String,
    title: String,
  },
  created() {
    this.currentIndex = this.selectedDiagramIdx;
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    updateCurrentIndex(event) {
      this.currentIndex = event;
    },
  },
  computed: {
    ...mapGetters("workflowPlayer", ["getter_current_step_attachments_diagrams"]),
    mobileDiagramsNum() {
      return getPagingNumber({
        current: this.currentIndex + 1,
        total: this.getter_current_step_attachments_diagrams.length,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$barHeight: 72px;
.AttachmentDiagramsPopup {
  z-index: 999;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 4px;
  background: #121314ca;
  overflow: hidden;
  &--mobile {
    position: absolute;
    background: #121314;
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &__topBar {
    height: $barHeight;
    width: 100%;
    padding: 0 32px;
    background: #1d1e21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    &--mobile {
      position: relative;
      width: 100%;
      padding: 24px 24px 0 24px;
      text-align: right;
    }
    &--closeBtnMobile {
      position: absolute;
      left: 14px;
      top: 20px;
      padding: 4px;
    }
    &--mobileLine {
      position: absolute;
      left: 12px;
      top: 58px;
      width: calc(100% - 24px);
      border-bottom: 1px solid #33353a;
    }
    &--title {
      width: calc(100% - 40px);
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      span {
        font-weight: 400;
        color: #9397a6;
      }
    }
    &--closeBtn {
      height: 28px;
      width: 28px;
      padding-top: 4px;
    }
  }
  &__content {
    position: relative;
    height: calc(100% - #{$barHeight});
    width: 100%;
    &--imgDiv {
      position: relative;
      top: 56px;
      width: 80%;
      height: calc(100% - 150px);
      margin: 0 auto;
      @media screen and (max-width: 599px) {
        width: calc(100% - 32px);
      }
      @media screen and (min-width: 600px) and (max-width: 959px) {
        width: 90%;
      }
    }
    &--imgDivMobile {
      width: 90%;
      height: calc(100% - 30px);
    }
  }
  &__numText {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    & ::v-deep span {
      font-weight: 400;
      color: #9397a6;
    }
  }
}
</style>
