<template>
  <section class="mixin-avatar" />
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import { getImageBlob, getSignedImageUrl } from "@/js/common/imageLib.js";
import { mapActions, mapGetters } from "vuex";

var subscribeList = {};

export default {
  name: "mixin-avatar",
  props: [],
  mixins: [MixinDB],
  mounted() {},
  beforeDestroy() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("global", ["cached_avatars"]),
  },
  methods: {
    ...mapActions("global", ["cacheAvatarToStore", "updateCachedAvatarUrl"]),
    async getUserAvatar(userId, callback) {
      const avatar = { url: "", userId: userId };
      if (this.hasCacheOrLoading(userId, avatar, callback)) return;

      try {
        const userDoc = await this.getDocument("users", userId);

        if (this.hasCacheOrLoading(userId, avatar, callback)) return;

        if (userDoc && userDoc.avatar) {
          this.cacheAvatarToStore({ userId, avatar });
          const blobUrl = await this.handleGetUserAvatarBlob(userDoc.avatar);
          this.updateCachedAvatarUrl({ userId, url: blobUrl });
          avatar.url = blobUrl;
          callback(avatar);
          this.publish(userId, avatar);
        } else {
          callback(avatar);
        }
      } catch (e) {
        callback(avatar);
      }
    },
    hasCacheOrLoading(userId, avatar, callback) {
      const self = this;
      if (self.cached_avatars[userId] && self.cached_avatars[userId].url) {
        //console.log("cache", userId, self.cached_avatars[userId].url);
        avatar.url = self.cached_avatars[userId].url;
        callback(avatar);
        return true;
      }

      if (self.cached_avatars[userId] && self.cached_avatars[userId].userId === userId) {
        //console.log("loading", userId);
        self.subscribe(userId, callback);
        return true;
      }

      return false;
    },
    subscribe(userId, callback) {
      try {
        const id = this.uuidv4();
        const subscribe = {
          id: id,
          callback: callback,
          unsubscribe: () => {
            //console.log("unsubscribe", id, subscribeList[userId][id]);
            delete subscribeList[userId][id];
          },
        };

        if (!subscribeList[userId]) {
          subscribeList[userId] = {};
        }
        //console.log("subscribeList subscribe", userId, subscribe);
        subscribeList[userId][id] = subscribe;
        //console.log("subscribeList subscribe finished", userId, subscribeList);

        return true;
      } catch (e) {
        console.warn(e);
        return false;
      }
    },
    publish(userId, avatar) {
      try {
        if (!subscribeList[userId]) {
          return false;
        }

        //console.log("subscribeList publish", userId, avatar);
        Object.keys(subscribeList[userId]).forEach((id) => {
          //console.log("subscribe");
          subscribeList[userId][id].callback(avatar);
        });

        // unsubscribe after published
        Object.keys(subscribeList[userId]).forEach((id) => {
          subscribeList[userId][id].unsubscribe();
        });
        delete subscribeList[userId];
        //console.log("subscribeList publish finished", userId, subscribeList);

        return true;
      } catch (e) {
        console.warn(e);
        return false;
      }
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    async handleGetUserAvatarBlob(src) {
      const signedUrl = await getSignedImageUrl(src);
      const { ok, url: blobUrl } = await getImageBlob(signedUrl);
      return ok ? blobUrl : signedUrl;
    },
    async updateUserAvatar(userId, url, callback) {
      let blobUrl = await this.handleGetUserAvatarBlob(url);
      let avatar = {
        url: blobUrl,
        userId: userId,
      };
      this.cacheAvatarToStore({ userId, avatar });
      callback(avatar);
      this.publish(userId, avatar);
    },
  },
};
</script>
