// refs: https://docs.fontawesome.com/apis/javascript/import-icons/#icon-names
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import regular from "./regular-icons";
import solid from "./solid-icons";
import thin from "./thin-icons";
import light from "./light-icons";

const FaIcon = {
  install(Vue) {
    regular.addIcons();
    solid.addIcons();
    thin.addIcons();
    light.addIcons();

    Vue.component("font-awesome-icon", FontAwesomeIcon);
  },
  getLibrary() {
    return library;
  },
};

export default FaIcon;
