// sub-category api doc:
// https://docs.google.com/document/d/1Lqqj6WfOWUIokv-9q4pc8MAF0cqRtOAy/edit

import deephowApiServerWrapper from "@/server/api-server";

export const createSubcategory = (categoryId, payload) =>
  deephowApiServerWrapper("post", `/v1/categories/${categoryId}/subcategories`, payload);

export const deleteSubcategory = (subcategoryId) =>
  deephowApiServerWrapper("delete", `/v1/categories/subcategories/${subcategoryId}`);

export const fetchSubcategories = (params) => deephowApiServerWrapper("get", "/v1/subcategories", { params });

export const fetchSubcategoriesByCategoryId = (categoryId) =>
  deephowApiServerWrapper("get", `/v1/categories/${categoryId}/subcategories`);

export const updateSubcategory = (subcategoryId, payload) =>
  deephowApiServerWrapper("patch", `/v1/categories/subcategories/${subcategoryId}`, payload);
