/** Global Events registered via eventbus
 *
 *  import eventbus from '@/main';
 *  import { EVENT } from '@/constants/globalEvents';
 *
 *  eventbus.$emit(EVENT.XXX);
 *  eventbus.$on(EVENT.XXX, () => { doSomething() });
 *  eventbus.$off(EVENT.XXX);
 */
const EVENT = {
  /** Triggers the global workflow uploader popup */
  SHOW_CREATE_WORKFLOW_POP: "showUploader",
  /** Triggers the global create MMSOP popup */
  SHOW_CREATE_MM_SOP_POP: "showCreateMMSOPPop",
  /** Forces a refresh of workflow-related pages */
  REFRESH_WORKSPACE_WORKFLOWS: "refresh-workspace-workflows",
  /** Updates workspace info after edits (e.g., favorite, rename, color change) */
  UPDATE_WORKSPACE_INFO: "updateWorkspaceInfo",
};

export { EVENT };
