<template>
  <div class="DHeader">
    <deephow-logo class="logo" />
    <div class="DHeader__tools">
      <template v-if="!isMobileDevice">
        <header-create-button v-if="showCreateSkillButton" @openCreateSkill="openCreateSkillPop" />
        <button class="DHeader__searchBtn" @click="toExplorerSearch()" autotest="navigator-search">
          <font-awesome-icon :icon="['far', 'magnifying-glass']" size="lg" />
        </button>
      </template>
      <user-notifications :isMobileDevice="false" class="explorer-part" />
      <user-avatar
        v-if="!isMobileDevice"
        :class="{ 'DHeader__avatar--active': isUserMenuOpen }"
        @open-user-menu="$emit('open-user-menu')"
      />
    </div>

    <d-create-skill-popup v-if="isOpenCreateSkillPop" @close="closeCreateSkillPop" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { IS_MOBILE } from "@/constants/device-version";
import { ROUTE_PATH } from "@/router/route";

import DCreateSkillPopup from "@/components/ui_components/DCreateSkillPopup.vue";
import UserAvatar from "@/components/DUser/UserAvatar";
import UserNotifications from "@/components/DNotification/UserNotifications.vue";
import HeaderCreateButton from "./HeaderCreateButton.vue";
import DeephowLogo from "@/components/icons/logo/DeephowLogo.vue";
import { USER_ROLE } from "@/constants/userRole";
export default {
  name: "DHeader",
  components: {
    UserAvatar,
    UserNotifications,
    HeaderCreateButton,
    DCreateSkillPopup,
    DeephowLogo,
  },

  data() {
    return {
      isMobileDevice: IS_MOBILE,
      isOpenCreateSkillPop: false,
    };
  },
  computed: {
    ...mapGetters("global", ["getter_is_user_menu_open"]),
    ...mapGetters("auth", ["getter_user_role"]),
    isUserMenuOpen() {
      return this.getter_is_user_menu_open;
    },
    showCreateSkillButton() {
      return this.getter_user_role !== USER_ROLE.VIEWER;
    },
  },
  methods: {
    toExplorerSearch() {
      this.$router.push(ROUTE_PATH.PLAYER_EXPLORER_SEARCH);
    },
    openCreateSkillPop() {
      this.isOpenCreateSkillPop = true;
    },
    closeCreateSkillPop() {
      this.isOpenCreateSkillPop = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.DHeader {
  position: relative;
  width: 100%;
  min-height: var(--header-height);
  padding: 0 32px 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: var(--dGrey1-color);
  @include mobile {
    justify-content: space-between;
    padding: 15px 24px 15px 70px;
  }

  .logo {
    width: 96px;
    display: none;
    @include mobile {
      display: block;
    }
  }
  &__items {
    display: flex;
    align-items: center;
  }

  &__tools {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }

  &__searchBtn {
    height: 40px;
    width: 40px;
    color: var(--dGrey6-color);
    &:hover {
      color: var(--brand-white);
    }
  }

  &__avatar--active {
    z-index: calc(var(--z-index-dialog) + var(--z-index-above));
  }
}
</style>
