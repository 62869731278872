export const SOP_TYPE = {
  WORKFLOW: "workflows",
  MMSOP: "multimodal-sops",
};

export const SOP_TYPE_ICON = {
  WORKFLOW: ["fas", "video"],
  MMSOP: ["fas", "list-dropdown"],
};

export const BE_SOP_TYPE = {
  WORKFLOW: "workflows",
  MMSOP: "multimodalSops",
};
