import { IS_CHINA } from "@/constants/env";

const generatePathname = () => {
  let pathname = window.location.pathname;
  if (pathname !== "/") return pathname;

  const urlParams = new URLSearchParams(window.location.search);
  const queryValue = urlParams.get("redirect");
  if (!queryValue) return "/";

  return decodeURIComponent(queryValue);
};

export const isIpadOrIphoneDevice = () => {
  return /iPhone|iPad/i.test(navigator.userAgent);
};

export const isIosDevice = () => {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const generateLinkToIOSApp = () => {
  const pathname = generatePathname();
  return IS_CHINA ? `deephowNavigatorChina:/${pathname}` : `deephowNavigator:/${pathname}`;
};
export const goToAppStore = () => {
  if (IS_CHINA) {
    window.location.href = "https://apps.apple.com/us/app/deephow-navigator-china/id6451498590";
  } else {
    window.location = "https://apps.apple.com/us/app/deephow-navigator/id6451498531";
  }
};
