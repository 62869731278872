<template>
  <div class="NotificationCardBase">
    <div class="NotificationCardBase__top">
      <div class="NotificationCardBase__top--avatar">
        <slot name="avatar"></slot>
      </div>
      <div class="NotificationCardBase__top--message" v-html="message"></div>
      <div class="NotificationCardBase__top--time">
        {{ timeSince }}&nbsp;&nbsp;
        <span class="NotificationCardBase__top--blueDot" :class="{ 'NotificationCardBase__top--noDot': read }"></span>
      </div>
    </div>
    <slot name="workflow-message"></slot>

    <div class="NotificationCardBase__content">
      <div class="NotificationCardBase__content--poster">
        <d-image :aspectRatio="16 / 9" :src="poster" />
      </div>
      <div class="NotificationCardBase__content--title">
        <div class="NotificationCardBase__content--titleText">{{ title }}</div>
        <div class="NotificationCardBase__content--info">
          <slot name="workflow-info">
            <span>{{ time }}</span>
            <span class="NotificationCardBase__content--infoDot"></span>
            <span>{{ info }}</span>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DImage from "@/components/ui_components/DImage.vue";

export default {
  name: "NotificationCardBase",
  props: {
    message: String,
    timeSince: String,
    read: Boolean,
    poster: String,
    title: String,
    time: String,
    info: String,
  },
  components: {
    DImage,
  },
};
</script>

<style lang="scss" scoped>
.NotificationCardBase {
  position: relative;
  width: 100%;
  &__top {
    position: relative;
    width: 100%;
    min-height: 34px;
    padding-top: 4px;
    color: var(--dGrey6-color);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    display: flex;
    text-align: left;
    &--avatar {
      position: relative;
      top: -4px;
      min-width: 56px;
    }
    &--message {
      width: calc(100% - 120px);
      min-width: calc(100% - 120px);
      ::v-deep span {
        color: var(--brand-white);
      }
    }
    &--time {
      width: 100%;
      white-space: nowrap;
      padding-left: 8px;
      text-align: right;
    }
    &--blueDot {
      height: 8px;
      width: 8px;
      min-width: 8px;
      background-color: var(--brand-blue);
      border-radius: 50%;
      display: inline-block;
    }
    &--noDot {
      background-color: transparent;
    }
  }
  &__content {
    position: relative;
    width: 100%;
    height: 54px;
    margin-top: 18px;
    display: flex;
    &--poster {
      width: 96px;
      height: 54px;
      background-color: var(--dGrey1-color);
    }
    &--title {
      position: relative;
      margin-left: 16px;
      height: 100%;
      width: calc(100% - 130px);
    }
    &--titleText {
      position: absolute;
      width: 100%;
      height: 34px;
      top: -2px;
      left: 0;
      color: var(--brand-white);
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      word-wrap: break-word;
      word-break: break-all;
      @include clamp-text(2);
    }
    &--info {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: var(--dGrey6-color);
      font-size: 12px;
      line-height: 14px;
      text-align: left;
    }
    &--infoDot {
      height: 3px;
      width: 3px;
      color: var(--dGrey6-color);
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 3px;
    }
  }
}
</style>
