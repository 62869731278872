export const ROUTE_PATH = {
  ADMIN: "/admin/",
  ADMIN_WORKSPACE_GROUPS: "/admin/workspace-groups",
  ADMIN_WORKSPACE_GROUP: "/admin/workspace-group/:workspaceGroupId",
  ADMIN_WORKSPACES: "/admin/workspaces",
  ADMIN_TEAMS: "/admin/teams",
  ADMIN_USERS: "/admin/users",
  ADMIN_BUSINESS: "/admin/business",
  ADMIN_CATEGORIES: "/admin/categories",
  ADMIN_CATEGORY_SUCATEGORIES: "/admin/category/:categoryId",
  ADMIN_INTEGRATIONS: "/admin/integrations",
  ADMIN_SETTING_MAVEN: "/admin/setting/maven",
  ADMIN_SETTING_INTERFACE_LANGUAGE: "/admin/setting/interface-language",
  ADMIN_SETTING_PERMISSIONS: "/admin/setting/permissions",
  ADMIN_SETTING_MASTER_PASSWORD: "/admin/setting/master-password",
  ADMIN_SETTING_IP_RESTRICTION: "/admin/setting/ip-restriction",
  ADMIN_SETTING_QR_CODE: "/admin/setting/qr-code",

  ANALYTICS: "/analytics",
  ANALYTICS_ORG: "/analytics/main",
  ANALYTICS_WORKSPACES: "/analytics/workspaces",
  ANALYTICS_WORKFLOWS: "/analytics/workflows",
  ANALYTICS_WORKFLOWS_DETAIL: "/analytics/workflows/detail",
  ANALYTICS_WORKFLOWS_TREND: "/analytics/workflows/trend",
  ANALYTICS_WORKFLOW: "/analytics/workflow/:workflowId",
  ANALYTICS_MM_SOPS_DETAIL: "/analytics/mm-sops/detail",
  ANALYTICS_MM_SOPS_TREND: "/analytics/mm-sops/trend",
  ANALYTICS_SKILLS_PERFORMANCE: "/analytics/skills/performance",
  ANALYTICS_QUIZ: "/analytics/quiz",
  ANALYTICS_QUIZ_WORKFLOW: "/analytics/quiz/workflow/:workflowId",
  ANALYTICS_QUIZ_WORKFLOW_USER: "/analytics/quiz/:quizId/workflow/:workflowId/user/:userId",
  ANALYTICS_QUIZ_PERFORMANCE: "/analytics/quiz/performance",
  ANALYTICS_USERS: "/analytics/users",
  ANALYTICS_USER: "/analytics/user/:userId",

  BUILDER_MAIN: "/buildermain/:workflowId",
  EDITOR_MAIN: "/editormain/:workflowId",
  EDITOR_TRANSCRIPTION: "/editormain/:workflowId/transcription",
  EDITOR_SEGMENTATION: "/editormain/:workflowId/segmentation",

  HOME: "/home",
  HOME_OVERVIEW: "/home/overview",
  HOME_DISCOVER: "/home/discover",
  HOME_SKILLS_TODO: "/home/skills-todo",
  HOME_SKILLS_COMPLETED: "/home/skills-completed",
  HOME_FAVORITES: "/home/favorites",

  PLAYER_EXPLORER_SEARCH: "/explorer-search",

  SKILL: "/skill",
  SKILL_HOME: "/skill/home",
  SKILL_BUILDER: "/skillbuilder/:playlistId",
  SKILL_BUILDER_CONTENT: "/skillbuilder/:playlistId/content",
  SKILL_BUILDER_USERS: "/skillbuilder/:playlistId/users",
  SKILL_BUILDER_ASSESSMENT: "/skillbuilder/:playlistId/assessment",
  SKILL_BUILDER_CERTIFICATION: "/skillbuilder/:playlistId/certification",
  SKILL_PLAYER: "/skillplayer",
  SKILL_PLAYER_MM_SOP: "/skillplayer/:skillId/mm-sop/:smartInstructionId",

  OWNED_BY_ME: "/owned-by-me",
  OWNED_BY_ME_WORKFLOWS: "/owned-by-me/workflows",
  OWNED_BY_ME_MM_SOP: "/owned-by-me/mm-sop",
  OWNED_BY_ME_SKILLS: "/owned-by-me/skills",
  OWNED_BY_ME_TEAMS: "/owned-by-me/teams",

  WORKSPACE: "/workspace",
  WORKSPACE_WORKFLOW: "/workspace/:workspaceId/workflow",
  WORKSPACE_MM_SOP: "/workspace/:workspaceId/mm-sop",
  WORKSPACE_SKILLS: "/workspace/:workspaceId/skills",
  WORKSPACE_SKILL_MATRIX: "/workspace/:workspaceId/skill-matrix",
  WORKSPACE_INSIGHTS: "/workspace/:workspaceId/insights",

  USER_PROFILE: "/UserProfile",

  MAVEN: "/maven",

  SMART_INSTRUCTION: "/smart-instruction",
};

export const ROUTE_NAME = {
  ADMIN: "Admin",
  ADMIN_WORKSPACE_GROUPS: "AdminWorkspaceGroups",
  ADMIN_WORKSPACE_GROUP: "AdminWorkspaceGroup",
  ADMIN_WORKSPACES: "AdminWorkspaces",
  ADMIN_TEAMS: "AdminTeams",
  ADMIN_USERS: "AdminUsers",
  ADMIN_BUSINESS: "AdminBusiness",
  ADMIN_CATEGORIES: "AdminCategories",
  ADMIN_CATEGORY_SUCATEGORIES: "AdminCategorySubcategories",
  ADMIN_INTEGRATIONS: "AdminIntegrations",
  ADMIN_SETTING_MAVEN: "AdminSettingMaven",
  ADMIN_SETTING_INTERFACE_LANGUAGE: "AdminSettingInterfaceLanguage",
  ADMIN_SETTING_PERMISSIONS: "AdminSettingPermissions",
  ADMIN_SETTING_MASTER_PASSWORD: "AdminSettingMasterPassword",
  ADMIN_SETTING_IP_RESTRICTION: "AdminSettingIpRestriction",
  ADMIN_SETTING_QR_CODE: "AdminSettingQrCode",

  ANALYTICS: "Analytics",
  ANALYTICS_ORG: "AnalyticsMain",
  ANALYTICS_WORKSPACES: "AnalyticsWorkspaces",
  ANALYTICS_WORKFLOWS: "AnalyticsWorkflows",
  ANALYTICS_WORKFLOWS_DETAIL: "AnalyticsWorkflowsDetail",
  ANALYTICS_WORKFLOWS_TREND: "AnalyticsWorkflowsTrend",
  ANALYTICS_WORKFLOW: "AnalyticsWorkflow",
  ANALYTICS_MM_SOPS_DETAIL: "AnalyticsMMSopsDetail",
  ANALYTICS_MM_SOPS_TREND: "AnalyticsMMSopsTrend",
  ANALYTICS_SKILLS_PERFORMANCE: "AnalyticsSkillsPerformance",
  ANALYTICS_QUIZ: "AnalyticsQuiz",
  ANALYTICS_QUIZ_WORKFLOW: "AnalyticsQuizWorkflow",
  ANALYTICS_QUIZ_WORKFLOW_USER: "AnalyticsQuizWorkflowUser",
  ANALYTICS_QUIZ_PERFORMANCE: "AnalyticsQuizPerformance",
  ANALYTICS_USERS: "AnalyticsUsers",
  ANALYTICS_USER: "AnalyticsUser",

  BUILDER_MAIN: "BuilderMain",
  EDITOR_MAIN: "EditorMain",
  EDITOR_TRANSCRIPTION: "EditorTranscription",
  EDITOR_SEGMENTATION: "EditorSegmentation",

  HOME: "Home",
  HOME_OVERVIEW: "HomeOverview",
  HOME_DISCOVER: "HomeDiscover",
  HOME_SKILLS_TODO: "HomeSkillsToDo",
  HOME_SKILLS_COMPLETED: "HomeSkillsCompleted",
  HOME_FAVORITES: "HomeFavorites",

  PLAYER_EXPLORER_SEARCH: "PlayerExplorerSearch",

  SKILL: "Skill",
  SKILL_HOME: "SkillHome",
  SKILL_BUILDER: "SkillBuilder",
  SKILL_BUILDER_CONTENT: "SkillBuilderContent",
  SKILL_BUILDER_USERS: "SkillBuilderUsers",
  SKILL_BUILDER_ASSESSMENT: "SkillBuilderAssessment",
  SKILL_BUILDER_CERTIFICATION: "SkillBuilderCertification",
  SKILL_PLAYER: "SkillPlayer",
  SKILL_PLAYER_MM_SOP: "SkillPlayerMMSOP",

  OWNED_BY_ME: "OwnedByMe",
  OWNED_BY_ME_WORKFLOWS: "OwnedByMeWorkflows",
  OWNED_BY_ME_MM_SOP: "OwnedByMeMMSOP",
  OWNED_BY_ME_SKILLS: "OwnedByMeSkills",
  OWNED_BY_ME_TEAMS: "OwnedByMeTeams",

  WORKSPACE: "Workspace",
  WORKSPACE_WORKFLOW: "WorkspaceWorkflow",
  WORKSPACE_MM_SOP: "WorkspaceMMSOP",
  WORKSPACE_SKILLS: "WorkspaceSkills",
  WORKSPACE_SKILL_MATRIX: "WorkspaceSkillMatrix",
  WORKSPACE_INSIGHTS: "WorkspaceInsights",

  USER_PROFILE: "UserProfileMain",

  MAVEN: "Maven",
  SMART_INSTRUCTION: "SmartInstruction",
};
