<template>
  <div class="DListItem" :class="{ 'DListItem--disabled': disabled }" @click="$emit('click')">
    <slot name="icon" />
    <div class="DListItem__content">
      <slot>
        <div v-if="title" class="DListItem__title" :autotest="titleAutotest">{{ title }}</div>
        <div v-if="subtitle" class="DListItem__subtitle" :autotest="subtitleAutotest">{{ subtitle }}</div>
      </slot>
    </div>
    <slot name="action" />
  </div>
</template>

<script>
export default {
  name: "DListItem",
  components: {},
  mixins: [],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    titleAutotest: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    subtitleAutotest: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.DListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  color: var(--dGrey6-color);
  cursor: pointer;

  &:hover {
    background-color: #9b9b9b1c !important;
  }
  &--disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &__content {
    flex: 1;
    overflow: hidden;
  }
  &__title {
    color: var(--brand-white);
    font-size: 16px;
    line-height: 24px;
    @include truncate-text;
  }
  &__subtitle {
    color: var(--dGrey6-color);
    font-size: 14px;
    line-height: 14px;
    @include truncate-text;
  }
}
</style>
