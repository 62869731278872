<template>
  <section class="DSideMenu" :class="[getter_is_side_menu_open ? 'DSideMenu--open' : 'DSideMenu--close']">
    <button v-show="!getter_is_side_menu_open" class="DSideMenu__closeButton" @click="toggleSideMenu">
      <font-awesome-icon :icon="['far', 'chevrons-right']" />
    </button>
    <div class="DSideMenu__wrapper" v-show="getter_is_side_menu_open">
      <div class="DSideMenu__header">
        <router-link to="/" class="DSideMenu__header-logo--wrapper">
          <deephow-logo class="DSideMenu__header-logo--logo" />
        </router-link>
        <font-awesome-icon :icon="['far', 'chevrons-left']" class="DSideMenu__expand-btn" @click="toggleSideMenu" />
      </div>

      <d-maven-chat-side-menu v-if="isShowMavenMenu" @close="closeMavenMenu" />
      <template v-else>
        <div class="DSideMenu__content">
          <template v-if="!isMobileDevice">
            <navigation-menu class="DSideMenu__navigationMenu" :userRole="userRole" :access="access" />
            <hr class="DSideMenu__divider" />
          </template>
          <div class="DSideMenu__searchInput">
            <d-search-input v-model="keyword" :placeholder="$t('sidemenu.searchPlaceholder')" />
          </div>
          <nav-my-workspace-menu class="DSideMenu__workspaceMenu" :keyword="keyword" />
          <hr class="DSideMenu__divider" />
          <nav-all-workspace-menu class="DSideMenu__workspaceMenu" :userRole="userRole" :keyword="keyword" />
        </div>
        <div v-if="showFooter" class="DSideMenu__footer">
          <div class="dh-btn btn-fancy-border DSideMenu__aiButton" @click="goToMaven">
            <font-awesome-icon :icon="['fas', 'robot']" />
            {{ $t("askMaven.askMaven") }}
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import DeephowLogo from "@/components/icons/logo/DeephowLogo.vue";

import { USER_ROLE } from "@/constants/userRole";
import NavAllWorkspaceMenu from "./NavAllWorkspaceMenu.vue";
import NavigationMenu from "./NavigationMenu.vue";
import NavMyWorkspaceMenu from "./NavMyWorkspaceMenu.vue";
import DMavenChatSideMenu from "@/components/DSideMenu/DMavenChatSideMenu.vue";
import { ROUTE_NAME, ROUTE_PATH } from "@/router/route";
import { IS_MOBILE } from "@/constants/device-version";

export default {
  name: "DSideMenu",
  components: {
    DeephowLogo,
    NavigationMenu,
    NavMyWorkspaceMenu,
    NavAllWorkspaceMenu,
    DMavenChatSideMenu,
  },
  props: {
    access: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      keyword: "",
      isMobileDevice: IS_MOBILE,
    };
  },
  computed: {
    ...mapState("permission", ["mavenEnable"]),
    ...mapGetters("global", ["getter_is_side_menu_open"]),
    ...mapGetters("auth", ["getter_user_role"]),

    userRole() {
      return this.getter_user_role || USER_ROLE.VIEWER;
    },
    showFooter() {
      return this.mavenEnable;
    },
    isShowMavenMenu() {
      return this.$route.name === ROUTE_NAME.MAVEN;
    },
  },
  methods: {
    ...mapActions("global", ["closeSideBar", "openSideBar"]),
    toggleSideMenu() {
      if (this.getter_is_side_menu_open) {
        this.closeSideBar();
      } else {
        this.openSideBar();
      }
    },

    /** Maven Menu */
    goToMaven() {
      this.$router.push(ROUTE_PATH.MAVEN);
    },
    closeMavenMenu() {
      this.$router.push(ROUTE_PATH.HOME_OVERVIEW);
    },
  },
};
</script>

<style lang="scss" scoped>
$side-menu-width: 310px;

.DSideMenu {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--dGrey2-color);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.5);
  transition: width 0.2s;
  z-index: var(--z-index-side-menu);
  @include mobile {
    z-index: var(--z-index-side-menu-mobile);
    position: absolute;
  }
  &--open {
    width: $side-menu-width;
    @include mobile {
      width: 100%;
    }
  }
  &--close {
    width: 0;
  }
  &__closeButton {
    position: absolute;
    top: 20px;
    right: -50px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--dGrey3-color);
    background-color: var(--dGrey2-color);
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 16px;
    color: var(--dGrey6-color);
    &:hover {
      color: var(--brand-white);
    }
  }
  &__divider {
    flex: 0 0 auto;
    margin: 8px 24px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 0;
  }

  &__searchInput {
    margin: 10px 24px;
  }
  &__wrapper {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__header {
    flex: 0 0 auto;
  }
  &__content {
    flex: 1 1 auto;
  }
  &__footer {
    flex: 0 0 auto;
  }
}

.DSideMenu__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 32px 24px;
  &-logo {
    &--wrapper {
      display: flex;
      align-items: center;
    }
    &--logo {
      width: 130px;
      height: 28px;
    }
  }
}
.DSideMenu__content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.DSideMenu__footer {
  .DSideMenu__aiButton {
    margin: 12px 24px;
  }
}

.DSideMenu__expand-btn {
  cursor: pointer;
  font-size: 16px;
  width: 16px;
  color: var(--dGrey6-color);
  &:hover {
    color: var(--brand-white);
  }
}
</style>
