<template>
  <section class="DMavenChatSideMenu">
    <div class="DMavenChatSideMenu__back" @click="closeMaven">
      <font-awesome-icon class="DMavenChatSideMenu__back--icon" :icon="['far', 'chevron-left']" />
      {{ $t("maven.back") }}
    </div>
    <hr class="DMavenChatSideMenu__divider" />
    <div class="DMavenChatSideMenu__newChat">
      <div class="DMavenChatSideMenu__blockHeader">
        {{ $t("maven.newChat") }}
      </div>

      <button class="dh-btn btn-primary DMavenChatSideMenu__newChat--button" @click="createNewChat('regular')">
        <font-awesome-icon :icon="['far', 'circle-plus']" />
        {{ $t("maven.newChat") }}
      </button>
    </div>

    <div class="DMavenChatSideMenu__chatHistory chatHistoryList">
      <div class="DMavenChatSideMenu__blockHeader">
        {{ $t("maven.chatHistory") }}
      </div>

      <div class="chatHistoryList__list">
        <d-icon-spin v-if="isLoading" />
        <template v-else>
          <div
            class="chatHistoryList__listItem"
            :class="{ active: selectedChatId === chat.id, disabled: disableChangeSelectedChatId }"
            v-for="chat in chatList"
            :key="chat.id"
            @click="clickHistory(chat)"
          >
            <font-awesome-icon class="chatHistoryList__listItem--icon" :icon="['far', 'message']" />
            <div class="chatHistoryList__listItem--title">{{ chat.title }}</div>
          </div>
        </template>
      </div>

      <div v-if="chatList.length > 0" class="DMavenChatSideMenu__chatHistory-footer">
        <hr class="DMavenChatSideMenu__divider" />
        <div class="DMavenChatSideMenu__clear" :class="{ disabled: disableChangeSelectedChatId }" @click="clearHistory">
          <font-awesome-icon :icon="['far', 'trash-can']" />
          <div>{{ $t("maven.clearConversation") }}</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import eventbus from "@/main";

import { IS_MOBILE } from "@/constants/device-version";
import clickOutsideDirective from "@/directives/click-outside-directive.js";

import DIconSpin from "@/components/icons/animated/DIconSpin.vue";

export default {
  name: "DMavenChatSideMenu",
  directives: {
    "click-outside": clickOutsideDirective,
  },
  components: {
    DIconSpin,
  },

  data() {
    return {
      isMobileDevice: IS_MOBILE,
      isLoading: false,
    };
  },

  computed: {
    ...mapState("maven2", ["chatList", "selectedChatId", "disableChangeSelectedChatId"]),
  },

  created() {
    this.fetchChatList();
  },
  methods: {
    ...mapActions("maven2", ["fetchChatList", "clearConversations"]),
    ...mapMutations("maven2", ["SET_CHAT_TYPE", "SET_CHAT_ID"]),
    closeMaven() {
      this.$emit("close");
    },
    createNewChat(type) {
      if (this.disableChangeSelectedChatId) return;
      this.SET_CHAT_TYPE(type);
      this.SET_CHAT_ID(-1);
    },
    clickHistory(chat) {
      if (this.disableChangeSelectedChatId) return;
      this.SET_CHAT_ID(chat.id);
      this.SET_CHAT_TYPE(chat.type);
    },
    async clearHistory() {
      if (this.disableChangeSelectedChatId) return;
      this.isLoading = true;
      await this.clearConversations();
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$side-padding: 24px;
.DMavenChatSideMenu {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background: var(--dGrey2-color);

  &__back {
    flex: 0 0 auto;
  }
  &__newChat {
    flex: 0 0 auto;
  }
  &__chatHistory {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &-footer {
      flex: 0 0 auto;
    }
  }
  &__divider {
    flex: 0 0 auto;
    margin: 8px $side-padding;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 0;
  }
}

.DMavenChatSideMenu__back {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--brand-white);
  padding: 12px $side-padding 20px $side-padding;
  cursor: pointer;
  column-gap: 8px;
  &--icon {
    color: var(--brand-blue);
    font-size: 16px;
  }
}

.DMavenChatSideMenu__newChat {
  display: flex;
  flex-direction: column;
  &--button {
    margin: 0px $side-padding;
  }
}

.DMavenChatSideMenu__blockHeader {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding: 32px $side-padding 20px $side-padding;

  color: var(--dGrey6-color);
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.48px;
}

.chatHistoryList {
  &__list {
    flex: 1;
    overflow-y: auto;
    @include scrollbar();
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 12px $side-padding;
    color: var(--brand-white);
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }

    &:hover,
    &.active {
      background: var(--dGrey3-color);
    }
    &--icon {
      flex: 0 0 auto;
      font-size: 16px;
      color: var(--dGrey6-color);
    }
    &--title {
      text-align: left;
      font-size: 16px;
      @include truncate-text();
    }
  }
}

.DMavenChatSideMenu__clear {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  padding: 12px $side-padding;
  color: var(--danger-color);
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
}
</style>
