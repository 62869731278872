import deephowApiServerWrapper from "@/server/api-server";

export const getUserTeamList = async ({ userId }) => deephowApiServerWrapper("get", `/v1/users/${userId}/teams`);

export const fetchUserContinueEditingWorkflowList = async ({ userId }) =>
  deephowApiServerWrapper("get", `/v1/users/${userId}/workflows/continue-editing`);

export const fetchUserContinueEditingInstructionList = async ({ userId }) =>
  deephowApiServerWrapper("get", `/v1/users/${userId}/multimodal-sops/continue-editing`);

export const getUserWorkspaceList = async ({ userId, keyword }) =>
  deephowApiServerWrapper("get", `/v1/users/${userId}/workspaces`, { params: { keyword } });

/** Skills by User*/
export const getUserAssignedSkillList = async ({ userId, keyword, page, size, isCompleted }) => {
  let type = isCompleted === undefined ? undefined : isCompleted ? "completed" : "todo";
  const params = { keyword, page, size, type };
  return deephowApiServerWrapper("get", `/v1/users/${userId}/skills-assigned`, { params });
};
export const getUserAssignedSkillDetail = async ({ userId, skillId }) =>
  deephowApiServerWrapper("get", `/v1/users/${userId}/skills-assigned/${skillId}`);
