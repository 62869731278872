import { IS_CHINA } from "@/constants/env";
import services from "@/constants/env/services";

const getServiceMap = () => {
  return {
    coreServices: [services.deephowApiServer],
    assetServices: [services.sign],
    adminServices: [services.admin],
    analyticsServices: [services.analytics],
    ...(!IS_CHINA
      ? {
          mavenServices: [services.chatServer, services.deephowAI],
        }
      : {
          mavenServices: [services.deephowAI],
          databaseServices: [services.database],
        }),
  };
};

export const CONNECTION_FAILED_TYPE = {
  CLOSE: "CLOSE",
  INIT_TO_CHECKED_DOMAIN: "INIT_TO_CHECKED_DOMAIN",
  LOGIN_EMAIL: "LOGIN_EMAIL",
  LOGIN_SSO: "LOGIN_SSO",
};

export const STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  LOADING: "LOADING",
};

export const STATUS_ICON_ATTRIBUTES = {
  [STATUS.SUCCESS]: {
    icon: ["fa", "circle-check"],
    class: "text-success",
  },
  [STATUS.FAILED]: {
    icon: ["fas", "circle-xmark"],
    class: "text-danger",
  },
  [STATUS.LOADING]: {
    icon: ["fas", "spinner"],
    class: "text-loading",
    spin: true,
  },
};

export const SERVICE_MAP = getServiceMap();

export const DEFAULT_SERVICE_STATUS = Object.keys(SERVICE_MAP).reduce(
  (result, key) => ({ ...result, [key]: STATUS.LOADING }),
  {}
);
