<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox">
    <!-- iconName="Close Editor" -->
    <!-- <title :id="iconName" lang="en">{{iconName}}</title> -->
    <g :fill="iconColor">
      <slot></slot>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: {
      type: String,
      default: "0 0 22 22",
    },
    width: {
      type: [Number, String],
      default: 22,
    },
    height: {
      type: [Number, String],
      default: 22,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  /* margin-bottom: -2px;  */
  /* yes, I'm that particular about formatting */
}
</style>
