<template>
  <div class="AttachmentsBarListOuter">
    <div class="AttachmentsBarListBg" :class="{ 'AttachmentsBarListBg--show': showAttachmentsBar }" />
    <div class="AttachmentsBarList" :class="{ 'AttachmentsBarList--show': showAttachmentsBar }">
      <div class="AttachmentsBarList__list" @mouseleave="$emit('hide-list')">
        <div class="AttachmentsBarList__listScroll">
          <div
            v-for="(data, index) in getter_attachments[currentStep]"
            :key="data.type"
            class="AttachmentsBarList__listScroll--item"
          >
            <!-- DIAGRAMS -->
            <div v-if="data.type === ADDON_TYPE.DIAGRAM">
              <div class="AttachmentsBarList__label">
                <font-awesome-icon :icon="['far', 'image']" class="AttachmentsBarList__label--icon" />
                {{ $t("player.diagrams") }}
              </div>
              <div
                v-for="(imgURL, idx) in data.diagrams"
                :key="'diagram' + idx + imgURL"
                class="mb-4"
                @click="onclickOpenAttachmentsPopup(idx)"
              >
                <attachment-diagram-card :imgURL="imgURL" />
              </div>
            </div>
            <!-- LINKS -->
            <div v-if="data.type === ADDON_TYPE.LINKS">
              <div class="AttachmentsBarList__label mt-4">
                <font-awesome-icon :icon="['far', 'list']" class="AttachmentsBarList__label--icon" />
                {{ $t("builder.links") }}
              </div>
              <div v-for="(item, idx) in data.links" :key="data.type + idx">
                <attachment-link-card :title="item.label" :link="item.link" />
              </div>
            </div>
            <!-- PDF -->
            <div v-if="data.type === ADDON_TYPE.PDF">
              <div class="AttachmentsBarList__label mt-4">
                <font-awesome-icon :icon="['fal', 'file-pdf']" class="AttachmentsBarList__label--icon" />
                {{ $t("player.pdfFiles") }}
              </div>
              <div v-for="(item, idx) in data.pdfs" :key="data.type + idx">
                <attachment-pdf-card :item="item" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AttachmentDiagramCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentDiagramCard.vue";
import AttachmentLinkCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentLinkCard.vue";
import AttachmentPdfCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentPdfCard.vue";
import { ADDON_TYPE } from "@/constants/attachmentStatus";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AttachmentsBarList",
  props: {
    currentStep: Number,
    showAttachmentsBar: Boolean,
  },
  components: {
    AttachmentDiagramCard,
    AttachmentLinkCard,
    AttachmentPdfCard,
  },
  created() {},
  data() {
    return {
      ADDON_TYPE,
    };
  },
  methods: {
    ...mapActions("workflowPlayer", ["openAttachmentsPopup", "setSelectedAttachmentStep"]),
    onclickOpenAttachmentsPopup(idx) {
      let attachmentStep = this.getters_steps[this.currentStep];
      attachmentStep["stepIndex"] = this.currentStep;
      this.setSelectedAttachmentStep({ step: attachmentStep });
      this.openAttachmentsPopup({ selectedDiagramIdx: idx, isOpen: true });
    },
  },
  computed: {
    ...mapGetters("workflowPlayer", ["getter_attachments", "getters_steps"]),
  },
};
</script>

<style lang="scss" scoped>
.AttachmentsBarListOuter {
  height: 100%;
  padding-top: 24px;
}
.AttachmentsBarListBg {
  position: absolute;
  left: 0;
  width: 0;
  top: 0;
  height: 100%;
  transition: 0.3s;
  pointer-events: none;
  background: #12131426;
  &--show {
    width: 100%;
    transition: 0.3s;
  }
}
.AttachmentsBarList {
  position: relative;
  left: calc(-100% - 32px);
  width: 100%;
  height: 100%;
  transition: 0.3s;
  pointer-events: none;
  &--show {
    left: 0;
  }
  &__list {
    $bottomControlsHeight: 128px;
    width: 100%;
    height: calc(100% - #{$bottomControlsHeight});
    transition: 0.3s;
    pointer-events: auto;
    overflow: hidden;
  }
  &__listScroll {
    width: 184px;
    padding-right: 4px;
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: #585858;
    }
    &--item {
      position: relative;
      width: 100%;
      min-height: 100px;
      margin-bottom: 10px;
    }
  }
  &__label {
    position: relative;
    text-align: left;
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
    white-space: nowrap;
    &--icon {
      margin-right: 6px;
      font-size: 12px;
    }
  }
}
</style>
