import { ROUTE_NAME, ROUTE_PATH } from "../route.js";

export const SEARCH_ROUTE_NAMES = [ROUTE_NAME.PLAYER_EXPLORER_SEARCH];
const SearchRoute = [
  {
    path: ROUTE_PATH.PLAYER_EXPLORER_SEARCH,
    name: ROUTE_NAME.PLAYER_EXPLORER_SEARCH,
    component: () => import("@/components/pages/Search/PlayerExplorerSearch"),
    meta: { auth: true, keepAlive: true },
  },
];

export default SearchRoute;
