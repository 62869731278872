import deephowApiServerWrapper from "@/server/api-server";

export const getWorkspaceGroups = (params = { needWorkspaces: true }) =>
  deephowApiServerWrapper("get", `/v1/workspaces-groups/`, { params });

export const createWorkspaceGroup = (payload) => deephowApiServerWrapper("post", `/v1/workspaces-groups/`, payload);

export const patchWorkspaceGroup = ({ id, name, locale }) =>
  deephowApiServerWrapper("patch", `/v1/workspaces-groups/${id}`, { name, locale });

export const deleteWorkspaceGroup = (id) => deephowApiServerWrapper("delete", `/v1/workspaces-groups/${id}`);
