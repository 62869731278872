<template>
  <div class="DSearchInput" @click="focusInput">
    <font-awesome-icon :icon="['far', 'magnifying-glass']" class="DSearchInput__prefix" />
    <input
      ref="searchInputRef"
      v-model="inputValue"
      class="DSearchInput__input"
      type="text"
      :placeholder="placeholder"
      :disabled="disabled"
      :autocomplete="false"
      @input="handleInput"
      @keyup.enter="handleEnter"
    />
    <button
      v-if="!disabled && allowClear"
      class="DSearchInput__suffix"
      :class="{ 'DSearchInput__suffix--allow-clear': canClearSearch }"
      @click.stop="clearSearch"
    >
      <font-awesome-icon :icon="['far', 'xmark']" />
    </button>
  </div>
</template>
<script>
import { debounce } from "lodash-es";

export default {
  name: "DSearchInput",
  components: {},
  props: {
    value: {
      // for v-model
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    canClearSearch() {
      return this.inputValue !== "" && !this.disabled;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    focusInput() {
      this.$refs.searchInputRef.focus();
    },
    handleInput(e) {
      const keyword = e.target.value;
      this.doSearch(keyword);
    },
    handleEnter() {
      const keyword = this.inputValue;
      this.doSearch(keyword);
    },
    doSearch: debounce(function (keyword) {
      if (this.disabled) return;
      this.$emit("search", keyword);
    }, 300),

    clearSearch() {
      this.inputValue = "";
      this.$emit("search", "");
    },
  },
};
</script>
<style scoped lang="scss">
.DSearchInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  height: 40px;
  min-width: 240px;
  overflow: hidden;
  padding: 0px 12px;
  border: 1px solid var(--dGrey5-color);
  background: transparent;
  border-radius: 8px;
  &__prefix {
    flex: 0 0 auto;
    height: 100%;
    width: 18px;
    color: var(--brand-white);
  }
  &__input {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    padding: 12px 0px;
    border: 0;
    background: transparent;
    box-shadow: none;
    outline: none;
    color: var(--brand-white);

    &::placeholder {
      font-style: italic;
    }
  }
  &__suffix {
    flex: 0 0 auto;
    height: 100%;
    width: 18px;
    display: none;
    color: var(--brand-white);
  }
}

/** control clear button visibility */
.DSearchInput:hover {
  .DSearchInput__suffix--allow-clear {
    display: block;
  }
}
</style>
