import deephowApiServerWrapper from "@/server/api-server";
import { BASE_URL } from "@/constants/env";

function getLanguageCode({ vueInstanceRef }) {
  return vueInstanceRef.$i18n.locale;
}

/** send notification when some users or teams are assigned to a skill */
export async function notifyAssignSkillToUser({ playlistId, teams, users, lang }) {
  const requestBody = {
    playlistId,
    type: "assign-skill",
    languageCode: lang,
    baseUrl: BASE_URL,
    teams,
    users,
  };
  return await deephowApiServerWrapper("post", "/v1/notifications/skill", requestBody);
}

export async function notifySkillHasChangedPublishedState({ playlistId, isPublished, vueInstanceRef }) {
  const requestBody = {
    playlistId,
    type: isPublished ? "publish-skill" : "unpublish-skill",
    languageCode: getLanguageCode({ vueInstanceRef }),
    baseUrl: BASE_URL,
  };

  try {
    const result = await deephowApiServerWrapper("post", "/v1/notifications/skill/publish", requestBody);
    return result;
  } catch (e) {
    console.error(e);
  }
}

export async function notifyUsersOfNotYetCompletedSkill({ playlistId, users, lang }) {
  const requestBody = {
    playlistId,
    type: "remind-skill",
    languageCode: lang,
    baseUrl: BASE_URL,
    users,
  };

  try {
    const result = await deephowApiServerWrapper("post", "/v1/notifications/skill/reminder", requestBody);
    return result;
  } catch (e) {
    console.error(e);
  }
}

export function notifyWorkflowReviewRequest({ workflowId, vueInstanceRef, users }) {
  const payload = {
    workflowId,
    type: "review-workflow",
    languageCode: getLanguageCode({ vueInstanceRef }),
    baseUrl: BASE_URL,
    users,
  };
  return deephowApiServerWrapper("post", "/v1/notifications/workflow/review", payload);
}

export async function notifySharedWorkflowToUsersOrTeams({ workflowId, message, users, teams, vueInstanceRef }) {
  const requestBody = {
    workflowId,
    type: "share-workflow",
    languageCode: getLanguageCode({ vueInstanceRef }),
    baseUrl: BASE_URL,
    message,
    users,
    teams,
  };

  try {
    const result = await deephowApiServerWrapper("post", "/v1/notifications/workflow/share", requestBody);
    return result;
  } catch (e) {
    console.error(e);
  }
}

// Get Notification List: by default it gets the latest 500 from the past 30 days
export const postNotificationList = () => deephowApiServerWrapper("post", `/v1/notifications`);

export function notifyWorkflowReactionRequest({ workflowId, vueInstanceRef }) {
  const payload = {
    workflowId,
    type: "workflow-reaction",
    languageCode: getLanguageCode({ vueInstanceRef }),
    baseUrl: BASE_URL,
  };
  return deephowApiServerWrapper("post", "/v1/notifications/workflow/reaction", payload);
}
