export const namespaced = true;
import Vue from "vue";
import { postChatQuery, postChatLike, postChatReferences } from "@/server/chat-server.js";
import { fetchWorkspaceList } from "@/server/api-server.js";
import { postFetchVoiceOutputSpeech } from "@/server/azure-speech-server.js";

export const state = {
  chatQuestionHistory: [
    // Object Array
    /** { query: String, groupId: String, audio: Object } */
  ],
  chatAnswerHistory: [
    // Object Array
    /** {
     * query: String,
     * text: String,
     * workflowPoster: String, workflows: ObjectArray, errorMessage: String,
     * references: Object/null,
     * answer: String
     * } */
  ],
  chatPreviousMessages: [
    // Object Array
    /**[{ role: String, content: String }] */
  ],
  mavenUserGroupList: [],
};

export const mutations = {
  CLEAR_CHAT_QUESTION_HISTORY(state) {
    state.chatQuestionHistory = [];
  },
  ADD_CHAT_QUESTION_HISTORY(state, payload) {
    state.chatQuestionHistory.push(payload);
  },
  UPDATE_CHAT_QUESTION_HISTORY_ITEM(state, { index, data }) {
    Vue.set(state.chatQuestionHistory, index, { ...state.chatQuestionHistory[index], ...data });
  },
  CLEAR_CHAT_ANSWER_HISTORY(state) {
    state.chatAnswerHistory = [];
  },
  ADD_CHAT_ANSWER_HISTORY(state, payload) {
    state.chatAnswerHistory.push(payload);
  },
  UPDATE_CHAT_ANSWER_HISTORY_ITEM(state, { index, data }) {
    Vue.set(state.chatAnswerHistory, index, { ...state.chatAnswerHistory[index], ...data });
  },
  CLEAR_CHAT_PREVIOUS_MESSAGES(state) {
    state.chatPreviousMessages = [];
  },
  ADD_CHAT_PREVIOUS_MESSAGES(state, { query, result_text }) {
    state.chatPreviousMessages.push({ role: "user", content: query });
    state.chatPreviousMessages.push({ role: "assistant", content: result_text });
  },
  SET_MAVEN_USER_GROUP_LIST(state, payload = []) {
    state.mavenUserGroupList = payload;
  },
};

export const getters = {
  getter_chat_previous_messages: (state) => state.chatPreviousMessages,
  getter_chat_question_history: (state) => state.chatQuestionHistory,
  getter_chat_answer_history: (state) => state.chatAnswerHistory,
  getter_chat_history: (state) => {
    return state.chatQuestionHistory.map((question, index) => {
      const answerItem = state.chatAnswerHistory[index];
      const answerObj = answerItem
        ? {
            answer: answerItem,
          }
        : {};
      return {
        question,
        ...answerObj,
      };
    });
  },
  getters_maven_user_group_list: (state) => state.mavenUserGroupList,
  getters_maven_group_dictionary: (state) => {
    return state.mavenUserGroupList.reduce((acm, group) => {
      if (!group.id) return acm;

      acm[group.id] = group.name;
      return acm;
    }, {});
  },
};

export const actions = {
  // chat methods
  addChatQuestionHistory: ({ commit }, payload = { query, groupId }) => {
    if (!payload) return;

    commit("ADD_CHAT_QUESTION_HISTORY", payload);
  },
  updateChatQuestionHistoryItem: ({ commit }, payload = { index, data: { query } }) => {
    commit("UPDATE_CHAT_QUESTION_HISTORY_ITEM", payload);
  },
  updateChatAnswerHistoryItem: ({ commit }, payload = { index, data: { query } }) => {
    commit("UPDATE_CHAT_ANSWER_HISTORY_ITEM", payload);
  },
  fetchChatQuery: async ({ commit }, payload) => {
    const { ok, data, errorMessage } = await postChatQuery(payload);
    if (!ok) {
      console.error(errorMessage);
      const errorMessageI18n = "alert.unexpectedError";
      commit("ADD_CHAT_ANSWER_HISTORY", { errorMessage: errorMessageI18n });
      return;
    }

    const {
      item: { answer, referenceInfo = {} },
    } = data;
    commit("ADD_CHAT_ANSWER_HISTORY", {
      ...answer,
      ...referenceInfo,
    });
    commit("ADD_CHAT_PREVIOUS_MESSAGES", { query: payload.query, result_text: answer.text });
  },
  fetchChatQueryReferences: async ({ getters, commit }, payload) => {
    const historyIndex = getters.getter_chat_history.length - 1;
    const { ok, data, errorMessage } = await postChatReferences(payload);
    if (!ok) {
      console.error(errorMessage);
    } else {
      const answerPayload = {
        index: historyIndex,
        data: {
          workflows: data.item?.results || [],
          isReferenceReady: true,
        },
      };
      commit("UPDATE_CHAT_ANSWER_HISTORY_ITEM", answerPayload);
    }
  },
  clearChatHistory: ({ commit }) => {
    commit("CLEAR_CHAT_QUESTION_HISTORY");
    commit("CLEAR_CHAT_ANSWER_HISTORY");
    commit("CLEAR_CHAT_PREVIOUS_MESSAGES");
  },
  clearChat: ({ commit }) => {
    commit("CLEAR_CHAT_QUESTION_HISTORY");
    commit("CLEAR_CHAT_ANSWER_HISTORY");
    commit("CLEAR_CHAT_PREVIOUS_MESSAGES");
    commit("SET_MAVEN_USER_GROUP_LIST", []);
  },
  // group methods
  fetchMavenUserGroupList: async ({ commit }, { allWorkspaceLabel }) => {
    try {
      const { ok, data } = await fetchWorkspaceList({ type: "view" });

      if (ok) {
        commit("SET_MAVEN_USER_GROUP_LIST", [{ name: allWorkspaceLabel, id: null }, ...data.items]);
      } else {
        commit("SET_MAVEN_USER_GROUP_LIST", []);
      }
    } catch (e) {
      console.error(e);
      commit("SET_MAVEN_USER_GROUP_LIST", []);
    }
  },
  // update like/dislike methods
  updateAnswerUserReaction: async ({}, payload) => {
    return await postChatLike(payload);
  },
  // get voice output audio
  fetchVoiceOutputAudio: async ({}, payload) => {
    return await postFetchVoiceOutputSpeech(payload);
  },
};
