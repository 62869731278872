<template>
  <div>
    <div class="DFooterExpandPanel" :class="{ 'DFooterExpandPanel--show': show }">
      <slot v-if="show" />
    </div>
    <div v-if="show" class="DFooterExpandPanel__keepSpace" />
  </div>
</template>

<script>
export default {
  name: "DFooterExpandPanel",
  props: {
    show: Boolean,
  },
  mounted() {},
  data() {
    return {};
  },
  components: {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.DFooterExpandPanel {
  z-index: 2;
  position: fixed;
  height: 0;
  transition: height 0.3s;
  width: 100%;
  background-color: var(--dGrey5-color);
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: white;
  font-size: 18px;
  font-weight: 500;

  &--show {
    height: 70px;
  }

  &__keepSpace {
    width: 100%;
    height: 70px;
  }
}
</style>
