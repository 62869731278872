<template>
  <div ref="desktopControls" class="ControlsDesktop">
    <!-- Play Or Pause video when click the main area of the player-->
    <div
      class="ControlsDesktop__main"
      :class="{ 'ControlsDesktop__main--small': isSmallScreen, 'ControlsDesktop__main--hideControls': hideControls }"
      @click="onclickPlayOrPause"
    >
      <safety-info-watermark v-if="showSafetyInfoWaterMark" :watermarkId="watermarkId" />
    </div>
    <!-- top -->
    <div
      v-if="showTopBar"
      class="ControlsDesktop__topBar"
      :class="{ 'ControlsDesktop__topBar--hidden': hideControls, 'ControlsDesktop__topBar--small': isSmallScreen }"
      @click.stop
    >
      <div class="ControlsDesktop__title">
        <div class="ControlsDesktop__title--text">
          <div
            class="ControlsDesktop__title--workflow text-no-wrap text-truncate"
            :class="{ 'ControlsDesktop__title--workflowSm': isSmallScreen }"
          >
            {{ workflowTitle }}
          </div>
          <div
            class="ControlsDesktop__title--step text-no-wrap text-truncate"
            :class="{ 'ControlsDesktop__title--stepSm': isSmallScreen }"
          >
            {{ stepNum }}. {{ stepTitle }}
          </div>
        </div>
      </div>
      <div v-if="!isSingleStepPlayer">
        <v-tooltip :disabled="isDisabledTooltip" bottom color="#2C2D32" z-index="101">
          <button slot="activator" class="ControlsDesktop__menuBtn" @click="$emit('click-menu-btn')">
            <font-awesome-icon :icon="['fas', 'list-ul']" />
          </button>
          <span>{{ $t("player.menuTooltip") }}</span>
        </v-tooltip>
      </div>
    </div>

    <!-- center buttons (only for small screen) -->
    <div class="ControlsDesktop__center" :class="{ 'ControlsDesktop__center--hidden': hideControls }">
      <div class="ControlsDesktop__centerBtns" @click.stop>
        <button class="ControlsDesktop__btns--btnSm" @click="$emit('play-back')">
          <font-awesome-icon
            :icon="['fas', 'arrow-rotate-right']"
            class="ControlsDesktop__btns--btnRotate text-white"
          />
        </button>
        <button class="ControlsDesktop__btns--btnSm" @click="$emit('play-previous-step')">
          <font-awesome-icon :icon="['fas', 'forward-step']" class="ControlsDesktop__btns--btnRotate text-white" />
        </button>
        <button class="ControlsDesktop__btns--playBtnSm" @click="onclickPlayOrPause">
          <font-awesome-icon :icon="playing ? ['fas', 'circle-pause'] : ['fas', 'circle-play']" class="text-white" />
        </button>
        <button class="ControlsDesktop__btns--btnSm" @click="$emit('play-next-step')">
          <font-awesome-icon :icon="['fas', 'forward-step']" class="text-white" />
        </button>
        <button class="ControlsDesktop__btns--btnSm" @click="$emit('play-forward')">
          <font-awesome-icon :icon="['fas', 'arrow-rotate-right']" class="text-white" />
        </button>
      </div>
    </div>

    <!-- bottom -->
    <div class="ControlsDesktop__bottomBar" @click.stop>
      <!-- time progress bar -->
      <div class="ControlsDesktop__bottomTime">
        <span
          class="ControlsDesktop__bottomTime--time"
          :class="{
            'ControlsDesktop__bottomTime--timeSm': isSmallScreen,
            'ControlsDesktop__bottomTime--hideTime': hideControls,
          }"
          v-html="videoTime"
        ></span>
        <div
          class="ControlsDesktop__bottomTime--timeWrapper"
          :class="{ 'ControlsDesktop__bottomTime--timeWrapperSm': isSmallScreen }"
        >
          <slot></slot>
        </div>
      </div>

      <!-- Bottom buttons -->
      <div
        class="ControlsDesktop__bottomControls"
        :class="{
          'ControlsDesktop__bottomControls--small': isSmallScreen,
          'ControlsDesktop__bottomControls--hidden': hideControls,
        }"
        @click.stop
      >
        <div class="ControlsDesktop__btns">
          <button
            class="ControlsDesktop__btns--btnLg"
            :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
            @click="$emit('play-back')"
          >
            <font-awesome-icon
              :icon="['fas', 'arrow-rotate-right']"
              class="ControlsDesktop__btns--btnRotate text-white"
            />
          </button>
          <desktop-step-name-tooltip
            v-if="!isSingleStepPlayer"
            isPrevious
            :stepNumber="previousStepNum"
            :stepTitle="previousStepTitle"
            :hidden="currentStep === 0"
          >
            <button
              class="ControlsDesktop__btns--btnLg ControlsDesktop__btns--btnNextLg"
              :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
              @click="$emit('play-previous-step')"
            >
              <font-awesome-icon :icon="['fas', 'forward-step']" class="ControlsDesktop__btns--btnRotate text-white" />
            </button>
          </desktop-step-name-tooltip>
          <button
            class="ControlsDesktop__btns--playBtnLg"
            :class="{ 'ControlsDesktop__btns--playBtnSm': isSmallScreen }"
            @click="onclickPlayOrPause"
          >
            <font-awesome-icon :icon="playing ? ['fas', 'circle-pause'] : ['fas', 'circle-play']" class="text-white" />
          </button>
          <desktop-step-name-tooltip
            v-if="!isSingleStepPlayer"
            :stepNumber="nextStepNum"
            :stepTitle="nextStepTitle"
            :hidden="currentStep === getters_steps.length - 1"
          >
            <button
              class="ControlsDesktop__btns--btnLg ControlsDesktop__btns--btnNextLg"
              :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
              @click="$emit('play-next-step')"
            >
              <font-awesome-icon :icon="['fas', 'forward-step']" class="text-white" />
            </button>
          </desktop-step-name-tooltip>
          <button
            class="ControlsDesktop__btns--btnLg"
            :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
            @click="$emit('play-forward')"
          >
            <font-awesome-icon :icon="['fas', 'arrow-rotate-right']" class="text-white" />
          </button>
        </div>
        <div class="ControlsDesktop__btnsLeft">
          <button
            v-if="!getter_is_gen2 && !isSingleStepPlayer"
            class="ControlsDesktop__btns--btnLg"
            :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
            @click="$emit('click-search')"
          >
            <font-awesome-icon :icon="['far', 'magnifying-glass']" />
          </button>
          <reactions-in-player v-if="showReactionBtn" :workflowId="workflowData.id" />
        </div>
        <div class="ControlsDesktop__btnsRight">
          <controls-repeat-button
            class="ControlsDesktop__btns--btnLg"
            :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
            :size="isSmallScreen ? 'small' : 'large'"
            :disableTooltip="isDisabledTooltip"
            :isSingleStepPlayer="isSingleStepPlayer"
          />
          <volume-slider :volume="volume" :isMuted="isMuted" @update-volume="$emit('update-volume', $event)">
            <button
              class="ControlsDesktop__btns--btnLg"
              :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
              @click="$emit('click-mute')"
            >
              <font-awesome-icon :icon="isMuted ? ['far', 'volume-slash'] : ['far', 'volume']" />
            </button>
          </volume-slider>
          <button
            class="ControlsDesktop__btns--btnLg"
            :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
            @click="$emit('click-setting')"
            @mouseenter="mouseenterSettingsButton"
          >
            <font-awesome-icon :icon="['far', 'gear']" />
          </button>
          <!-- theater mode button -->
          <v-tooltip v-if="enableTheaterButton" :disabled="isDisabledTooltip" top color="#2C2D32" z-index="101">
            <button
              slot="activator"
              class="ControlsDesktop__btns--btnLg"
              :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
              @click="changeTheaterMode"
            >
              <icon-base v-if="isTheaterMode"><d-icon-theater-mode-exit /></icon-base>
              <font-awesome-icon v-else :icon="['far', 'rectangle']" />
            </button>
            <span> {{ isTheaterMode ? $t("player.exitTheaterMode") : $t("player.theaterMode") }} (T)</span>
          </v-tooltip>
          <!-- fullscreen mode button -->
          <v-tooltip v-if="isSupportFullscreen" :disabled="isDisabledTooltip" top color="#2C2D32" z-index="101">
            <button
              slot="activator"
              class="ControlsDesktop__btns--btnLg"
              :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
              @click="$emit('change-fullscreen')"
            >
              <font-awesome-icon :icon="isFullscreen ? ['far', 'compress-wide'] : ['far', 'expand-wide']" />
            </button>
            <span> {{ isFullscreen ? $t("player.exitFullscreen") : $t("player.fullscreen") }} (F)</span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReactionsInPlayer from "@/components/DWorkflowPlayer/controls/ReactionsInPlayer.vue";
import DIconTheaterModeExit from "@/components/icons/player/DIconTheaterModeExit.vue";
import DesktopStepNameTooltip from "@/components/DWorkflowPlayer/controls/DesktopStepNameTooltip.vue";
import VolumeSlider from "@/components/DWorkflowPlayer/controls/VolumeSlider.vue";
import ControlsRepeatButton from "@/components/DWorkflowPlayer/controls/ControlsRepeatButton.vue";
import { IS_TABLET, IS_MOBILE } from "@/constants/device-version";
import { IS_SUPPORT_FULLSCREEN } from "@/js/workflow/fullscreen.js";
import { getDisplayTitle } from "@/js/video-player/title-display.js";
import { getFormattedVideoDuration, getNumberWithZero } from "@/js/common/formatText.js";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "ControlsDesktop",
  components: {
    DIconTheaterModeExit,
    DesktopStepNameTooltip,
    VolumeSlider,
    ControlsRepeatButton,
    ReactionsInPlayer,
    SafetyInfoWatermark: () => import("./SafetyInfoWatermark.vue"),
  },
  props: {
    isPublic: Boolean,
    isSmallScreen: Boolean,
    hideControls: Boolean,
    playing: Boolean,
    isMuted: Boolean,
    currentStep: Number,
    currentVideoTime: Number,
    subtitlesChoice: String,
    isUsingIframe: Boolean,
    isEmbedDeephow: Boolean,
    enableTheaterButton: Boolean,
    volume: Number,
    showTopBarForcible: {
      type: Boolean,
      default: false,
    },
    watermarkId: {
      type: String,
    },
    isSingleStepPlayer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSupportFullscreen: IS_SUPPORT_FULLSCREEN,
      isDisabledTooltip: IS_TABLET || IS_MOBILE,
    };
  },
  methods: {
    ...mapActions("workflowPlayer", ["changeTheaterMode"]),
    onclickPlayOrPause() {
      if (this.playing) {
        this.$emit("pause-video");
        return;
      }
      this.$emit("play-video");
    },
    getStepDisplayTitle(stepIdx) {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      const step = this.getters_steps[stepIdx] || {};
      return getDisplayTitle(step, displayLang);
    },
    mouseenterSettingsButton() {
      if (IS_TABLET) return;
      this.$emit("click-setting");
    },
  },
  computed: {
    ...mapGetters("auth", ["getter_is_gen2"]),
    ...mapGetters("workflowPlayer", ["getters_steps", "getter_is_window_mode"]),
    ...mapGetters("permission", ["getter_safety_watermark_enabled"]),
    ...mapState("workflowPlayer", ["workflowData", "isTheaterMode", "isFullscreen"]),
    showSafetyInfoWaterMark() {
      return this.workflowData.enableSafetyWatermark;
    },
    videoTime() {
      const current = getFormattedVideoDuration({ videoDuration: this.currentVideoTime });
      const total = getFormattedVideoDuration({ videoDuration: this.getters_steps[this.currentStep].duration });
      return `${current} / ${total}`;
    },
    workflowTitle() {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      return getDisplayTitle(this.workflowData, displayLang);
    },
    stepNum() {
      return getNumberWithZero({ num: this.currentStep + 1 });
    },
    stepTitle() {
      return this.getStepDisplayTitle(this.currentStep);
    },
    previousStepTitle() {
      return this.getStepDisplayTitle(this.currentStep - 1);
    },
    previousStepNum() {
      return getNumberWithZero({ num: this.currentStep });
    },
    nextStepTitle() {
      return this.getStepDisplayTitle(this.currentStep + 1);
    },
    nextStepNum() {
      return getNumberWithZero({ num: this.currentStep + 2 });
    },
    showTopBar() {
      return this.showTopBarForcible || !this.getter_is_window_mode || this.isUsingIframe || this.isEmbedDeephow;
    },
    showReactionBtn() {
      if (this.isPublic || !this.workflowData.published) return false;
      return this.isTheaterMode || this.isFullscreen;
    },
  },
};
</script>

<style lang="scss" scoped>
$bottomHeight: 94px;
$bottomHeightSm: 60px;
.ControlsDesktop {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  &__main {
    position: absolute;
    width: 100%;
    height: calc(100% - #{$bottomHeight});
    left: 0;
    top: 0;
    pointer-events: auto;
    &--small {
      height: calc(100% - #{$bottomHeightSm});
    }
    &--hideControls {
      height: calc(100% - 20px);
    }
  }
  &__topBar {
    z-index: 1;
    padding: 16px 32px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: auto;
    background: linear-gradient(180deg, rgba(29, 30, 33, 0.8) 0%, rgba(29, 30, 33, 0) 100%);
    opacity: 1;
    transition: 0.3s;
    &--hidden {
      pointer-events: none;
      opacity: 0;
      transition: 0.3s;
    }
    &--small {
      padding: 8px 16px;
    }
  }
  &__title {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    &--text {
      position: relative;
      min-width: calc(100% - 60px);
      text-align: left;
    }
    &--workflow {
      color: var(--dGrey6-color);
      font-size: 16px;
      line-height: 16px;
    }
    &--workflowSm {
      font-size: 14px;
      line-height: 14px;
    }
    &--step {
      color: #ffffff;
      font-size: 24px;
      margin-top: 4px;
    }
    &--stepSm {
      font-size: 16px;
    }
  }
  &__menuBtn {
    height: 32px;
    width: 32px;
    color: #ffffff;
    font-size: 16px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.25);
  }

  &__center {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.3s;
    display: flex;
    display: none;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(29, 30, 33, 0.8) 0%, rgba(29, 30, 33, 0) 60%);
    @media (max-width: 599px) {
      display: flex;
      opacity: 1;
      transition: 0.3s;
    }
    &--hidden {
      opacity: 0;
      transition: 0.3s;
    }
  }
  &__centerBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
  }
  &__bottomBar {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: auto;
  }
  &__bottomTime {
    position: relative;
    width: 100%;
    height: 4px;
    padding: 0 24px;
    &--time {
      position: absolute;
      left: 24px;
      top: -24px;
      color: #ffffff;
      font-size: 12px;
      opacity: 1;
      transition: 0.3s;
    }
    &--timeSm {
      left: 16px;
      top: -18px;
    }
    &--hideTime {
      pointer-events: none;
      opacity: 0;
      transition: 0.3s;
    }
    &--timeWrapper {
      position: absolute;
      width: calc(100% - 48px);
      left: 24px;
    }
    &--timeWrapperSm {
      width: 100%;
      left: 0px;
    }
  }
  &__bottomControls {
    position: relative;
    height: $bottomHeight;
    width: 100%;
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 1;
    background: linear-gradient(180deg, rgba(29, 30, 33, 0) 0%, rgba(29, 30, 33, 0.8) 100%);
    &--small {
      height: $bottomHeightSm;
      transition: 0.3s;
    }
    &--hidden {
      padding-top: 0px;
      height: 8px;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s;
    }
  }
  &__btns {
    $btnMarginLg: 16px;
    $btnMarginSm: 6px;
    position: relative;
    display: flex;
    align-items: center;
    justify-items: center;
    @media (max-width: 599px) {
      display: none;
    }
    &--btnRotate {
      transform: rotateY(180deg);
    }
    &--btnLg {
      color: #ffffff;
      margin: 0 $btnMarginLg;
      svg {
        height: 30px;
        width: 30px;
      }
    }
    &--btnNextLg {
      padding: 3px;
      svg {
        height: 24px;
        width: 24px;
      }
    }
    &--btnSm {
      color: #ffffff;
      margin: 0 $btnMarginSm;
      svg {
        height: 18px;
        width: 18px;
      }
    }
    &--playBtnLg {
      margin: 0 $btnMarginLg;
      svg {
        height: 52px;
        width: 52px;
      }
    }
    &--playBtnSm {
      margin: 0 $btnMarginSm;
      svg {
        height: 26px;
        width: 26px;
      }
    }
  }
  &__btnsLeft {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding-left: 8px;
  }
  &__btnsRight {
    position: absolute;
    right: 0;
    padding-right: 8px;
  }
}
</style>
