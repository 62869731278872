<template>
  <div class="d-tooltip" @mouseenter="showTooltip" @mouseleave="hideTooltip">
    <d-teleport to="body">
      <div
        v-if="isVisible"
        ref="tooltipRef"
        class="tooltip-div"
        :class="{ 'h-100': h100, 'w-fit': wFit }"
        :style="tooltipStyle"
      >
        <p class="tooltip-text ma-0" :class="{ 'text-no-wrap': !textWrap }">
          <slot name="content">
            <!-- fallback content -->
            {{ text }}
          </slot>
        </p>
      </div>
    </d-teleport>
    <slot />
  </div>
</template>

<script>
import DTeleport from "@/components/ui_components/DTeleport.vue";
export default {
  name: "DTooltip",
  components: {
    DTeleport,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    h100: {
      type: Boolean,
      default: true,
    },
    wFit: {
      type: Boolean,
      default: false,
    },
    textWrap: {
      type: Boolean,
      default: false,
    },
    placementDown: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      tooltipStyle: {
        top: "0px",
        left: "0px",
        transform: "",
        width: "",
      },
      tooltipTranslatePosition: {
        x: "0%",
        y: "0%",
      },
    };
  },
  methods: {
    hideTooltip() {
      if (this.disabled) return;
      this.isVisible = false;
    },

    showTooltip(event) {
      if (this.disabled) return;
      const target = event.currentTarget;
      const rect = target.getBoundingClientRect();

      // Calculate tooltip position based on the defined position prop
      const PADDING = 8;

      if (!this.placementDown) {
        // top
        this.tooltipStyle = {
          ...this.tooltipStyle,
          top: `${rect.top + window.scrollY - PADDING}px`,
          left: `${rect.left + target.offsetWidth / 2}px`,
        };
        this.tooltipTranslatePosition = { x: "-50%", y: "-100%" };
      } else {
        // bottom
        this.tooltipStyle = {
          ...this.tooltipStyle,
          top: `${rect.bottom + window.scrollY + PADDING}px`,
          left: `${rect.left + target.offsetWidth / 2}px`,
        };
        this.tooltipTranslatePosition = { x: "-50%", y: "0%" };
      }

      const { x, y } = this.tooltipTranslatePosition;
      this.tooltipStyle = {
        ...this.tooltipStyle,
        transform: `translate(${x}, ${y})`,
        width: this.width || (this.textWrap ? "160px" : "fit-content"),
      };

      this.isVisible = true;
      setTimeout(() => this.repositionTooltip(), 0);
    },

    repositionTooltip() {
      const tooltipRect = this.$refs.tooltipRef?.getBoundingClientRect();
      if (!tooltipRect) return;

      const TOLERANCE = 20;

      if (tooltipRect.right + TOLERANCE >= window.innerWidth) {
        // overflow right
        this.tooltipStyle = { ...this.tooltipStyle, left: `${window.innerWidth - TOLERANCE - tooltipRect.width}px` };
        this.tooltipTranslatePosition = { ...this.tooltipTranslatePosition, x: "0%" };
      } else if (tooltipRect.left - TOLERANCE <= 0) {
        // overflow left
        this.tooltipStyle = { ...this.tooltipStyle, left: `${TOLERANCE}px` };
        this.tooltipTranslatePosition = { ...this.tooltipTranslatePosition, x: "0%" };
      }
      const { x, y } = this.tooltipTranslatePosition;
      this.tooltipStyle = { ...this.tooltipStyle, transform: `translate(${x}, ${y})` };
    },
  },
};
</script>

<style scoped lang="scss">
.d-tooltip {
  position: relative;
  &.h-100 {
    height: 100%;
  }
  &.w-fit {
    width: fit-content;
  }
}
.tooltip-div {
  z-index: var(--z-index-tooltip);
  position: absolute;
  background-color: #2c2d32;
  box-shadow: 0 0 4px 0 rgba(62, 58, 58, 0.35);
  pointer-events: none;
  padding: 6px 8px;
}

.tooltip-text {
  color: var(--brand-white);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}
</style>
