import { QUIZ_TYPES } from "./constants";

/**
 * Formats quiz data to match the backend API requirements
 * @param {Object} quizData - The raw quiz data
 * @returns {Object} - Formatted quiz data matching backend requirements
 */
export const formatQuizForBackend = (quizData) => {
  // Add defensive check for QUIZ_TYPES
  if (!QUIZ_TYPES) {
    console.error("❌ [quizUtils] QUIZ_TYPES constants not loaded");
    return null;
  }

  if (!quizData || !quizData.questions) {
    console.warn("❌ [quizUtils] Invalid quiz data provided:", quizData);
    return null;
  }

  try {
    // Create a plain object copy to avoid Vue reactivity issues
    const plainQuizData = JSON.parse(JSON.stringify(quizData));

    // Format questions according to API requirements
    const formattedQuestions = (plainQuizData.questions || [])
      .map((question) => {
        // Add defensive check for question type
        if (!question || !question.type) {
          console.warn("❌ [quizUtils] Invalid question format:", question);
          return null;
        }

        // Handle true/false type questions
        if (question.type === "trueFalse" || question.type === QUIZ_TYPES.TRUE_FALSE) {
          return {
            type: QUIZ_TYPES.TRUE_FALSE,
            title: question.question || question.title || "",
            correctAnswer:
              typeof question.correctAnswer === "number"
                ? String.fromCharCode(65 + question.correctAnswer)
                : question.correctAnswer,
            options: {
              A: "true",
              B: "false",
            },
          };
        }

        // Handle single selection questions
        // Get answers from either answers array or existing options
        let options = {};

        // First try to get options from the options object
        if (question.options && typeof question.options === "object") {
          options = { ...question.options };
        }
        // If no options but we have answers array, create options from answers
        else if (Array.isArray(question.answers)) {
          question.answers.forEach((answer, index) => {
            const key = String.fromCharCode(65 + index); // Convert 0 to 'A', 1 to 'B', etc.
            options[key] = answer || "";
          });
        }

        // Ensure we have at least one option
        if (Object.keys(options).length === 0 && question.answers && question.answers.length > 0) {
          const firstAnswer = question.answers[0];
          options = { A: firstAnswer };
        }

        // Get the correct answer letter
        const correctAnswerLetter =
          typeof question.correctAnswer === "number"
            ? String.fromCharCode(65 + question.correctAnswer) // Convert index to letter
            : question.correctAnswer || "A"; // Use existing letter or fallback

        const formattedQuestion = {
          type: "singleSelection",
          title: question.question || question.title || "",
          correctAnswer: correctAnswerLetter,
          options: options,
        };

        // Log the formatted question for debugging

        return formattedQuestion;
      })
      .filter(Boolean); // Filter out any null values

    const formattedQuiz = {
      type: "quiz",
      title: plainQuizData.title || "Quiz",
      questions: formattedQuestions,
    };

    // Log the final formatted quiz for debugging

    return formattedQuiz;
  } catch (error) {
    console.error("❌ [quizUtils] Error formatting quiz data:", error);
    return null;
  }
};

/**
 * Validates quiz data format
 * @param {Object} quizData - The quiz data to validate
 * @returns {boolean} - Whether the quiz data is valid
 */
export const isValidQuizData = (quizData) => {
  if (!quizData || !quizData.questions || !Array.isArray(quizData.questions)) {
    return false;
  }

  return quizData.questions.every((question) => {
    // Check required fields
    if (!question.type || !question.title || !question.correctAnswer) {
      return false;
    }

    // Check options
    if (!question.options || typeof question.options !== "object") {
      return false;
    }

    // For true/false questions
    if (question.type === QUIZ_TYPES.TRUE_FALSE) {
      return question.options.A === "true" && question.options.B === "false";
    }

    // For single selection questions
    if (question.type === QUIZ_TYPES.SINGLE_SELECTION) {
      return (
        Object.keys(question.options).length > 0 &&
        question.correctAnswer >= "A" &&
        question.correctAnswer <= String.fromCharCode(65 + Object.keys(question.options).length - 1)
      );
    }

    return false;
  });
};

/**
 * Process quiz data from API response
 * @param {Object} quizData - Quiz data from API
 * @returns {Object} Processed quiz data
 */
export function processQuizFromResponse(quizData) {
  if (!quizData) return null;

  return {
    id: quizData.id,
    quizId: quizData.quizId,
    questions: (quizData.questions || []).map((q) => ({
      id: q.id || `q_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
      type: q.type || "single",
      question: q.title || "",
      answers: Object.values(q.options || {}),
      correctAnswer: q.correctAnswer ? q.correctAnswer.charCodeAt(0) - 65 : 0,
      explanation: q.explanation || "",
    })),
    settings: {
      shuffleQuestions: false,
      shuffleOptions: false,
      passingScore: 80,
      showExplanation: true,
    },
    metadata: {
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      version: "1.0",
    },
    latestUserQuiz: quizData.latestUserQuiz || null,
  };
}
