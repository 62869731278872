import { getUserProfileApi } from "@/server/api-server.js";
import { checkIpAddress } from "@/server/admin-server.js";
import { CHECKING_RESTRICTED_IP_STAUTS } from "@/constants/auth";
import { getIsIpPassedLocalStorage, setIsIpPassedLocalStorage } from "@/js/login/checkRestrictedIp";
import { registerUserLastInteraction } from "@/server/login-activity-server";
import userGuide from "@/js/userGuide";
import { USER_ROLE } from "@/constants/userRole";

export const namespaced = true;

export const state = {
  checkIpStatus: CHECKING_RESTRICTED_IP_STAUTS.TODO,
  clientIP: "",
  clientKey: "",
  isLogin: false,
  userProfile: null,

  access: null,
  isAccessReady: false, // can be used to avoid race condition

  defaultWorkspaceGroupId: "",
};

export const actions = {
  // [key: string, canAccess: bool]
  setAccess({ commit }, payload) {
    commit("SET_USER_ACCESS", payload);
    commit("SET_USER_ACCESS_READY", true);
  },
  // Wait for user access to be ready with timeout option
  waitForUserAccess({ state }, { timeout = 30000 } = {}) {
    const startTime = Date.now();
    return new Promise((resolve, reject) => {
      const checkAccess = () => {
        if (state.isAccessReady) {
          resolve();
        } else if (Date.now() - startTime > timeout) {
          reject(new Error("Timeout waiting for user access"));
        } else {
          setTimeout(checkAccess, 500);
        }
      };
      checkAccess();
    });
  },

  setClientKey({ commit }, clientKey) {
    commit("SET_CLIENT_KEY", clientKey);
  },
  setIsCheckIpNotAllowed({ commit }) {
    setIsIpPassedLocalStorage(false);
    commit("SET_IP_STATUS", CHECKING_RESTRICTED_IP_STAUTS.NOT_ALLOWED);
  },
  setDefaultWorkspaceGroupId({ commit }, defaultWorkspaceGroupId) {
    commit("SET_DEFAULT_WORKSPACE_GROUP_ID", defaultWorkspaceGroupId);
  },
  async checkIp({ state, commit }) {
    try {
      if (!state.isLogin) {
        return;
      }
      const isIpPassed = getIsIpPassedLocalStorage();
      if (isIpPassed) {
        commit("SET_IP_STATUS", CHECKING_RESTRICTED_IP_STAUTS.PASSED);
        return;
      }
      commit("SET_IP_STATUS", CHECKING_RESTRICTED_IP_STAUTS.CHECKING);
      const checkIpResult = await checkIpAddress();
      console.log(checkIpResult.data.item);
      const hasAllowedKey = checkIpResult?.data?.item?.allowed !== undefined;
      const checkIpStatus = hasAllowedKey
        ? checkIpResult.data.item?.allowed
          ? CHECKING_RESTRICTED_IP_STAUTS.PASSED
          : CHECKING_RESTRICTED_IP_STAUTS.NOT_ALLOWED
        : CHECKING_RESTRICTED_IP_STAUTS.CONNECT_FAILED;
      const checkIpOK = checkIpStatus === CHECKING_RESTRICTED_IP_STAUTS.PASSED;
      setIsIpPassedLocalStorage(checkIpOK);
      commit("SET_IP_STATUS", checkIpStatus);
      commit("SET_CLIENT_IP", checkIpResult.data?.item?.clientIP ?? "");
    } catch (e) {
      console.log(e);
      commit("SET_IP_STATUS", CHECKING_RESTRICTED_IP_STAUTS.TODO);
      commit("SET_CLIENT_IP", "");
    }
  },
  updateUserProfileKey({ commit }, { key, value }) {
    commit("SET_USER_PROFILE_KEY", { key, value });
  },
  updateUserProfile({ state, commit }, payload) {
    const userProfile = { ...state.userProfile, ...payload };
    commit("SET_USER_PROFILE", userProfile);
  },
  async getUserProfile({ commit }) {
    const { ok, data } = await getUserProfileApi();
    if (ok) {
      const userProfile = { ...data.item, changeCount: 0 };
      commit("SET_USER_PROFILE", userProfile);
      registerUserLastInteraction();
      userGuide.updateUserInfo(userProfile.id, {
        organizationId: userProfile.organizationInfo.id,
        highestRole: userProfile.role,
        uiLanguage: userProfile.locale,
        dateCreated: userProfile.createdAt,
        disabled: false,
      });

      const UG_ADMIN_ROLES = [
        USER_ROLE.ADMIN_SUPER,
        USER_ROLE.ADMIN_ORG,
        USER_ROLE.ADMIN_WORKSPACE,
        USER_ROLE.PUBLISHER,
      ];
      if (UG_ADMIN_ROLES.includes(userProfile.role)) {
        document.body.id = "ug-admin";
      }
    } else {
      commit("LOGOUT");
    }
  },
  async logout({ commit }) {
    commit("LOGOUT");
  },
};
export const getters = {
  user_access(state) {
    return state.access;
  },
  is_user_access_ready(state) {
    return state.isAccessReady;
  },

  getter_user_role(state) {
    return state.userProfile?.role ?? "";
  },
  getter_user_ip_passed(state) {
    return state.checkIpStatus === CHECKING_RESTRICTED_IP_STAUTS.PASSED;
  },
  getter_user_ip_not_allowed(state) {
    return state.checkIpStatus === CHECKING_RESTRICTED_IP_STAUTS.NOT_ALLOWED;
  },
  getter_user_organization(state) {
    return state.userProfile?.organizationInfo.id || null;
  },
  getter_user_id(state) {
    return state.userProfile?.id || null;
  },
  getter_user_display_name(state) {
    if (!state.userProfile) return "";
    const { displayName, firstName, lastName } = state.userProfile;
    return displayName || `${firstName} ${lastName}`;
  },
  getter_user_email(state) {
    return state.userProfile?.email || "";
  },
  getter_user_display_email_or_id(state) {
    if (!state.userProfile) return "";
    const { passwordless, employeeId, email } = state.userProfile;
    if (passwordless) return employeeId;
    const emailOrId = email;
    return emailOrId.includes("deephow.ai") ? emailOrId.split(".")[1].split("@")[0] : emailOrId;
  },
  getter_user_locale(state) {
    return state.userProfile?.locale || "";
  },
  getter_user_avatar(state) {
    return state.userProfile?.avatar || "";
  },
  getter_user_avatar_change_count(state) {
    return state.userProfile?.changeCount || 0;
  },
  getter_is_shared_account(state) {
    return state.userProfile?.shared || false;
  },
  getter_is_gen2(state) {
    return state.userProfile?.organizationInfo.generation === "gen2";
  },
  getter_is_gen2_plus(state) {
    return state.userProfile?.organizationInfo.generation === "gen2plus";
  },
  getter_org_default_workspace(state) {
    return state.userProfile?.organizationInfo.defaultGroup || "";
  },
  getter_org_default_workspace_group(state) {
    return state.defaultWorkspaceGroupId;
  },
  getter_client_key(state) {
    return state.clientKey;
  },
};

export const mutations = {
  LOGOUT(state) {
    document.body.id = "";
    setIsIpPassedLocalStorage(false);
    state.clientIP = "";
    state.clientKey = "";
    state.isLogin = false;
    state.userProfile = null;
    state.checkIpStatus = CHECKING_RESTRICTED_IP_STAUTS.TODO;
    state.defaultWorkspaceGroupId = "";
    state.access = null;
  },
  SET_CLIENT_IP(state, payload) {
    state.clientIP = payload;
  },
  SET_CLIENT_KEY(state, value = "") {
    state.clientKey = value;
  },
  SET_IP_STATUS(state, payload) {
    state.checkIpStatus = payload;
  },
  SET_IS_LOGIN(state, payload) {
    state.isLogin = payload;
  },
  SET_USER_PROFILE(state, payload) {
    state.userProfile = payload;
  },
  SET_USER_PROFILE_KEY(state, { key, value }) {
    if (state.userProfile.hasOwnProperty(key)) {
      state.userProfile[key] = value;
    }
  },
  SET_DEFAULT_WORKSPACE_GROUP_ID(state, payload) {
    state.defaultWorkspaceGroupId = payload;
  },
  SET_USER_ACCESS(state, payload) {
    state.access = payload;
  },
  SET_USER_ACCESS_READY(state, payload) {
    state.isAccessReady = payload;
  },
};
