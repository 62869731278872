import DDialog from "@/components/DDialog/DDialog.vue";
import DPageContent from "@/components/DPageLayout/DPageContent.vue";
import DPageLayout from "@/components/DPageLayout/DPageLayout.vue";
import DPageToolbar from "@/components/DPageLayout/DPageToolbar.vue";
import DButton from "@/components/DVuetify/button/DButton";
import DFlex from "@/components/DVuetify/DFlex";
import DLayout from "@/components/DVuetify/DLayout";
import DLoadingBlock from "@/components/ui_components/DLoadingBlock.vue";
import DEmptyBlock from "@/components/ui_components/DEmptyBlock.vue";
import DSearchInput from "@/components/ui_components/DSearchInput.vue";
import DDropdownMenu from "@/components/ui_components/DDropdownMenu.vue";
import DDropdownMenuItem from "@/components/ui_components/DDropdownMenuItem.vue";
import DFooterExpandPanel from "@/components/ui_components/DFooterExpandPanel.vue";
import DCheckbox from "@/components/ui_components/DCheckbox.vue";
import DTooltip from "@/components/ui_components/DTooltip.vue";
import DIconButton from "@/components/ui_components/DIconButton.vue";
import IconBase from "@/components/IconBase.vue";
import DList from "@/components/DList/DList.vue";
import DListItem from "@/components/DList/DListItem.vue";

const DeephowUI = {
  install(Vue) {
    Vue.component("d-button", DButton);
    Vue.component("d-checkbox", DCheckbox);
    Vue.component("d-layout", DLayout);
    Vue.component("d-flex", DFlex);
    Vue.component("d-dialog", DDialog);
    Vue.component("d-list", DList);
    Vue.component("d-list-item", DListItem);
    Vue.component("d-page-layout", DPageLayout);
    Vue.component("d-page-content", DPageContent);
    Vue.component("d-page-toolbar", DPageToolbar);
    Vue.component("d-loading-block", DLoadingBlock);
    Vue.component("d-empty-block", DEmptyBlock);
    Vue.component("d-search-input", DSearchInput);
    Vue.component("d-dropdown-menu", DDropdownMenu);
    Vue.component("d-dropdown-menu-item", DDropdownMenuItem);
    Vue.component("d-footer-expand-panel", DFooterExpandPanel);
    Vue.component("d-tooltip", DTooltip);
    Vue.component("d-icon-button", DIconButton);
    Vue.component("icon-base", IconBase);
  },
};

export default DeephowUI;
