import { ROUTE_NAME, ROUTE_PATH } from "../route";

export const OWNED_BY_ME_ROUTE_NAMES = [
  ROUTE_NAME.OWNED_BY_ME,
  ROUTE_NAME.OWNED_BY_ME_WORKFLOWS,
  ROUTE_NAME.OWNED_BY_ME_MM_SOP,
  ROUTE_NAME.OWNED_BY_ME_SKILLS,
  ROUTE_NAME.OWNED_BY_ME_TEAMS,
];

export default [
  {
    path: ROUTE_PATH.OWNED_BY_ME,
    name: ROUTE_NAME.OWNED_BY_ME,
    redirect: { name: ROUTE_NAME.OWNED_BY_ME_WORKFLOWS },
    component: () => import("@/components/pages/OwnedByMe/OwnedByMe.vue"),
    meta: { auth: true },
    children: [
      {
        path: ROUTE_PATH.OWNED_BY_ME_WORKFLOWS,
        name: ROUTE_NAME.OWNED_BY_ME_WORKFLOWS,
        component: () => import("@/components/pages/OwnedByMe/OwnedByMeWorkflows.vue"),
      },
      {
        path: ROUTE_PATH.OWNED_BY_ME_MM_SOP,
        name: ROUTE_NAME.OWNED_BY_ME_MM_SOP,
        component: () => import("@/components/pages/OwnedByMe/OwnedByMeMMSOP.vue"),
      },
      {
        path: ROUTE_PATH.OWNED_BY_ME_SKILLS,
        name: ROUTE_NAME.OWNED_BY_ME_SKILLS,
        component: () => import("@/components/pages/OwnedByMe/OwnedByMeSkills.vue"),
      },
      {
        path: ROUTE_PATH.OWNED_BY_ME_TEAMS,
        name: ROUTE_NAME.OWNED_BY_ME_TEAMS,
        component: () => import("@/components/pages/OwnedByMe/OwnedByMeTeams.vue"),
      },
    ],
  },
];
