import firebase from "firebase";
import { FIREBASE_CONFIG } from "@/constants/env/firebase";

import { IS_CHINA } from "@/constants/env";

// Firebase DB singleton
const firebaseApp = firebase;
let DB = null;

function setUpFirebase() {
  firebase.initializeApp(FIREBASE_CONFIG);
  const db = firebase.firestore();

  const settings = { experimentalForceLongPolling: true, merge: true };
  db.settings(settings);
  return db;
}

// Ensure to call initializeFirebase in main.js to explicitly setup firebase
function initializeFirebase() {
  if (DB === null) {
    DB = IS_CHINA ? null : setUpFirebase();
  }
}

// Export Firestore instance
export { DB, firebaseApp, initializeFirebase };
