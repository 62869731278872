import { DatePicker } from "element-ui";
import elementLocale from "element-ui/lib/locale";
import "@/assets/element-variables.scss";

const ElementUI = {
  install(Vue, { i18n } = {}) {
    // Configure element-ui locale if i18n is provided
    if (i18n) {
      elementLocale.i18n((key, value) => i18n.t(key, value));
    }

    // Register Element UI components
    Vue.component(DatePicker.name, DatePicker);
  },
};

export default ElementUI;
