import { render, staticRenderFns } from "./DDialogIconHeader.vue?vue&type=template&id=323b9240&scoped=true&"
import script from "./DDialogIconHeader.vue?vue&type=script&lang=js&"
export * from "./DDialogIconHeader.vue?vue&type=script&lang=js&"
import style0 from "./DDialogIconHeader.vue?vue&type=style&index=0&id=323b9240&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323b9240",
  null
  
)

export default component.exports