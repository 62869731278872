<template>
  <div class="DNavGroup">
    <div class="group__title" :class="{ active: isActive }" @click="toggleOpen">
      <div class="group__title--left">
        <font-awesome-icon class="text-white" :icon="['fas', 'industry-windows']" />
        <v-tooltip lazy bottom left :open-delay="800" color="#2C2D32">
          <workspace-group-title
            slot="activator"
            :title="workspaceGroup.name"
            size="small"
            :color="isOpen ? 'white' : ''"
            max-width="142"
          />
          <span>{{ workspaceGroup.name }}</span>
        </v-tooltip>
      </div>
      <div
        class="icon__arrow"
        :class="{ open: isOpen || iskeepOpen, 'no-show': workspaceGroup.workspaces.length === 0 }"
        @click.stop="toggleOpen"
      >
        <font-awesome-icon :icon="['fas', 'chevron-down']" />
      </div>
    </div>
    <div class="DNavGroup__expend" :class="{ open: isOpen || iskeepOpen }">
      <nav-workspace-item
        v-for="(workspace, idx) in workspaceGroup.workspaces"
        :key="`${workspace.id}-${idx}`"
        :workspace="workspace"
        :workspaceGroup="workspace.workspaceGroupName"
      />
    </div>
  </div>
</template>
<script>
import WorkspaceGroupTitle from "../WorkspaceGroupTitle.vue";
import DIconArrowForward from "../icons/DIconArrowForward.vue";
import NavWorkspaceItem from "./NavWorkspaceItem.vue";
export default {
  props: {
    workspaceGroup: {
      type: Object,
      required: true,
    },
    iskeepOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  components: { WorkspaceGroupTitle, DIconArrowForward, NavWorkspaceItem },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    open() {
      this.isOpen = true;
    },
  },
  computed: {
    isActive() {
      return this.$route.params.workspaceGroupId === this.workspaceGroup.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.DNavGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-05);
  .group__title {
    display: flex;
    color: #9397a6;
    padding: 12px 24px;
    font-size: 16px;
    justify-content: space-between;
    &.active,
    &:hover {
      background: #28292e;
    }
    &--left {
      display: flex;
      align-items: center;
      gap: 24px;
    }
    .icon__arrow {
      width: 18px;
      transition: all 0.2s;
      visibility: visible;
      cursor: pointer;
      &.open {
        transform: rotate(180deg);
      }
      &.no-show {
        visibility: hidden;
      }
    }
  }
  &__expend {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-03);
    height: 0;
    overflow-y: hidden;
    transition: all 0.5s;
    &.open {
      height: auto;
      margin-bottom: var(--spacing-05);
    }
  }
  ::v-deep .workspaceGroupTitle__title {
    user-select: none;
  }
}
</style>
