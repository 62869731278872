<template>
  <div class="DCarouselPagingButtons">
    <button class="DCarouselPagingButtons__button" @click="$emit('handlePrevClick')">
      <icon-base color="#FFFFFF" height="16" width="16">
        <d-icon-arrow-back />
      </icon-base>
    </button>
    <div class="DCarouselPagingButtons__numText text-no-wrap" v-html="numberText"></div>
    <button class="DCarouselPagingButtons__button" @click="$emit('handleNextClick')">
      <icon-base color="#FFFFFF" height="16" width="16" class="DCarouselPagingButtons__button--right">
        <d-icon-arrow-back />
      </icon-base>
    </button>
  </div>
</template>

<script>
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
import { getPagingNumber } from "@/js/common/formatText.js";

export default {
  name: "DCarouselPagingButtons",
  props: {
    currentIndex: Number,
    total: Number,
  },
  components: {
    DIconArrowBack,
  },
  computed: {
    numberText() {
      return getPagingNumber({
        current: this.currentIndex + 1,
        total: this.total,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$buttonSize: 32px;
.DCarouselPagingButtons {
  height: $buttonSize;
  display: flex;
  align-items: center;
  justify-content: center;
  &__button {
    min-width: $buttonSize;
    min-height: $buttonSize;
    padding: 6px 0 0 0;
    border-radius: 50%;
    background: #28292e;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    &--right {
      transform: scaleX(-1);
      filter: FlipH;
    }
  }
  &__numText {
    margin: 0 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    & ::v-deep span {
      font-weight: 400;
      color: #9397a6;
    }
  }
}
</style>
