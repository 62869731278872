import Vue from "vue";
import { WORKFLOW_ACTION } from "@/constants/editorStatus";
import delay from "@/js/time/delay";
import { fetchWorkspaceStates } from "@/server/analytics-server";

export const namespaced = true;
export const state = {
  workflowCountMap: {}, // { [workspaceId]: { created: Number, published: Number, unpublished: Number }}
};

export const mutations = {
  STORE_WORKFLOW_COUNT(state, { workspaceId, created, published }) {
    const value = {
      created: created,
      published: published,
      unpublished: created - published,
    };
    if (workspaceId in state.workflowCountMap) {
      state.workflowCountMap[workspaceId] = value;
    } else {
      Vue.set(state.workflowCountMap, workspaceId, value);
    }
  },
  STORE_WORKFLOW_COUNT_FROM_API(state, workspaces) {
    workspaces.forEach((workspace) => {
      const { id, workflows } = workspace;
      const value = {
        created: workflows.created,
        published: workflows.published,
        unpublished: workflows.created - workflows.published,
      };
      if (id in state.workflowCountMap) {
        state.workflowCountMap[id] = value;
      } else {
        Vue.set(state.workflowCountMap, id, value);
      }
    });
  },
};

export const actions = {
  async changeWorkflowCountByMethod({ commit, state, dispatch }, payload) {
    const { method, isPublished, workspaceId, toWorkspaceId } = payload;
    const originState = state.workflowCountMap[workspaceId];
    // use frontend chnage count first and use api sync current count later (prevent bigquery is not ready)
    switch (method) {
      case WORKFLOW_ACTION.CREATE:
        commit("STORE_WORKFLOW_COUNT", {
          workspaceId,
          created: originState.created + 1,
          published: originState.published,
        });
        break;
      case WORKFLOW_ACTION.DUPLICATED:
        commit("STORE_WORKFLOW_COUNT", {
          workspaceId,
          created: originState.created + 1,
          published: isPublished ? originState.published + 1 : originState.published,
        });
        break;
      case WORKFLOW_ACTION.DELETE:
        commit("STORE_WORKFLOW_COUNT", {
          workspaceId,
          created: originState.created - 1,
          published: isPublished ? originState.published - 1 : originState.published,
        });
        break;
      case WORKFLOW_ACTION.MOVE:
        commit("STORE_WORKFLOW_COUNT", {
          workspaceId,
          created: originState.created - 1,
          published: isPublished ? originState.published - 1 : originState.published,
        });
        const destinationWorkspaceState = state.workflowCountMap[toWorkspaceId];
        if (destinationWorkspaceState) {
          commit("STORE_WORKFLOW_COUNT", {
            workspaceId: toWorkspaceId,
            created: destinationWorkspaceState.created + 1,
            published: isPublished ? destinationWorkspaceState.published + 1 : destinationWorkspaceState.published,
          });
        }
        break;
      case WORKFLOW_ACTION.TO_UNPUBLISHED:
        commit("STORE_WORKFLOW_COUNT", {
          workspaceId,
          created: originState.created,
          published: originState.published - 1,
        });
        break;
    }

    let needResyncWorkspaceIds = [workspaceId];
    if (toWorkspaceId) {
      needResyncWorkspaceIds.push(toWorkspaceId);
    }
    await delay(3);
    dispatch("fetchWorkflowCountByWorkspaceIds", needResyncWorkspaceIds);
  },
  async fetchWorkflowCountByWorkspaceIds({ commit }, workspaceIds) {
    try {
      const { ok, data } = await fetchWorkspaceStates({
        size: workspaceIds.length,
        workspaces: workspaceIds,
      });
      if (ok) {
        commit("STORE_WORKFLOW_COUNT_FROM_API", data.items);
      }
    } catch (e) {
      console.log(e);
    }
  },
};
