<template>
  <div class="ControlsIframe" :class="{ 'ControlsIframe--xs': isXSmallScreen }">
    <safety-info-watermark v-if="showSafetyInfoWaterMark" :watermarkId="watermarkId" />
    <!-- top title (always keep to display) -->
    <template v-if="isUsingIframe && !isDivideMode">
      <div class="ControlsIframe__shadow--up"></div>
      <div class="ControlsIframe__topBar" @click.stop>
        <div class="ControlsIframe__topBarTitle">
          <div>
            {{ workflowTitle }}
          </div>
          <div class="ControlsIframe__topBar--stepTitle">{{ stepNum }}. {{ stepTitle }}</div>
        </div>
        <v-tooltip
          v-if="!isSingleStepPlayer"
          :disabled="isDisabledTooltip"
          bottom
          color="#2C2D32"
          z-index="101"
          :value="isShowMenuTipForOnce"
        >
          <button slot="activator" class="ControlsIframe__topBarMenu" @click="$emit('click-menu-btn')">
            <font-awesome-icon :icon="['fas', 'list-ul']" />
          </button>
          <span>{{ $t("player.menuTooltip") }}</span>
        </v-tooltip>
      </div>
    </template>

    <template v-if="showControl">
      <div class="ControlsIframe__shadow--down"></div>

      <!-- center buttons -->
      <div class="ControlsIframe__center" @click.stop>
        <button v-show="!isSmallScreen" class="ControlsIframe__center--btn" @click="$emit('play-back')">
          <font-awesome-icon
            :icon="['fas', 'arrow-rotate-right']"
            class="ControlsIframe__center--btnRotate text-white"
          />
        </button>

        <button v-if="!isSingleStepPlayer" class="ControlsIframe__center--btn" @click="$emit('play-previous-step')">
          <font-awesome-icon :icon="['fas', 'forward-step']" class="ControlsIframe__center--btnRotate text-white" />
        </button>
        <button class="ControlsIframe__center--playBtnLg" @click="onclickPlayOrPause">
          <font-awesome-icon :icon="playing ? ['fas', 'circle-pause'] : ['fas', 'circle-play']" class="text-white" />
        </button>

        <button v-if="!isSingleStepPlayer" class="ControlsIframe__center--btn" @click="$emit('play-next-step')">
          <font-awesome-icon :icon="['fas', 'forward-step']" class="text-white" />
        </button>
        <button v-show="!isSmallScreen" class="ControlsIframe__center--btn" @click="$emit('play-forward')">
          <font-awesome-icon :icon="['fas', 'arrow-rotate-right']" class="text-white" />
        </button>
      </div>

      <!-- bottom controls -->
      <div class="ControlsIframe__bottom" @click.stop>
        <div v-html="videoTime" class="ControlsIframe__bottom--timeText"></div>
        <div class="ControlsIframe__bottom--btns">
          <controls-repeat-button
            :isSingleStepPlayer="isSingleStepPlayer"
            class="ControlsIframe__bottom--btn"
            size="medium"
          />
          <button class="ControlsIframe__bottom--btn" @click="$emit('click-mute')">
            <font-awesome-icon :icon="isMuted ? ['far', 'volume-slash'] : ['far', 'volume']" />
          </button>
          <v-tooltip :disabled="isDisabledTooltip" top color="#2C2D32" z-index="101">
            <button slot="activator" class="ControlsIframe__bottom--btn" @click="$emit('click-setting')">
              <font-awesome-icon :icon="['far', 'gear']" />
            </button>
            <span> {{ $t("player.settings") }}</span>
          </v-tooltip>
          <v-tooltip v-if="enableTheaterButton" :disabled="isDisabledTooltip" top color="#2C2D32" z-index="101">
            <button slot="activator" class="ControlsIframe__bottom--btn" @click="changeTheaterMode">
              <icon-base v-if="isTheaterMode"><d-icon-theater-mode-exit /></icon-base>
              <font-awesome-icon v-else :icon="['far', 'rectangle']" />
            </button>
            <span> {{ isTheaterMode ? $t("player.exitTheaterMode") : $t("player.theaterMode") }} (T)</span>
          </v-tooltip>
          <v-tooltip v-if="isSupportFullscreen" :disabled="isDisabledTooltip" top color="#2C2D32" z-index="101">
            <button slot="activator" class="ControlsIframe__bottom--btn" @click="$emit('change-fullscreen')">
              <font-awesome-icon :icon="isFullscreen ? ['far', 'compress-wide'] : ['far', 'expand-wide']" />
            </button>
            <span> {{ isFullscreen ? $t("player.exitFullscreen") : $t("player.fullscreen") }} (F)</span>
          </v-tooltip>
        </div>
        <div class="ControlsIframe__bottom--timeWrapper">
          <slot></slot>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DIconTheaterModeExit from "@/components/icons/player/DIconTheaterModeExit.vue";
import ControlsRepeatButton from "@/components/DWorkflowPlayer/controls/ControlsRepeatButton.vue";
import { getDisplayTitle } from "@/js/video-player/title-display.js";
import { getFormattedVideoDuration, getNumberWithZero } from "@/js/common/formatText.js";
import { mapActions, mapGetters, mapState } from "vuex";
import { IS_TABLET, IS_MOBILE } from "@/constants/device-version";
import { IS_SUPPORT_FULLSCREEN } from "@/js/workflow/fullscreen.js";
import Cookies from "js-cookie";

const IS_VIEWED_MENU_TOOLTIP = "IS_VIEWED_MENU_TOOLTIP";

export default {
  name: "ControlsIframe",
  props: {
    playing: Boolean,
    isMuted: Boolean,
    isPublic: Boolean,
    isXSmallScreen: Boolean,
    isSmallScreen: Boolean,
    isUsingIframe: Boolean,
    currentStep: Number,
    currentVideoTime: Number,
    subtitlesChoice: String,
    enableTheaterButton: Boolean,
    watermarkId: {
      type: String,
    },
    showControl: {
      type: Boolean,
    },
    isSingleStepPlayer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDisabledTooltip: IS_TABLET || IS_MOBILE,
      isShowMenuTipForOnce: false,
      isSupportFullscreen: IS_SUPPORT_FULLSCREEN,
    };
  },
  components: {
    DIconTheaterModeExit,
    ControlsRepeatButton,
    SafetyInfoWatermark: () => import("./SafetyInfoWatermark.vue"),
  },
  mounted() {
    this.checkToShowMenuTip();
  },
  methods: {
    ...mapActions("workflowPlayer", ["changeTheaterMode"]),
    checkToShowMenuTip() {
      if (!this.isUsingIframe || !this.isPublic) return;
      if (Cookies.get(IS_VIEWED_MENU_TOOLTIP)) return;

      Cookies.set(IS_VIEWED_MENU_TOOLTIP, "1");
      this.isShowMenuTipForOnce = true;
      setTimeout(() => {
        this.isShowMenuTipForOnce = false;
      }, 2500);
    },
    onclickPlayOrPause() {
      if (this.playing) {
        this.$emit("pause-video");
        return;
      }
      this.$emit("play-video");
    },
  },
  computed: {
    ...mapGetters("workflowPlayer", ["getters_steps"]),
    ...mapGetters("permission", ["getter_safety_watermark_enabled"]),
    ...mapState("workflowPlayer", ["workflowData", "isDivideMode", "isFullscreen", "isTheaterMode"]),
    showSafetyInfoWaterMark() {
      return this.workflowData.enableSafetyWatermark;
    },
    videoTime() {
      const current = getFormattedVideoDuration({ videoDuration: this.currentVideoTime });
      const total = getFormattedVideoDuration({ videoDuration: this.getters_steps[this.currentStep].duration });
      return `${current} / ${total}`;
    },
    workflowTitle() {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      return getDisplayTitle(this.workflowData, displayLang);
    },
    stepNum() {
      return getNumberWithZero({ num: this.currentStep + 1 });
    },
    stepTitle() {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      const step = this.getters_steps[this.currentStep] || {};
      return getDisplayTitle(step, displayLang);
    },
  },
};
</script>

<style lang="scss" scoped>
.ControlsIframe {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  --bottom-btn-gap: 24px;
  --bottom-btn-size: 24px;
  --center-btn-size: 30px;
  --center-play-btn-size: 48px;
  &--xs {
    --bottom-btn-gap: 12px;
    --bottom-btn-size: 14px;
    --center-btn-size: 24px;
    --center-play-btn-size: 30px;
  }
  &__shadow {
    &--up {
      position: absolute;
      top: 0;
      left: 0;
      height: 60%;
      width: 100%;
      background: linear-gradient(180deg, rgba(12, 12, 14, 1) 0%, rgba(12, 12, 14, 0) 80%);
    }
    &--down {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60%;
      width: 100%;
      background: linear-gradient(0deg, rgba(12, 12, 14, 1) 0%, rgba(12, 12, 14, 0) 80%);
    }
  }
  &__topBar {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &Title {
      color: var(--dGrey6-color);
      font-size: 14px;
      line-height: 14px;
    }
    &Menu {
      color: #ffffff;
      font-size: 16px;
      height: 32px;
      width: 32px;
      pointer-events: auto;
      background: rgba(0, 0, 0, 0.25);
      border-radius: 100px;
    }
    &--stepTitle {
      margin-top: var(--spacing-02);
      color: #ffffff;
      font-size: 16px;
    }
  }
  &__center {
    position: relative;
    display: flex;
    align-items: center;
    justify-items: center;

    &--btnRotate {
      transform: rotateY(180deg);
    }
    &--btn {
      pointer-events: auto;
      color: #ffffff;
      margin: 0 2.5vw;
      svg {
        height: var(--center-btn-size);
        width: var(--center-btn-size);
      }
    }
    &--playBtnLg {
      pointer-events: auto;
      margin: 0 2.5vw;
      svg {
        height: var(--center-play-btn-size);
        width: var(--center-play-btn-size);
      }
    }
  }
  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    &--timeText {
      position: absolute;
      left: 16px;
      bottom: 34px;
      color: #8d909f;
      font-size: 12px;
      line-height: 14px;
    }
    &--btns {
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px 16px 0 0;
      display: flex;
      align-items: center;
      gap: var(--bottom-btn-gap);
    }
    &--btn {
      color: #ffffff;
      pointer-events: auto;
      svg {
        height: var(--bottom-btn-size);
        width: var(--bottom-btn-size);
      }
    }
    &--timeWrapper {
      position: absolute;
      width: 100%;
      height: 22px;
      bottom: 4px;
      left: 0;
      pointer-events: auto;
    }
  }
}
</style>
