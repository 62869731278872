import { IS_CHINA } from "@/constants/env";
import db from "../../../db";
import { postNotificationList } from "@/server/notifications-server";
export const namespaced = true;

const getNotificationsDate = function () {
  // we don't use msec in ali
  return !IS_CHINA
    ? new Date(Date.now() - 1000 * 60 * 60 * 24 * 30)
    : Math.floor((Date.now() - 1000 * 60 * 60 * 24 * 30) / 1000);
};

export const state = {
  notifications: [],
  dbNotificationsNum: 0,
  isLoading: false,
};

export const mutations = {
  STORE_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
  CLEAR_NOTIFICATIONS(state) {
    state.notifications = [];
  },
  STORE_DB_NOTIFICATIONS_NUM(state, num) {
    state.dbNotificationsNum = num;
  },
  CLEAR_DB_NOTIFICATIONS_NUM(state) {
    state.dbNotificationsNum = 0;
  },
  UPDATE_NOTIFICATION(state, { index, key, value }) {
    state.notifications[index][key] = value;
  },
  SET_LOADING(state, { value }) {
    state.isLoading = value;
  },
};

export const getters = {
  getters_new_notifications: (state) => {
    return state.notifications.filter((notification) => {
      return notification.counter == true;
    });
  },
};

export const actions = {
  async fetchNotificationsFromFirestore({ state, commit, dispatch }, args) {
    if (state.dbNotificationsNum > 0) {
      return;
    }
    commit("SET_LOADING", { value: true });
    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
      {
        field: "receiverId",
        comparison: "==",
        value: args.uid,
      },
      {
        field: "createdAt",
        comparison: ">",
        value: getNotificationsDate(), //get notifications within 30 days
      },
    ];
    const methods = [
      {
        type: "orderBy",
        value: "'createdAt', 'desc'",
      },
      {
        type: "limit",
        value: 500,
      },
    ];
    commit("CLEAR_DB_NOTIFICATIONS_NUM");
    let newDbNotifications = [];
    // TODO: refactor the way of listening to backend updates
    db.getDocumentQueryListener.call(this, "notifications", comparisons, methods, function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        const notification = doc.data();
        newDbNotifications.push(notification);
      });
      const num = newDbNotifications.length;
      commit("STORE_DB_NOTIFICATIONS_NUM", num);
      if (newDbNotifications.length > 0) {
        dispatch("fetchNotifications");
      }
    });
    commit("SET_LOADING", { value: false });
  },
  async fetchNotifications({ commit }) {
    commit("SET_LOADING", { value: true });
    const { ok, data, errorMessage } = await postNotificationList();
    if (ok) {
      const { item: notifications = [] } = data;
      commit("CLEAR_NOTIFICATIONS");
      commit("STORE_NOTIFICATIONS", notifications);
    } else {
      console.warn(errorMessage);
    }
    commit("SET_LOADING", { value: false });
  },
  clearNotifications({ commit }) {
    commit("CLEAR_NOTIFICATIONS");
    commit("CLEAR_DB_NOTIFICATIONS_NUM");
  },
  updateAllCounterInNotifications({ state, commit }) {
    for (let index = 0; index < state.notifications.length; index++) {
      const notification = state.notifications[index];
      if (notification.counter) {
        commit("UPDATE_NOTIFICATION", { index, key: "counter", value: false });
      }
    }
  },
  updateReadInNotifications({ state, commit }, { index }) {
    commit("UPDATE_NOTIFICATION", { index, key: "read", value: true });
    const notification = state.notifications[index];
    let newActivity = notification.activity;
    newActivity.forEach((user) => {
      user["read"] = true;
    });
    commit("UPDATE_NOTIFICATION", { index, key: "activity", value: newActivity });
  },
};
