<template>
  <div class="DLoadingBlock">
    <d-icon-processing-bar-flex height="4px" width="120px" />
    <div v-if="$slots.title" class="DLoadingBlock__title">
      <slot name="title" />
    </div>
    <div v-if="$slots.description" class="DLoadingBlock__description">
      <slot name="description" />
    </div>
  </div>
</template>

<script>
import DIconProcessingBarFlex from "../icons/animated/DIconProcessingBarFlex.vue";
export default {
  name: "DLoadingBlock",
  components: {
    DIconProcessingBarFlex,
  },
};
</script>

<style lang="scss" scoped>
.DLoadingBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: var(--spacing-03);

  &__title {
    color: var(--dGrey6-color);
    font-size: 16px;
  }
  &__description {
    color: var(--dGrey-color);
    font-size: 14px;
  }
}
</style>
