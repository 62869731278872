<template>
  <div>
    <d-dropdown-menu v-if="showButton" trigger="click">
      <button class="dh-btn btn-secondary HeaderCreateButton">
        <font-awesome-icon :icon="['fas', 'plus']" class="text-primary" />
        <span class="text-capitalize">{{ $t("all.create") }}</span>
      </button>
      <template slot="dropdown">
        <d-dropdown-menu-item
          v-for="item in createActions"
          :key="item.key"
          @click="item.action"
          :disabled="item.disabled"
        >
          <hr v-if="item.key === 'divider'" class="w-full"></hr>
          <template v-else>{{ item.text }}</template>
        </d-dropdown-menu-item>
      </template>
    </d-dropdown-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import eventbus from "@/main";
import { EVENT } from "@/constants/globalEvents";
import { UPLOAD_TYPE } from "@/constants/uploadType";
import { USER_ROLE } from "@/constants/userRole";
import { IS_CHINA } from "@/constants/env";

export default {
  name: "HeaderCreateButton",
  data() {
    return {
      showMMSOPDialog: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getter_user_role", "user_access"]),
    ...mapGetters("permission", ["getter_zoom_integration_enabled"]),
    showButton() {
      return this.createActions.length > 0;
    },
    createActions() {
      const actions = [
        {
          key: UPLOAD_TYPE.MMSOP,
          text: this.$t("workspace.createMMSOP"),
          action: () => this.openCreateMMSOPPop(),
          canAccess: this.canCreateWorkflow,
        },
        {
          key: UPLOAD_TYPE.LOCAL_VIDEO,
          text: this.$t("new.uploadVideo"),
          action: () => this.openUploaderPop(UPLOAD_TYPE.LOCAL_VIDEO),
          canAccess: this.canCreateWorkflow,
        },
        {
          key: UPLOAD_TYPE.RECORD_SCREEN,
          text: this.$t("new.recordScreen"),
          action: () => this.openUploaderPop(UPLOAD_TYPE.RECORD_SCREEN),
          canAccess: this.canCreateWorkflow,
        },
        {
          key: UPLOAD_TYPE.ZOOM_IMPORT,
          text: this.$t("new.importFromZoom"),
          action: () => this.openUploaderPop(UPLOAD_TYPE.ZOOM_IMPORT),
          canAccess: this.canAccessZoomImport,
        },
        {
          key: "divider",
          disabled: true,
          action: () => {},
          canAccess: true,
        },
        {
          key: "createSkill",
          text: this.$t("workspace.createSkill"),
          action: () => this.openCreateSkillPop(),
          canAccess: this.user_access.skills,
        },
      ];
      return actions.filter((action) => action.canAccess);
    },
    canCreateWorkflow() {
      return [
        USER_ROLE.ADMIN_SUPER,
        USER_ROLE.ADMIN_ORG,
        USER_ROLE.ADMIN_WORKSPACE,
        USER_ROLE.PUBLISHER,
      ].includes(this.getter_user_role);
    },
    canAccessZoomImport() {
      return !IS_CHINA && this.getter_zoom_integration_enabled && this.canCreateWorkflow;
    },
  },
  methods: {
    openUploaderPop(uploadType) {
      eventbus.$emit(EVENT.SHOW_CREATE_WORKFLOW_POP, { uploadType });
    },
    openCreateSkillPop() {
      this.$emit("openCreateSkill");
    },
    openCreateMMSOPPop() {
      eventbus.$emit(EVENT.SHOW_CREATE_MM_SOP_POP);
    },
  },
};
</script>

<style lang="scss" scoped>
.HeaderCreateButton {
  width: 120px;
}
</style>
