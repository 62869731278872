import { ROUTE_NAME, ROUTE_PATH } from "../route.js";

const EditorRoute = [
  {
    name: ROUTE_NAME.BUILDER_MAIN,
    path: ROUTE_PATH.BUILDER_MAIN,
    component: () => import("@/components/BuilderMain"),
    meta: { auth: true },
    props: true,
  },
  {
    name: ROUTE_NAME.EDITOR_MAIN,
    path: ROUTE_PATH.EDITOR_MAIN,
    props: true,
    component: () => import("@/components/DEditor2.0/EditorMain"),
    redirect: { name: ROUTE_NAME.EDITOR_TRANSCRIPTION },
    meta: { auth: true },
    children: [
      {
        name: ROUTE_NAME.EDITOR_TRANSCRIPTION,
        path: ROUTE_PATH.EDITOR_TRANSCRIPTION,
        props: true,
        meta: { order: 1 },
        component: () => import("@/components/DEditor2.0/Transcription"),
      },
      {
        name: ROUTE_NAME.EDITOR_SEGMENTATION,
        path: ROUTE_PATH.EDITOR_SEGMENTATION,
        props: true,
        meta: { order: 2 },
        component: () => import("@/components/DEditor2.0/Segmentation"),
      },
    ],
  },
];

export default EditorRoute;
