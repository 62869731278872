import db from "../../../db";
import { RECOMMEND_TYPE } from "@/constants/recommendWorkflow";

export const namespaced = true;
export const state = {
  recommends_from_api: [],

  workflows_by_workspaces_sort_rule: {},
  assigned_private_workspace_ids: new Set(), // set of workspace id
};

export const mutations = {
  STORE_RECOMMEND_DATA(state, payload) {
    state.recommends_from_api = payload;
  },
  STORE_CONTINUE_WATCHING_DATA(state, newContinueWatchingData) {
    const continueWatchingSource = state.recommends_from_api.find(
      (data) => data.recommendType === RECOMMEND_TYPE.CONTINUE_WATCHING
    );
    if (continueWatchingSource) {
      continueWatchingSource.workflows = newContinueWatchingData.workflows;
    }
  },

  STORE_WORKFLOW_SORT_RULE(state, rule) {
    state.workflows_by_workspaces_sort_rule = rule;
  },

  STORE_WORKSPACES_ASSIGNED_PRIVATE(state, payload = []) {
    state.assigned_private_workspace_ids = new Set(payload);
  },
};

export const getters = {
  getters_recommends_data: (state) => state.recommends_from_api,

  // TODO: [MMSOP] refactor to only require workspaceId to check, not the entire workflow;
  getters_is_not_in_private_workspace: (state) => (workflow) => {
    const workspaceId = workflow.workspaceId || workflow.group; // compatible with old group name
    return workflow.privateWorkspace && !state.assigned_private_workspace_ids.has(workspaceId);
  },
};

export const actions = {
  async storeContinueWatchingData({ commit, state }, apiResult = []) {
    try {
      const isEmptyData = !Array.isArray(apiResult) || apiResult.length === 0;
      if (isEmptyData) {
        return;
      }
      let newContinueWatchingData = apiResult.find((data) => data.recommendType === RECOMMEND_TYPE.CONTINUE_WATCHING);
      if (!newContinueWatchingData) {
        return;
      }

      const promises = [];
      newContinueWatchingData.workflows.forEach((workflow_data) => {
        const promise_fn = async () => {
          workflow_data["workflow"] = await db.getDocument
            .call(this, "workflows", workflow_data.workflowId)
            .catch((err) => {
              console.warn("Faild to get workflow data", err);
              return null;
            });
        };
        promises.push(promise_fn());
      });

      await Promise.all(promises);
      // filter out the workflow if can not get the detail data from db
      newContinueWatchingData.workflows = newContinueWatchingData.workflows.filter(
        (workflow_data) => workflow_data && workflow_data.workflow
      );
      commit("STORE_CONTINUE_WATCHING_DATA", newContinueWatchingData);
    } catch (error) {
      console.log(error);
    }
  },
  async storeRecommendData({ commit }, apiResult = []) {
    try {
      const promises = [];
      apiResult.forEach(({ workflows }) => {
        workflows.forEach((workflow_data) => {
          const promise_fn = async () => {
            workflow_data["workflow"] = await db.getDocument
              .call(this, "workflows", workflow_data.workflowId)
              .catch((err) => {
                console.warn("Faild to get workflow data", err);
                return null;
              });
          };
          promises.push(promise_fn());
        });
      });
      await Promise.all(promises);
      // filter out the workflow if can not get the detail data from db
      apiResult.forEach((item) => {
        if (item.workflows) {
          item.workflows = item.workflows.filter((workflow_data) => workflow_data && workflow_data.workflow);
        }
      });
      commit("STORE_RECOMMEND_DATA", apiResult);
    } catch (error) {
      console.log(error);
    }
  },

  changeWorkflowSortRule({ commit }, rule) {
    commit("STORE_WORKFLOW_SORT_RULE", rule);
  },

  storeWorkspacesAssignedPrivate({ commit }, apiResult = []) {
    commit("STORE_WORKSPACES_ASSIGNED_PRIVATE", apiResult);
  },
};
