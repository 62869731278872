import { IS_PROD_OR_SANDBOX } from "@/constants/env";

const DEV_FIREBASE_CONFIG = {
  apiKey: "AIzaSyBG5xBkoMRFXpU2dFCNcysdAmdn9eJsI5M",
  authDomain: "deephow-dev.firebaseapp.com",
  databaseURL: "https://deephow-dev.firebaseio.com",
  projectId: "deephow-dev",
  storageBucket: "deephow-dev.appspot.com",
  messagingSenderId: "928515275242",
  appId: "1:928515275242:web:2fbb8f29ea748f40bc9a93",
  measurementId: "G-J17RQFZ6YC",
};

const PROD_FIREBASE_CONFIG = {
  apiKey: "AIzaSyBTAJQ9qNMbaPaIU7GcK59Vjr7GGc99xjw",
  authDomain: "deephow-prod.firebaseapp.com",
  databaseURL: "https://deephow-prod.firebaseio.com",
  projectId: "deephow-prod",
  storageBucket: "deephow-prod.appspot.com",
  messagingSenderId: "671982572211",
  appId: "1:671982572211:web:6787e99a3659eccb8ad6c6",
};

export const FIREBASEP_PROXY_HOST = "googleapis-com.stephanie.ai";

export const FIREBASE_COLLECTION_VERSIONING = {
  collections: ["transcriptions"],
  maxVersionsAllowed: 1000,
};

export const FIREBASE_CONFIG = IS_PROD_OR_SANDBOX ? PROD_FIREBASE_CONFIG : DEV_FIREBASE_CONFIG;
