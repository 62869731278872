import Vue from "vue";
import { keyBy } from "lodash-es";
import { fetchCategories } from "@/server/category-server";
import { fetchSubcategories } from "@/server/subcategory-server";

export const namespaced = true;
export const state = {
  isLoadedCategories: false,
  categories: [],
  categoriesById: {},
  subCategories: [],
  subCategoriesById: {},
  subCategoriesByCategoryId: {},
};

export const mutations = {
  STORE_ALL_CATEGORIES(state, payload = []) {
    state.categories = payload;
    state.categoriesById = keyBy(payload, "id");
    state.isLoadedCategories = true;
  },
  STORE_ALL_SUB_CATEGORIES(state, payload) {
    state.subCategories = payload;
    state.subCategoriesById = keyBy(payload, "id");
  },
  STORE_SUB_CATEGORIES(state, { categoryId, items }) {
    Vue.set(state.subCategoriesByCategoryId, categoryId, items);
  },
};

export const getters = {
  getters_get_sub_category_by_id: (state) => (subcategoryId) => state.subCategoriesById[subcategoryId] || {},
  getters_get_category_by_id: (state) => (categoryId) => state.categoriesById[categoryId] || {},
};

export const actions = {
  async fetchAllCategories({ commit }) {
    const { ok, data } = await fetchCategories({
      all: true,
      orderBy: "name",
      order: "asc",
    });
    if (!ok) return;
    commit("STORE_ALL_CATEGORIES", data.item);
  },
  async fetchAllSubCategories({ commit }) {
    const { ok, data } = await fetchSubcategories({
      all: true,
      orderBy: "name",
      order: "asc",
    });
    if (!ok) return;
    commit("STORE_ALL_SUB_CATEGORIES", data.item);
  },
  async fetchSubCategoriesByCategoryId({ commit }, categoryId) {
    const { ok, data } = await fetchSubcategories({
      all: true,
      orderBy: "name",
      order: "asc",
      categories: [categoryId],
    });
    if (!ok) return;
    commit("STORE_SUB_CATEGORIES", { categoryId, items: data.item });
  },
  async initAllCategoriesAndSubCategories({ dispatch }) {
    try {
      await dispatch("fetchAllCategories");
      await dispatch("fetchAllSubCategories");
    } catch (e) {}
  },
};
