import axios from "axios";
import { apiWrapperGenerator } from "./lib";
import deephowApiServerWrapper from "@/server/api-server";
import services from "@/constants/env/services";
import { IS_PROD_OR_SANDBOX } from "@/constants/env";

// for private use user token to verify
export const fetchWorkflowPlayerData = ({ workflowId, withAddons }) =>
  deephowApiServerWrapper("get", `/v1/player/workflow?workflowId=${workflowId}&withAddons=${withAddons}`);

// public player
const publicPlayerServer = axios.create({
  baseURL: services.deephowApiServer,
});
const devServerToken =
  "3mJ1lNjGEu0oBIwxzr!8Yq0rE/gJMGa=T47GkE27XLuZ=kGzEVosYOvKcBa1b=-Ko8JWWQvDH=!n-aSDKv3Qm37apN6ZQs8PydwVxOsYA6480qPeN5T-V-XxFrDv41Rt";
const prodServerToken =
  "mPQDQNcliYwWUEdBeBXbfG?Jqnbt5p7dW1RLe/qDNGzvnoWoqIpAFm5Ug-zNANFYjupS0lDm34xfxJnHPZ=pfcBOjQqqj9VEfnIbc-QOZfge2p7kH6dp5V09605ttNIlBM!t5wdMQGoh=9GhQH=6Po?za7rs!ENwvIU/eS80-V3eKg6YYnzqk5CD7CI7BKmiC42Uh2X6tvmY?vvIhMIgRZA/pSqBKrcNMjRGUuBHZU6Kiwjl8KNH!4R0x34NIqmS";
publicPlayerServer.interceptors.request.use(
  (config) => {
    config.headers["x-server-token"] = IS_PROD_OR_SANDBOX ? prodServerToken : devServerToken;
    return config;
  },
  (error) => Promise.resolve(error)
);
const publicPlayerWrapper = apiWrapperGenerator(publicPlayerServer);

export const fetchPublicWorkflowPlayerData = ({ workflowId, withAddons }) =>
  publicPlayerWrapper("get", `/v1/player/workflow?workflowId=${workflowId}&withAddons=${withAddons}`);
