<template>
  <d-empty-block v-if="showNoResult">
    <template slot="icon">
      <icon-base class="mb-2" width="90" height="90">
        <d-icon-no-result />
      </icon-base>
    </template>
    <template slot="title">
      <p :class="{ 'dark-text': light }">
        {{ $t("explorer.noSearchResult") }}
      </p>
      <p v-if="!notSearch" class="please-try-searchin">({{ $t("explorer.trySearchAgain") }})</p>
    </template>
  </d-empty-block>
</template>
<script>
import DIconNoResult from "../icons/colored/DIconNoResult.vue";
export default {
  name: "DNoResult",
  props: {
    showNoResult: Boolean,
    notSearch: Boolean,
    light: Boolean,
  },
  components: {
    DIconNoResult,
  },
};
</script>

<style scoped>
.dark-text {
  color: #3f3f3f;
}
.please-try-searchin {
  color: #8d909f;
  font-size: 16px;
  text-align: center;
}
</style>
