<template>
  <div class="NotificationsList" @click="clickBackgroundToClose">
    <!-- desktop only: darken bg-->
    <div v-if="!IS_MOBILE" class="NotificationsList__darkBg"></div>

    <!-- Notifications List -->
    <div class="NotificationsList__desktop" :class="{ NotificationsList__mobile: IS_MOBILE }" @click.stop>
      <div v-if="isLoading" class="NotificationsList__text">{{ $t("player.loading") }}</div>
      <div v-else-if="notifications.length === 0" class="NotificationsList__text">
        {{ $t("notification.noNotificationsMsg") }}
      </div>
      <template v-if="notifications.length > 0">
        <div v-for="(notification, index) in notifications" :key="index">
          <notification-card
            :notification="notification"
            :index="index"
            :isMobileDevice="IS_MOBILE"
            @close-notifications="$emit('close-notifications')"
          />
        </div>
      </template>
    </div>

    <!-- mobile only: close btn -->
    <div v-if="IS_MOBILE" class="NotificationsList__mobileClose">
      <button @click="closeNotification" class="NotificationsList__mobileClose--btn">
        <font-awesome-icon :icon="['far', 'chevron-left']" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import MixinDB from "@/components/MixinDB.vue";
import NotificationCard from "@/components/DNotification/card/NotificationCard.vue";
import { IS_MOBILE } from "@/constants/device-version";
export default {
  name: "NotificationsList",
  mixins: [MixinDB],
  data() {
    return {
      IS_MOBILE,
    };
  },
  computed: {
    ...mapState("notification", ["notifications", "isLoading"]),
  },
  components: {
    NotificationCard,
  },
  methods: {
    closeNotification() {
      this.$emit("close-notifications");
    },
    clickBackgroundToClose() {
      if (IS_MOBILE) return;
      this.closeNotification();
    },
  },
};
</script>

<style lang="scss" scoped>
$mobileCloseBar: 64px;
$background-color: var(--dGrey2-color);
.NotificationsList {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: var(--z-index-dialog);

  // triangle
  &::before {
    content: "";
    position: absolute;
    top: 78px;
    right: 111px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent $background-color transparent;
  }
  &__desktop {
    position: absolute;
    top: 88px;
    right: 26px;
    width: 350px;
    max-height: calc(100% - 120px);
    overflow-y: auto;
    overflow-x: hidden;
    background: $background-color;
    border-radius: 8px;
    @include scrollbar();
  }
  &__mobile {
    z-index: 0;
    top: $mobileCloseBar;
    left: 0;
    height: calc(100% - #{$mobileCloseBar});
    max-height: unset;
    width: 100%;
    border-radius: 0px;
    padding: 20px 20px 64px 20px;
    background: $background-color;
  }

  &__darkBg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    pointer-events: none;
  }
  &__text {
    padding: 16px 0;
    color: var(--dGrey6-color);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
  &__mobileClose {
    position: absolute;
    height: $mobileCloseBar;
    width: 100%;
    top: 0;
    padding: 24px 0 0 0;
    background: $background-color;
    display: flex;
    &--btn {
      padding: 0 10px;
      color: var(--dGrey6-color);
    }
  }
}
</style>
