import { BACKEND_TYPE } from "@/constants/env";

const DOWNLOAD_FILES_BY_BACKEND = {
  global: {
    dhDictionary: "https://storage.googleapis.com/deephow-public/dh-dictionary.json",
    dhSettings: "https://storage.googleapis.com/deephow-public/dh-settings.json",
    windowsCapture: "https://storage.googleapis.com/deephow-public/app/deephow_windows_capture.msi",
    windowsCaptureZip: "https://storage.googleapis.com/deephow-public/app/deephow_windows_capture.zip",
    privacyPolicyEn: "https://storage.googleapis.com/deephow-public/policies/deephow-com-privacy-policy.html",
    privacyPolicyZh: "https://public.deephow.net/policies/deephow-privacy-policy.html",
    privacyPolicyPDF: "https://storage.googleapis.com/deephow-public/policies/DCP.pdf",
    userAgreementZh: "https://public.deephow.net/policies/deephow-user-agreement.html",
    abiUploadPolicyGlobal:
      "https://storage.googleapis.com/deephow-public/policies/deephow-abi-photograph-video-recording-privacy-statement.html",
  },
  china: {
    dhDictionary: "https://public.deephow.net/dh-dictionary.json",
    dhSettings: "https://public.deephow.net/dh-settings.json",
    windowsCapture: "https://public.deephow.net/apps/DeephowChinaInstaller.msi",
    windowsCaptureZip: "https://public.deephow.net/apps/DeephowChinaCapture.zip",
    privacyPolicyEn: "",
    privacyPolicyZh: "https://public.deephow.net/policies/deephow-privacy-policy.html",
    privacyPolicyPDF: "https://public.deephow.net/policies/DCP.pdf",
    userAgreementZh: "https://public.deephow.net/policies/deephow-user-agreement.html",
    abiUploadPolicyChina:
      "https://public.deephow.net/policies/deephow-abi-photograph-video-recording-privacy-statement.html",
  },
};

const DOWNLOAD_FILES = DOWNLOAD_FILES_BY_BACKEND[BACKEND_TYPE];

export default DOWNLOAD_FILES;
