import axios from "axios";
import services from "@/constants/env/services";
import { apiWrapperGenerator } from "./lib";

// NOTICE: this API is not support china version, so the value is null
const azureSpeechServer = axios.create({
  baseURL: services.azureSpeechServer,
});

azureSpeechServer.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.resolve(error)
);
const apiWrapper = apiWrapperGenerator(azureSpeechServer);

export const postFetchVoiceOutputSpeech = (
  payload = {
    oid,
    idToken,
    textList,
  }
) => apiWrapper("post", "/text-to-speech", payload);
