export const namespaced = true;
import { QUESTION_TYPES } from "@/constants/editorStatus";
import { isObject } from "lodash-es";
import { getQuiz } from "@/server/quiz-server";
import { getWokrflowQuiz, postGenerateQuizByAI } from "@/server/workflow-server";

import { putEditedQuiz, postCreateQuiz } from "@/server/quiz-server";
import { createSkillQuiz } from "@/server/skill-server";

export const state = {
  isQuizPublished: false,
  questionStatus: "completed", // ‘pending’ | ‘processing’ | ‘completed’ | ‘failure’
  questions: [],
  questionId: "",
  selectedQuestionsIds: [],
  isUserChangeLocalData: false,
  isAllQuestionsReadyOnly: false,
};

export const mutations = {
  DELETE_QUESTION_BY_ID(state, id) {
    const index = state.questions.findIndex((question) => question.id === id);
    if (index === -1) {
      return;
    }
    const question = state.questions[index];
    state.selectedQuestionsIds = state.selectedQuestionsIds.filter((id) => id !== question.id);
    state.questions.splice(index, 1);
  },
  SELECT_QUESTION_BY_ID(state, id) {
    const targetIndex = state.selectedQuestionsIds.indexOf(id);
    if (targetIndex !== -1) {
      state.selectedQuestionsIds.splice(targetIndex, 1);
    } else {
      state.selectedQuestionsIds.push(id);
    }
  },
  ADD_QUESTION_TEMPLATE(state, template) {
    state.questions.push(template);
  },
  CLEAR_SELECTED_QUESTION_IDS(state) {
    state.selectedQuestionsIds = [];
  },
  MUTATE_QUESTIONS(state, questions) {
    state.questions = questions;
  },
  MUTATE_QUESTION_BY_INDEX(state, { index, key, value }) {
    const question = state.questions[index];
    state.questions.splice(index, 1, { ...question, [key]: value });
  },
  MUTATE_QUESTION_ID(state, questionId) {
    state.questionId = questionId;
  },
  MUTATE_IS_USER_CHANGE_LOCAL_DATA(state, isChange) {
    state.isUserChangeLocalData = isChange;
  },
  MUTATE_QUESTION_STATUS(state, status) {
    state.questionStatus = status;
  },
  MUTATE_IS_ALL_QUESTIONS_READY_ONLY(state, readyOnly) {
    state.isAllQuestionsReadyOnly = readyOnly;
  },
  RESET_STATE(state) {
    state.questions = [];
    state.selectedQuestionsIds = [];
    state.questionId = "";
    state.isUserChangeLocalData = false;
    state.questionStatus = "completed";
    state.isQuizPublished = false;
  },
  MUTATE_IS_QUIZ_PUBLISHED(state, isQuizPublished) {
    state.isQuizPublished = isQuizPublished;
  },
};

export const getters = {
  getter_numbers_of_question_selected(state) {
    return state.selectedQuestionsIds.length;
  },
  getter_is_disabled_header_button(state) {
    return !state.isUserChangeLocalData;
  },
  getter_is_ai_processing(state) {
    return ["processing", "pending"].includes(state.questionStatus);
  },
};

export const actions = {
  deleteQuestionById({ commit }, id) {
    commit("DELETE_QUESTION_BY_ID", id);
    commit("MUTATE_IS_USER_CHANGE_LOCAL_DATA", true);
  },
  deleteAllSelectedQuestions({ state, commit }) {
    state.questions = state.questions.filter(({ id }) => !state.selectedQuestionsIds.includes(id));
    commit("CLEAR_SELECTED_QUESTION_IDS");
    commit("MUTATE_IS_USER_CHANGE_LOCAL_DATA", true);
  },
  deselectedAllQuestions({ commit }) {
    commit("CLEAR_SELECTED_QUESTION_IDS");
  },
  selectedQuestionById({ commit }, id) {
    commit("SELECT_QUESTION_BY_ID", id);
  },
  addQuestionTemplateByType({ commit, state }, type) {
    const template = {
      type,
      title: "",
      correctAnswer: "A",
      id: Math.random().toString(16).slice(2),
      referenceSource: "User manually adds questions",
      options: {
        A: type === QUESTION_TYPES.TRUE_OR_FALSE ? "true" : "",
        B: type === QUESTION_TYPES.TRUE_OR_FALSE ? "false" : "",
        ...(type === QUESTION_TYPES.SINGLE_SELECTION && { C: "", D: "" }),
      },
    };
    commit("ADD_QUESTION_TEMPLATE", template);
    commit("MUTATE_IS_USER_CHANGE_LOCAL_DATA", true);
  },
  async fetchQuizFromDb({ commit }, { id, quizBy = "workflow" }) {
    try {
      const getQuizFunc = quizBy === "workflow" ? getWokrflowQuiz : getQuiz;
      const { data, ok } = await getQuizFunc(id);
      if (ok && data.item) {
        const questionWithUniqueId = data.item.questions.map((question) => ({
          ...question,
          id: Math.random().toString(16).slice(2),
        }));
        commit("MUTATE_QUESTIONS", questionWithUniqueId);
        commit("MUTATE_QUESTION_ID", data.item.id);
        commit("MUTATE_IS_USER_CHANGE_LOCAL_DATA", false);
        commit("MUTATE_QUESTION_STATUS", data.item.status);
        commit("MUTATE_IS_QUIZ_PUBLISHED", data.item.published);
        commit("MUTATE_IS_ALL_QUESTIONS_READY_ONLY", data.item.published);
        commit("CLEAR_SELECTED_QUESTION_IDS");
      }
    } catch (error) {
      console.error(error);
    }
  },
  storeQuizData({ commit }, questions) {
    commit("MUTATE_QUESTIONS", questions);
    commit("MUTATE_IS_USER_CHANGE_LOCAL_DATA", true);
  },
  updateLocalQuizById({ commit, state }, { id, key, value }) {
    const index = state.questions.findIndex((question) => question.id === id);
    if (index === -1) {
      console.error(`Cannot find question by this id (${id})`);
      return;
    }
    commit("MUTATE_QUESTION_BY_INDEX", { index, key, value });
    commit("MUTATE_IS_USER_CHANGE_LOCAL_DATA", true);
  },
  resetQuizData({ commit }) {
    commit("RESET_STATE");
  },
  checkIsQuizEmpty({ state }) {
    return state.questions.some((question) => {
      const { title, options, correctAnswer, referenceSource } = question;
      return (
        !title ||
        !correctAnswer ||
        !referenceSource ||
        !isObject(options) ||
        Object.values(options).some((option) => !option)
      );
    });
  },
  async updateQuizByQuestionId({ state, commit }, { isPublished = false, workflowId, playlistId }) {
    if (!state.questionId) {
      const [createFunc, id] = workflowId ? [postCreateQuiz, workflowId] : [createSkillQuiz, playlistId];
      const { ok, data, errorMessage } = await createFunc({
        id,
        questions: state.questions.map(({ id, referenceSource, ...rest }) => rest),
      });
      if (!ok) {
        console.error(errorMessage);
        return { ok, errorMessage };
      }
      commit("MUTATE_QUESTION_ID", data.item.id);
    }
    const result = await putEditedQuiz({
      questionId: state.questionId,
      published: isPublished,
      questions: state.questions.map(({ id, ...rest }) => rest),
    });
    return { ok: result.ok, quizId: state.questionId };
  },
  async generateQuizByAI({ commit }, { workflowId, numberOfQuestions, questionTypes }) {
    try {
      const { data, ok, errorMessage } = await postGenerateQuizByAI({ workflowId, numberOfQuestions, questionTypes });
      if (!ok) {
        console.error(errorMessage);
        return;
      }
      commit("MUTATE_QUESTION_STATUS", data.item.status);
    } catch (error) {
      console.error(error);
    }
  },
};
