// type BackendType = 'china' | 'global'
// type Env = 'dev' | 'prod' | 'sandbox'

export const BACKEND_TYPE = process.env.VUE_APP_BACKEND_TYPE;
export const ENV = process.env.VUE_APP_API_ENV;

export const IS_CHINA = BACKEND_TYPE === "china";
export const IS_DEV = ENV === "dev";
export const IS_PROD = ENV === "prod";
export const IS_SANDBOX = ENV === "sandbox";
export const IS_PROD_OR_SANDBOX = IS_PROD || IS_SANDBOX; // use same db source
export const SETTING_ENV = IS_PROD_OR_SANDBOX ? "prod" : "dev";

export const BACKEND_SERVER_TYPE = IS_CHINA ? "ali" : "firebase";

export const MATOMO_CONFIG = {
  apiHost: process.env.VUE_APP_MATAMO_API_HOST,
  siteId: process.env.VUE_APP_MATAMO_SITE_ID,
  containerId: process.env.VUE_APP_CONTAINER_ID,
};

export const BASE_URL = window.location.origin;
