import { ROUTE_NAME, ROUTE_PATH } from "../route";

export const MAVEN_ROUTE_NAME = [ROUTE_NAME.MAVEN];
export const MavenRoute = [
  {
    path: ROUTE_PATH.MAVEN,
    name: ROUTE_NAME.MAVEN,
    props: true,
    component: () => import("@/components/DMaven2.0/Maven.vue"),
    meta: { auth: true },
  },
];
export default MavenRoute;
