export const CANVAS_HEIGHT = 380;
export const CANVAS_WIDTH = 676;

export const SHAPE = {
  CIRCLE: "circle",
  RECTANGLE: "rectangle",
  TRIANGLE: "triangle",
};

export const STROKE_TYPE = {
  NONE: "none",
  SOLID: "solid",
  DASHED: "dashed",
};

export const ANNOTATION_TYPE = {
  PEN: "path",
  ARROW: "arrow",
  TEXT: "i-text",
  SELECT: "select",
  IMAGE: "image",
  SHAPE: "shape",
  BLUR: "blur",
};

export const ANNOTATION_ICON_MAP = {
  [ANNOTATION_TYPE.SELECT]: ["far", "arrow-pointer"],
  [ANNOTATION_TYPE.TEXT]: ["far", "text"],
  [ANNOTATION_TYPE.PEN]: ["far", "pencil"],
  [ANNOTATION_TYPE.ARROW]: ["far", "up-right"],
  [ANNOTATION_TYPE.IMAGE]: ["far", "image"],
  [ANNOTATION_TYPE.SHAPE]: ["far", "square"],
  [ANNOTATION_TYPE.BLUR]: ["far", "droplet"],
};

export const ANNOTATION_COLOR = {
  TRANSPARENT: "transparent",
  PRIMARY: "#4689f4",
  WHITE: "#ffffff",
  BLACK: "#000000",
  RED: "#ff0600",
  GREEN: "#00ff00",
  YELLOW: "#ffff00",
};

export const ANNOTATION_COLORS = [
  ANNOTATION_COLOR.PRIMARY,
  ANNOTATION_COLOR.WHITE,
  ANNOTATION_COLOR.BLACK,
  ANNOTATION_COLOR.RED,
  ANNOTATION_COLOR.GREEN,
  ANNOTATION_COLOR.YELLOW,
];

export const ANNOTATION_LINE_WIDTH = {
  THIN: "THIN",
  BOLD: "BOLD",
  NORMAL: "NORMAL",
};

export const ANNOTATION_LINE_WIDTH_VALUE = {
  [ANNOTATION_LINE_WIDTH.THIN]: 4,
  [ANNOTATION_LINE_WIDTH.NORMAL]: 6,
  [ANNOTATION_LINE_WIDTH.BOLD]: 8,
};

export const ANNOTATION_TEXT_STYLE = {
  BOLD: "BOLD",
  ITALIC: "ITALIC",
};
