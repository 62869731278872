import { AUTHING_APP_ID } from "@/constants/env/authing";
import { AuthenticationClient } from "authing-js-sdk";
import eventBus from "./eventBus.js";
import Cookies from "js-cookie";
import URL from "url";

const authClient = new AuthenticationClient({
  appId: AUTHING_APP_ID,
});

// Doc: https://docs.authing.cn/v2/reference/error-code.html
const AUTHING_ERROR_CODE = {
  SERVER_BUSY: 1000,
};

var alertLogout = false;

function convertUser(user) {
  if (user) {
    user["uid"] = user.id;
    user["displayName"] = user.name;
  }

  return user;
}

function convertCredential(user) {
  let data = {};

  data["credential"] = {
    idToken: user.token,
    accessToken: "",
  };

  return data;
}

const forceRedirectToReLogin = (msg) => {
  if (!alertLogout) {
    alertLogout = true;
    alert(msg);
  }
  location.href = "/";
  return;
};

function checkUserLogout(user) {
  if (!authApi.currentUser.uid) {
    // no user login
    return;
  }
  // user already login, check user status in authing
  if (!user && URL.parse(location.href).path !== "/") {
    forceRedirectToReLogin("此帐号已从其他设备登出，请重新登录！");
    throw "User logouted";
  }
}

const authApi = {
  currentUser: {
    getIdToken: function (bool) {
      return authApi.getIdToken(bool);
    },
    displayName: "",
    email: "",
    uid: "",
  },
  onAuthStateChanged: function (callback) {
    authApi
      .getCurrentUser()
      .then((user) => {
        callback(user);
      })
      .catch((error) => {
        console.log(error);
        callback(null);
      });

    eventBus.subscribe("onAuthStateChanged", callback);
  },
  getCurrentUser: function () {
    return new Promise(function (resolve) {
      let cookieUserInfo = Cookies.get("userInfo");
      if (cookieUserInfo) {
        return resolve(JSON.parse(cookieUserInfo));
      }

      authClient
        .getCurrentUser()
        .then((user) => {
          checkUserLogout(user);
          if (!user) {
            return resolve(null);
          }

          Cookies.set("userInfo", convertUser(user), { expires: new Date(user.tokenExpiredAt) });
          resolve(convertUser(user));
        })
        .catch((error) => {
          console.log(error);
          resolve(null);
        });
    });
  },
  getIdToken: async function (bool = false) {
    try {
      if (bool) {
        console.log("refreshToken ...");
        const tokenInfo = await authClient.refreshToken();
        Cookies.set("idToken", tokenInfo.token, { expires: new Date(tokenInfo.exp * 1000) });
        console.log("refreshToken finished");
      }

      const cookieIdToken = Cookies.get("idToken");
      if (cookieIdToken) {
        return cookieIdToken;
      }

      const user = await authClient.getCurrentUser();
      if (!user) {
        throw "authClient.getCurrentUser failed - user is null";
      }
      Cookies.set("idToken", user.token, { expires: new Date(user.tokenExpiredAt) });
      return user.token;
    } catch (e) {
      if (e.code === AUTHING_ERROR_CODE.SERVER_BUSY) {
        return "";
      }
      console.error("authApi.getIdToken failed:", e);
      forceRedirectToReLogin("登录失效，请重新登录");
      Cookies.remove("userInfo");
      Cookies.remove("idToken");
      return "";
    }
  },
  loginByUsername: function (name, password) {
    return authClient.loginByUsername(name, password);
  },
  loginByEmail: function (email, password) {
    return new Promise(function (resolve, reject) {
      authClient
        .loginByEmail(email, password)
        .then((user) => {
          Cookies.remove("userInfo");
          Cookies.remove("idToken");
          resolve(convertUser(user));

          eventBus.publish("onAuthStateChanged", convertUser(user));
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  logout: async function () {
    try {
      // force refresh to prevent sharing account side effect: When user A logout then the other users should logout, too.
      await authClient.refreshToken();
      await authClient.logout();
    } catch (e) {
      // skip authClient logout fail.
    }
    Cookies.remove("userInfo");
    Cookies.remove("idToken");
    return;
  },
  loginWithPopup: function (protocol, identifier) {
    return new Promise(function (resolve, reject) {
      authClient.enterprise.authorize(protocol, identifier, {
        onSuccess: (user) => {
          Cookies.remove("userInfo");
          Cookies.remove("idToken");
          resolve(convertCredential(user));

          //eventBus.publish('onAuthStateChanged', user);
        },
        onError: (code, message) => {
          console.log(code, message);
          reject(error);
        },
        position: {
          w: 480,
          h: 480,
        },
      });
    });
  },
};

export default authApi;
