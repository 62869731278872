<template>
  <div class="DPageToolbar">
    <div class="DPageToolbar__tools">
      <d-search-input
        v-if="showSearch"
        v-model="keyword"
        :placeholder="searchPlaceholder"
        @search="onSearchKeyword"
        allowClear
        :disabled="searchDisabled"
      />

      <slot name="tools-left" />
    </div>
    <div class="DPageToolbar__tools">
      <div v-if="canChangeView" class="DPageToolbar__viewModeButtonList">
        <d-icon-button
          v-for="modeButton in viewModeList"
          :key="modeButton.mode"
          :active="viewMode === modeButton.mode"
          @click="toggleViewMode(modeButton.mode)"
        >
          <font-awesome-icon :icon="modeButton.icon" />
        </d-icon-button>
      </div>
      <slot name="tools-right" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DPageToolbar",
  mixins: [],
  props: {
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
    searchDisabled: {
      type: Boolean,
      default: false,
    },
    defaultView: {
      type: String, // "card" | "list"
      default: "card",
    },
    canChangeView: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      keyword: "",
      viewModeList: [
        { mode: "card", icon: ["far", "grid-2"] },
        { mode: "list", icon: ["far", "list"] },
      ],
      viewMode: this.defaultView,
    };
  },
  methods: {
    onSearchKeyword() {
      this.$emit("search", this.keyword);
    },
    toggleViewMode(mode) {
      this.viewMode = mode;
      this.$emit("change-view", this.viewMode);
    },
  },
};
</script>

<style lang="scss" scoped>
.DPageToolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 24px;
  &__tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 24px;
  }
}
.DPageToolbar__viewModeButtonList {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}
</style>
