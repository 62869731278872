<template>
  <div class="ReactionsInfo">
    <div
      v-for="(reaction, idx) in displayReactions"
      :key="reaction.id"
      class="ReactionsInfo__icon"
      :style="{ 'z-index': -idx }"
    >
      <img :src="reaction.icon" :alt="reaction.name" height="14" />
    </div>
    <span class="ml-2 text-no-wrap text-truncate">{{ $tc("player.numColleaguesReact", usersNum) }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ReactionsInfo",
  props: {
    reactions: Array,
    usersNum: Number,
  },
  components: {},
  computed: {
    ...mapState("workflowReactions", ["reactionSet"]),
    displayReactions() {
      let displayItems = [];
      this.reactionSet.forEach((item) => {
        const foundItem = this.reactions.find((reaction) => reaction === item.id);
        if (foundItem) {
          displayItems.push(item);
        }
      });
      return displayItems;
    },
  },
};
</script>
