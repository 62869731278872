<template>
  <d-dialog class="UserPrivacyPolicy" width="80%" :showFooter="false" @close="close">
    <div class="UserPrivacyPolicy__content">
      <div class="content" v-html="policyText"></div>
    </div>
  </d-dialog>
</template>

<script>
import { loggingError } from "@/server/error-log-server.js";
import downloadFiles from "@/constants/env/downloadFiles";

export default {
  name: "UserPrivacyPolicyPopup",
  props: {
    popupContent: String,
  },

  data() {
    return {
      policyText: "",
    };
  },
  mounted() {
    this.getPolicyContent();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getPolicyContent() {
      let url;
      switch (this.popupContent) {
        case "user-agreement-zh":
          url = downloadFiles.userAgreementZh;
          break;
        case "privacy-policy-zh":
          url = downloadFiles.privacyPolicyZh;
          break;
        case "privacy-policy-en":
          url = downloadFiles.privacyPolicyEn;
          break;
        case "abi-upload-policy-global":
          url = downloadFiles.abiUploadPolicyGlobal;
          break;
        case "abi-upload-policy-china":
          url = downloadFiles.abiUploadPolicyChina;
          break;
        default:
          url = downloadFiles.privacyPolicyEn;
      }
      const self = this;
      fetch(url)
        .then(function (response) {
          // The API call was successful!
          return response.text();
        })
        .then(function (html) {
          //get html string
          self.policyText = html;
        })
        .catch(function (err) {
          // There was an error
          self.policyText = "Failed to load Content.";
          loggingError(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.UserPrivacyPolicy {
  &__content {
    padding-bottom: 24px;
  }
}
.content {
  position: relative;
  color: var(--dGrey6-color);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: left;
}
.content ::v-deep p {
  margin: 0;
}
.content ::v-deep div {
  background-color: #17181a;
  position: relative;
  width: 100% !important;
  margin: 0 !important;
}
.content ::v-deep div p {
  padding: 0;
  width: 100%;
  margin-bottom: 18px;
  font-size: 16px;
  line-height: 28px;
}
.content ::v-deep div p span {
  font-size: 16px;
  line-height: 28px;
}
.content ::v-deep .p0 {
  color: var(--brand-white);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  left: 0;
  text-align: left;
}
.content ::v-deep strong {
  color: var(--brand-white);
  font-size: 24px;
  line-height: 32px;
}
</style>
