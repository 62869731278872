/*
    notification object spec by type:
    https://docs.google.com/spreadsheets/d/1vEPUVcZHQLA2iRMnet2eX5BalEtofPQJ0phSurgwtaA/edit#gid=0
*/

export const NOTIFY_CARD_TYPE = {
  WORKFLOW: "WORKFLOW",
  SKILL: "SKILL",
  SKILL_SYSTEM: "SKILL_SYSTEM",
  SKILL_QUIZ: "SKILL_QUIZ",
};

export const NOTIFY_TYPE = {
  WORKFLOW_REACTION: "workflow-reaction",
  SHARE_WORKFLOW: "share-workflow",
  REVIEW_WORKFLOW: "review-workflow",
  ASSIGN_SKILL: "assign-skill",
  PUBLISH_SKILL: "publish-skill",
  UNPUBLISH_SKILL: "unpublish-skill",
  REMIND_SKILL: "remind-skill",
  ASSIGN_SKILL_DUE: "assign-skill-due",
  REMIND_SKILL_OVERDUE: "remind-skill-overdue",
  REMIND_SKILL_LEFT_DAYS: "remind-skill-left-days",
  USER_WORKFLOW_RETRAIN: "user-workflow-retrain",
};

export const NOTIFY_CARD_GROUP = {
  [NOTIFY_CARD_TYPE.WORKFLOW]: [
    NOTIFY_TYPE.SHARE_WORKFLOW,
    NOTIFY_TYPE.REVIEW_WORKFLOW,
    NOTIFY_TYPE.WORKFLOW_REACTION,
    NOTIFY_TYPE.USER_WORKFLOW_RETRAIN,
  ],
  [NOTIFY_CARD_TYPE.SKILL]: [
    NOTIFY_TYPE.ASSIGN_SKILL,
    NOTIFY_TYPE.PUBLISH_SKILL,
    NOTIFY_TYPE.UNPUBLISH_SKILL,
    NOTIFY_TYPE.REMIND_SKILL,
    NOTIFY_TYPE.ASSIGN_SKILL_DUE,
  ],
  [NOTIFY_CARD_TYPE.SKILL_SYSTEM]: [NOTIFY_TYPE.REMIND_SKILL_OVERDUE, NOTIFY_TYPE.REMIND_SKILL_LEFT_DAYS],
};
