<template>
  <section>
    <div
      class="UserNotifications"
      :class="{ UserNotifications__active: showNotification }"
      @click="clickToOpenNotifications"
    >
      <template v-if="newNotificationsNum > 0">
        <div v-if="newNotificationsNum <= 99" class="UserNotifications__redDot">
          {{ newNotificationsNum }}
        </div>
        <div v-else class="UserNotifications__redDotLg">99+</div>
      </template>
      <font-awesome-icon class="UserNotifications__button" :icon="['far', 'bell']" />
    </div>

    <notifications-list v-if="showNotification" @close-notifications="showNotification = false" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import NotificationsList from "@/components/DNotification/NotificationsList.vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";

export default {
  name: "UserNotifications",
  mixins: [MixinDB, MixinUser],
  components: {
    NotificationsList,
  },
  props: {
    isMobileDevice: {
      type: Boolean,
    },
    isTabletDevice: {
      type: Boolean,
    },
  },
  data() {
    return {
      showNotification: false,
    };
  },
  computed: {
    ...mapGetters("notification", ["getters_new_notifications"]),
    ...mapGetters("auth", ["getter_user_id", "getter_user_organization"]),
    newNotificationsNum() {
      return this.getters_new_notifications.length;
    },
  },

  methods: {
    ...mapActions("notification", ["fetchNotificationsFromFirestore", "updateAllCounterInNotifications"]),
    ...mapActions("workflowReactions", ["storeReactionSet"]),
    async setData() {
      await this.fetchNotificationsFromFirestore({
        organization: this.getter_user_organization,
        uid: this.getter_user_id,
      });
    },
    clickToOpenNotifications() {
      if (this.showNotification) {
        this.showNotification = false;
        return;
      }
      this.openNotifications();
    },
    async openNotifications() {
      this.showNotification = true;
      // change all counters in db
      // TODO: use API to change the counters in db
      const promises = [];
      for (const notification of this.getters_new_notifications) {
        for (const user of notification.activity) {
          if (user && user.counter && user.notificationId) {
            const promise_fn = async () => {
              const notificationId = user.notificationId;
              await this.updateDocument("notifications", notificationId, {
                counter: false,
              }).catch((err) => {
                console.warn("Faild to update notification counter", err);
                return null;
              });
            };
            promises.push(promise_fn());
          }
        }
      }
      await Promise.all(promises);

      // change all counters in vuex
      this.updateAllCounterInNotifications();
    },
  },

  created() {
    this.setData();
    this.storeReactionSet();
  },
};
</script>

<style lang="scss" scoped>
.UserNotifications {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  color: var(--dGrey6-color);

  &__active {
    background: rgba(196, 196, 196, 0.1);
    color: var(--brand-white);
    z-index: calc(var(--z-index-dialog) + var(--z-index-above));
  }
  &__button {
    height: 20px;
    width: 20px;
  }
  &__redDot {
    position: absolute;
    top: 3px;
    right: 2px;
    height: 16px;
    width: 16px;
    background-color: var(--danger-color);
    border-radius: 50%;
    color: var(--brand-white);
    font-size: 12px;
    line-height: 16px;
  }
  &__redDotLg {
    position: absolute;
    top: 1px;
    right: -2px;
    height: 22px;
    width: 22px;
    padding-top: 3px;
    transform: scale(0.8);
    background-color: var(--danger-color);
    border-radius: 50%;
    color: var(--brand-white);
    font-size: 12px;
    line-height: 16px;
  }
  & :hover {
    color: var(--brand-white);
  }
}
</style>
