import dayjs from "dayjs";

export const BE_DATE_FORMAT = "YYYY-MM-DD";
export const getHourMinuteSecond = (sec) => {
  if (!sec || sec === Infinity) return "0:00:00";
  const hours = Math.floor(sec / 3600);
  sec %= 3600;
  const minutes = Math.floor(sec / 60);
  const seconds = Math.floor(sec - minutes * 60);
  const padding = minutes < 10 ? "0" : "";
  const paddingSec = seconds < 10 ? "0" : "";
  return hours + ":" + padding + minutes + ":" + paddingSec + seconds;
};

export const getSecondsFromTimeString = (timeString) => {
  if (!timeString) return 0;
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

/** Output:
 *  default return timestamp (ms)
 *  if provide dayjs "format", return the required format */
export const formatDate = (date, format) => {
  if (!format) return dayjs(date).valueOf();
  return dayjs(date).format(format);
};

export const getDateTimeText = (date) => {
  return dayjs(date).format("MM/DD/YY h:mm A");
};

export const getDateText = (date) => {
  return dayjs(date).format("MM/DD/YYYY");
};

export const getShortDateText = (date) => {
  return dayjs(date).format("MM/DD/YY");
};

export const getAbbMonthDateText = (date) => {
  return dayjs(date).format("DD MMM YYYY, hh:mma");
};

export const getNowUnixTimestamp = () => {
  return dayjs().unix();
};

// 12, September 2024
export const getDateWithFullMonthName = (date) => {
  return dayjs(date).format("DD, MMMM YYYY");
};

/** default is timestamp ms */
export const getToday = (format) => {
  const today = dayjs();

  if (!format) return today.valueOf();
  return today.format(format);
};
