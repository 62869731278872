import deephowApiServerWrapper from "@/server/api-server";

export const fetchSkills = ({ page = 1, size = 500 }) =>
  deephowApiServerWrapper("get", `/v1/skills`, { params: { page, size } });

export const fetchPrerequisiteSkillList = async ({ skillIds }) =>
  deephowApiServerWrapper("get", `/v1/skills`, { params: { skillIds } });

/** Skill - Assessment */
export const createSkillQuiz = async ({ id, questions }) =>
  await deephowApiServerWrapper("post", `/v1/skill/skills/${id}/quiz`, { questions });

export const fetchSkillQuizStats = (id) => deephowApiServerWrapper("get", `/v1/skills/${id}/quiz-stats`);

/** Skill - User Scheduling */
export const fetchSkillTeamUsers = (playlistId, teamId, params) =>
  deephowApiServerWrapper("get", `/v1/skills/${playlistId}/${teamId}/users`, { params });

export const fetchSkillUsers = async (playlistId, params) =>
  deephowApiServerWrapper("get", `/v1/skills/${playlistId}/users`, { params });

export const updateSkillUserScheduling = async (playlistId, params) =>
  deephowApiServerWrapper("patch", `/v1/skills/${playlistId}/members`, params);

/** Playlists (can move to playlist-server) */
export const getPlaylistById = async ({ playlistId }) =>
  await deephowApiServerWrapper("get", `/v1/playlists/${playlistId}`);

export const postPlaylists = async ({ organization, group, playlistTitle }) =>
  await deephowApiServerWrapper("post", "/v1/playlists", { organization, group, playlistTitle });

export const putPlaylistContent = async ({ playlistId, list }) =>
  await deephowApiServerWrapper("put", `/v1/playlists/${playlistId}/content`, { list });

export const putPlaylist = async ({ playlistId, published, playlistTitle, poster }) =>
  await deephowApiServerWrapper("put", `/v1/playlists/${playlistId}`, {
    published,

    playlistTitle,
    ...(poster && { poster }),
  });

/** Skill - User Certificate */
export const fetchSkillCertificationList = async (playlistId, params) =>
  deephowApiServerWrapper("get", `/v1/skills/${playlistId}/certificates`, { params });

export const fetchSkillCertificationListByTeam = async (playlistId, teamId, params) =>
  deephowApiServerWrapper("get", `/v1/skills/${playlistId}/teams/${teamId}/certificates`, { params });

export const certifyUserSkill = async (playlistId, params) =>
  deephowApiServerWrapper("post", `/v1/skills/${playlistId}/user-certification`, params);
