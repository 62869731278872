<template>
  <div v-bind="$attrs" class="DList">
    <slot />
  </div>
</template>

<script>
export default {
  name: "DList",
  components: {},
  mixins: [],
  provide() {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.DList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
