<template>
  <li v-bind="$attrs" class="DDropdownMenuItem" :class="{ active: active, disabled: disabled }" @click="onClick">
    <slot />
  </li>
</template>

<script>
export default {
  name: "DDropdownMenuItem",
  components: {},
  mixins: [],
  inject: [
    "getDropdown", // { disabled:boolean, hideOnClick:boolean, hideDropdown: () => void}
  ],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    dropdown() {
      return this.getDropdown();
    },
  },
  methods: {
    onClick(e) {
      if (this.disabled) return;
      this.$emit("click", e);
      if (this.dropdown?.hideOnClick && this.dropdown?.hideDropdown) {
        this.dropdown.hideDropdown();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.DDropdownMenuItem {
  display: flex;
  cursor: pointer;
  gap: 16px;
  word-break: break-all;
  padding: 8px;
  font-size: 16px;
  line-height: 20px;
  color: var(--brand-white);
  &:hover {
    background-color: rgb(255 255 255 / 0.05);
  }
  &.active {
    background-color: rgb(255 255 255 / 0.05);
    color: var(--brand-blue);
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}
</style>
