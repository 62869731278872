import services from "@/constants/env/services";
import authChoice from "../../auth";

// NOTICE: this API is not support china version, so the value is null
const useFetch = async (method, url, payload, streamingCallback) => {
  const res = await fetch(`${services.chatServer}${url}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      "x-user-token": await authChoice().currentUser.getIdToken(),
    },
    body: JSON.stringify(payload),
  });
  if (res.headers.get("content-type").includes("text/event-stream")) {
    const encode = new TextDecoder("utf-8");
    const reader = res.body.getReader();
    while (true) {
      const { done, value } = await reader.read();
      const text = encode.decode(value);
      if (done) {
        break;
      }
      if (streamingCallback) {
        console.log(text);
        streamingCallback(text);
      }
    }
    return;
  }
  const data = await res.json();
  const { success, message, code } = data;
  if (success) {
    return { code, ok: true, data };
  }
  return { code, ok: false, errorMessage: message, data };
};

//TODO: use apiWrapper
export const createChatMessage = (payload, streamingCallback) =>
  useFetch("post", `/v2/chats`, payload, streamingCallback);

export const sendQuestion = (chatId, payload, streamingCallback) =>
  useFetch("post", `/v2/chats/${chatId}/messages`, payload, streamingCallback);
