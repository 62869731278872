import { ANNOTATION_TYPE, SHAPE } from "@/constants/annotationStatus";
import { uploadToCloudStorageByFileType } from "@/server/upload-server";

export const getUploadedImageById = async (annotations) => {
  try {
    const imageFilesToUpload = await annotations.reduce(async (result, { stepId, elements }) => {
      const updatedResult = await result;
      const elementResults = await Promise.all(
        elements.map(async (element) => {
          if (element.type !== ANNOTATION_TYPE.IMAGE || !element.imageUrl.startsWith("blob:")) return {};

          const blob = await fetch(element.imageUrl).then((res) => res.blob());
          return {
            [element.id]: new File([blob], `${stepId}-${element.id}.jpg`, {
              type: "image/jpeg",
            }),
          };
        })
      );
      return { ...updatedResult, ...Object.assign({}, ...elementResults) };
    }, Promise.resolve({}));

    const uploadedResult = await Promise.all(
      Object.entries(imageFilesToUpload).map(([id, file]) =>
        uploadToCloudStorageByFileType("annotationImage", file, {}).then((url) => ({ id, url }))
      )
    );

    const uploadedImageById = uploadedResult.reduce((acc, { id, url }) => ({ ...acc, [id]: url }), {});
    return { ok: true, uploadedImageById };
  } catch (e) {
    console.error("getUploadedImageById failed", e);
    return { ok: false, uploadedImageById: {} };
  }
};

export const getBlurItems = (items, ratio, currentTime, targetId = null) => {
  return items
    .filter(({ id, type, startTime, endTime }) => {
      if (type !== ANNOTATION_TYPE.BLUR) return false;
      if (targetId === id) return false;
      if (currentTime > endTime || currentTime < startTime) return false;
      return true;
    })
    .map(({ id, width, height, top, left, scaleX = 1, scaleY = 1, shape, radius }) => {
      const shapeStyle =
        shape === SHAPE.CIRCLE
          ? {
              borderRadius: "50%",
              width: `${radius * 2 * ratio * scaleX}px`,
              height: `${radius * 2 * ratio * scaleY}px`,
            }
          : {
              width: `${width * ratio * scaleX}px`,
              height: `${height * ratio * scaleY}px`,
            };
      return {
        id,
        width,
        height,
        style: {
          ...shapeStyle,
          top: `${top * ratio}px`,
          left: `${left * ratio}px`,
        },
      };
    });
};

export const checkNotoSerifFontFamilyLoaded = async () => {
  const promises = [];
  let isLoaded = true;
  try {
    document.fonts.forEach((fontFaceSet) => {
      if (fontFaceSet.family === "Noto Serif" && fontFaceSet.status !== "loaded") {
        isLoaded = false;
        promises.push(fontFaceSet.load().then(({ status }) => status));
      }
    });

    if (isLoaded) {
      return isLoaded;
    }
    const result = await Promise.all(promises);
    isLoaded = result.every((status) => status === "loaded");
    return isLoaded;
  } catch (error) {
    console.log(error);
  }
};
