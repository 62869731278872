<template>
  <section class="user-avatar" :class="{ 'user-avatar-mobile': isMobileDevice }">
    <button class="dh-user-avatar" v-if="getter_user_display_name" @click="$emit('open-user-menu')">
      <d-avatar :size="size" :userId="getter_user_id" :key="getter_user_avatar_change_count" :border="border" />
    </button>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import DAvatar from "@/components/ui_components/DAvatar.vue";

export default {
  name: "UserAvatar",
  components: {
    DAvatar,
  },
  props: {
    isMobileDevice: {
      type: Boolean,
    },
    border: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 36,
    },
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["getter_user_id", "getter_user_display_name", "getter_user_avatar_change_count"]),
  },
};
</script>

<style scoped>
.user-avatar {
  position: relative;
  height: 40px;
  width: 40px;
}
.user-avatar-mobile {
  height: 32px;
  width: 32px;
  margin-right: -6px;
  margin-bottom: -2px;
}
.dh-user-avatar {
  position: relative;
  padding: 0 !important;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
