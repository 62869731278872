import { SHAPE, ANNOTATION_TYPE, ANNOTATION_COLOR } from "@/constants/annotationStatus";
import { getBlurDefaultConfig, getShapeDefaultConfig } from "@/js/annotation/formatToFabric";
import { fabric } from "fabric";

class TextUtils {
  constructor() {
    this.TextConfig = {
      top: 0,
      left: 0,
      config: {},
    };
    this.Fabric = null;
  }

  handleTextMouseDown = (e) => {
    const {
      pointer: { x: left, y: top },
    } = e;
    this.TextConfig.top = top;
    this.TextConfig.left = left;
  };

  handleTextMouseUp = () => {
    this.unbindTextMouseEvent();
    this.Fabric.add(
      new fabric.IText("text", {
        ...this.TextConfig.config,
        type: ANNOTATION_TYPE.TEXT,
        id: Math.random().toString(16).slice(2),
        top: this.TextConfig.top,
        left: this.TextConfig.left,
      })
    );
  };

  bindTextMouseEvent(Fabric, config) {
    this.Fabric = Fabric;
    this.TextConfig.config = config;
    this.unbindTextMouseEvent();
    this.Fabric.on("mouse:down", this.handleTextMouseDown);
    this.Fabric.on("mouse:up", this.handleTextMouseUp);
  }

  unbindTextMouseEvent() {
    this.Fabric.off("mouse:down", this.handleTextMouseDown);
    this.Fabric.off("mouse:up", this.handleTextMouseUp);
  }
}

class BaseDrawingUtils {
  constructor() {
    this.tempFabricItem = null;
    this.isDrawing = false;
    this.mousePoints = {
      start: { x: 0, y: 0 },
      end: { x: 0, y: 0 },
      top: 0,
      left: 0,
    };
    this.config = {};
    this.Fabric = null;
    this.canvasWidth = 0;
    this.canvasHeight = 0;
    this.limitLength = 20;
  }

  drawDraftItem() {
    throw new Error("drawDraftItem method must be implemented by subclasses");
  }

  drawFinalItem() {
    throw new Error("drawFinalItem method must be implemented by subclasses");
  }

  _checkPointBoundaries(pointer) {
    if (pointer.y < 0 || pointer.x < 0) {
      pointer.y = Math.max(pointer.y, 0);
      pointer.x = Math.max(pointer.x, 0);
    }

    const LEFT_MAXIMUM = this.canvasWidth - 5;
    const TOP_MAXIMUM = this.canvasHeight - 5;
    if (pointer.y >= TOP_MAXIMUM || pointer.x >= LEFT_MAXIMUM) {
      pointer.y = Math.min(TOP_MAXIMUM, pointer.y);
      pointer.x = Math.min(LEFT_MAXIMUM, pointer.x);
    }
    return pointer;
  }

  handleMouseDown = (e) => {
    const { pointer } = e;
    this.isDrawing = true;
    this.mousePoints.top = pointer.y;
    this.mousePoints.left = pointer.x;
    this.mousePoints.start = this._checkPointBoundaries(pointer);
  };

  handleMouseMove = (e) => {
    if (!this.isDrawing) return;
    const { pointer } = e;
    this.mousePoints.end = this._checkPointBoundaries(pointer);
    if (this.tempFabricItem != null) {
      this.Fabric.remove(this.tempFabricItem);
    }
    this.drawDraftItem();
  };

  handleMouseUp = async (e) => {
    await Promise.resolve(this.Fabric.remove(this.tempFabricItem));
    const { pointer } = e;
    this.mousePoints.end = this._checkPointBoundaries(pointer);
    const {
      start: { x: startX, y: startY },
      end: { x: endX, y: endY },
    } = this.mousePoints;
    this.isDrawing = false;
    this.tempFabricItem = null;
    if (this.calculateLength(this.mousePoints) > this.limitLength) {
      this.drawFinalItem({ left: Math.min(startX, endX), top: Math.min(startY, endY), startX, startY, endX, endY });
      this.unbindMouseEvent();
    } else {
      console.error(`Shape's length is shorter than ${this.limitLength}, please try again`);
      this.unbindMouseEvent();
      this.bindMouseEvent();
    }
  };

  calculateLength(point) {
    const {
      start: { x: startX, y: startY },
      end: { x: endX, y: endY },
    } = point;
    const x = endX - startX;
    const y = endY - startY;
    return Math.sqrt(x * x + y * y);
  }

  prepareAndBindEvent(Fabric, canvasHeight, canvasWidth, config) {
    this.Fabric = Fabric;
    this.canvasHeight = canvasHeight;
    this.canvasWidth = canvasWidth;
    this.config = config;
    this.bindMouseEvent();
  }

  bindMouseEvent() {
    this.unbindMouseEvent();
    this.Fabric.on("mouse:down", this.handleMouseDown);
    this.Fabric.on("mouse:move", this.handleMouseMove);
    this.Fabric.on("mouse:up", this.handleMouseUp);
  }

  unbindMouseEvent() {
    this.Fabric.off("mouse:down", this.handleMouseDown);
    this.Fabric.off("mouse:move", this.handleMouseMove);
    this.Fabric.off("mouse:up", this.handleMouseUp);
  }
}

class ArrowUtils extends BaseDrawingUtils {
  constructor() {
    super();
  }

  drawDraftItem() {
    this.tempFabricItem = new fabric.Arrow(
      [this.mousePoints.start.x, this.mousePoints.start.y, this.mousePoints.end.x, this.mousePoints.end.y],
      {
        type: ANNOTATION_TYPE.ARROW,
        stroke: ANNOTATION_COLOR.RED,
        strokeWidth: 6,
        isDrawing: this.isDrawing,
      }
    );
    this.Fabric.add(this.tempFabricItem);
  }

  drawFinalItem({ top, left, startX, startY, endX, endY }) {
    this.Fabric.add(
      new fabric.Arrow([startX, startY, endX, endY], {
        ...this.config,
        top,
        left,
        startX,
        startY,
        endX,
        endY,
        id: Math.random().toString(16).slice(2),
      })
    );
  }
}

class ShapeUtils extends BaseDrawingUtils {
  constructor() {
    super();
  }
  drawDraftItem() {
    const {
      start: { x: startX, y: startY },
      end: { x: endX, y: endY },
    } = this.mousePoints;

    this.tempFabricItem = this.generateItem({
      ...this.config,
      top: Math.min(startY, endY),
      left: Math.min(startX, endX),
      width: Math.abs(startX - endX),
      height: Math.abs(startY - endY),
    });
    this.tempFabricItem.isDrawing = true;
    this.Fabric.add(this.tempFabricItem);
  }

  drawFinalItem({ startX, startY, endX, endY }) {
    const item = this.generateItem({
      ...this.config,
      top: Math.min(startY, endY),
      left: Math.min(startX, endX),
      width: Math.abs(startX - endX),
      height: Math.abs(startY - endY),
      id: Math.random().toString(16).slice(2),
    });
    this.Fabric.add(item);
  }

  generateItem(config) {
    const SHAPE_MAP = {
      [SHAPE.CIRCLE]: "Circle",
      [SHAPE.RECTANGLE]: "Rect",
      [SHAPE.TRIANGLE]: "Triangle",
    };
    const fabricConfig =
      config.type === ANNOTATION_TYPE.SHAPE ? getShapeDefaultConfig(config) : getBlurDefaultConfig(config);
    const { shape } = fabricConfig;
    const item = new fabric[SHAPE_MAP[shape]](fabricConfig);
    return item;
  }
}

export class AnnotationUtils {
  constructor() {
    this.Fabric = null;
    this.ArrowUtils = new ArrowUtils();
    this.ShapeUtils = new ShapeUtils();
    this.TextUtils = new TextUtils();
  }

  unbindMouseEvent(type) {
    switch (type) {
      case ANNOTATION_TYPE.TEXT:
        this.TextUtils.unbindTextMouseEvent();
        break;
      case ANNOTATION_TYPE.ARROW:
        this.ArrowUtils.unbindMouseEvent();
        break;
      case ANNOTATION_TYPE.SHAPE:
        this.ShapeUtils.unbindMouseEvent();
        break;
    }
  }
}
