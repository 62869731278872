import { HTTP_CODE } from "@/constants/http-code";
import { loggingError } from "@/server/error-log-server";
import { getUserAssignedSkillDetail } from "@/server/user-server";
export const namespaced = true;

export const SKILL_FETCHED_RESULT_MAP = {
  WAIT: "WAIT",
  UNPUBLISHED: "UNPUBLISHED",
  PUBLISHED: "PUBLISHED",
  NOT_ASSIGNED_SKILL: "NOT_ASSIGNED_SKILL",
};

export const state = {
  isFetching: false,
  isOpenPlayer: false,
  fetchedResult: SKILL_FETCHED_RESULT_MAP.WAIT,
  skillId: null,
  skillData: null,
  targetWorkflowId: null,
};

export const getters = {
  getter_target_skill_id(state) {
    return state.skillId;
  },
};

export const mutations = {
  SET_IS_FETCHING(state, isFetching) {
    state.isFetching = isFetching;
  },
  SET_SKILL_ID(state, payload) {
    state.skillId = payload;
  },
  SET_SKILL_DATA(state, payload) {
    state.skillData = payload;
  },
  SET_FETCHED_RESULT(state, payload) {
    state.fetchedResult = payload;
  },
  SET_TARGET_WORKFLOW_ID(state, workflowId) {
    state.targetWorkflowId = workflowId;
  },
  SET_IS_OPEN_PLAYER(state, payload) {
    state.isOpenPlayer = payload;
  },
  RESET_ALL_STATE(state) {
    state.isOpenPlayer = false;
    state.isFetching = false;
    state.skillData = null;
    state.skillId = null;
    state.targetWorkflowId = null;
    state.fetchedResult = SKILL_FETCHED_RESULT_MAP.WAIT;
  },
};

export const actions = {
  async fetchSkillDetail({ state, commit, rootGetters }, skillId) {
    try {
      const isInit = state.skillId !== skillId;
      commit("SET_IS_FETCHING", isInit);
      commit("SET_SKILL_ID", skillId);
      const params = { userId: rootGetters["auth/getter_user_id"], skillId };
      const { ok, data, code } = await getUserAssignedSkillDetail(params);
      const fetchedResult =
        code === HTTP_CODE.NOT_ASSIGNED_SKILL
          ? SKILL_FETCHED_RESULT_MAP.NOT_ASSIGNED_SKILL
          : ok
          ? SKILL_FETCHED_RESULT_MAP.PUBLISHED
          : SKILL_FETCHED_RESULT_MAP.UNPUBLISHED;
      commit("SET_SKILL_DATA", ok ? data.item : null);
      commit("SET_FETCHED_RESULT", fetchedResult);
    } catch (error) {
      console.error({ error });
      loggingError(error);
    } finally {
      commit("SET_IS_FETCHING", false);
    }
  },
  resetAllState({ commit }) {
    commit("RESET_ALL_STATE");
  },
  setTargetWorkflowId({ commit }, workflowId) {
    commit("SET_TARGET_WORKFLOW_ID", workflowId);
  },
  setIsOpenPlayer({ commit }, payload) {
    commit("SET_IS_OPEN_PLAYER", payload);
  },
};
