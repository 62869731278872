import { ROUTE_NAME, ROUTE_PATH } from "../route.js";

export const ADMIN_ROUTE_NAMES = [
  ROUTE_NAME.ADMIN,
  ROUTE_NAME.ADMIN_WORKSPACE_GROUPS,
  ROUTE_NAME.ADMIN_WORKSPACE_GROUP,
  ROUTE_NAME.ADMIN_WORKSPACES,
  ROUTE_NAME.ADMIN_TEAMS,
  ROUTE_NAME.ADMIN_USERS,
  ROUTE_NAME.ADMIN_BUSINESS,
  ROUTE_NAME.ADMIN_CATEGORIES,
  ROUTE_NAME.ADMIN_CATEGORY_SUCATEGORIES,
  ROUTE_NAME.ADMIN_INTEGRATIONS,
  ROUTE_NAME.ADMIN_SETTING_MAVEN,
  ROUTE_NAME.ADMIN_SETTING_INTERFACE_LANGUAGE,
  ROUTE_NAME.ADMIN_SETTING_PERMISSIONS,
  ROUTE_NAME.ADMIN_SETTING_MASTER_PASSWORD,
  ROUTE_NAME.ADMIN_SETTING_IP_RESTRICTION,
  ROUTE_NAME.ADMIN_SETTING_QR_CODE,
];

const AdminRoute = [
  {
    path: ROUTE_PATH.ADMIN,
    name: ROUTE_NAME.ADMIN,
    component: () => import("@/components/pages/Admin/Admin.vue"),
    meta: { auth: true },
    children: [
      {
        path: ROUTE_PATH.ADMIN_WORKSPACE_GROUPS,
        name: ROUTE_NAME.ADMIN_WORKSPACE_GROUPS,
        component: () => import("@/components/pages/Admin/AdminWorkspaceGroups.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_WORKSPACE_GROUP,
        name: ROUTE_NAME.ADMIN_WORKSPACE_GROUP,
        component: () => import("@/components/pages/Admin/AdminWorkspaces.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_WORKSPACES,
        name: ROUTE_NAME.ADMIN_WORKSPACES,
        component: () => import("@/components/pages/Admin/AdminWorkspaces.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_TEAMS,
        name: ROUTE_NAME.ADMIN_TEAMS,
        component: () => import("@/components/pages/Admin/AdminTeams.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_USERS,
        name: ROUTE_NAME.ADMIN_USERS,
        component: () => import("@/components/pages/Admin/AdminUsers.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_BUSINESS,
        name: ROUTE_NAME.ADMIN_BUSINESS,
        component: () => import("@/components/pages/Admin/AdminBusiness.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_CATEGORIES,
        name: ROUTE_NAME.ADMIN_CATEGORIES,
        component: () => import("@/components/pages/Admin/AdminCategories.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_CATEGORY_SUCATEGORIES,
        name: ROUTE_NAME.ADMIN_CATEGORY_SUCATEGORIES,
        component: () => import("@/components/pages/Admin/AdminCategorySubcategories.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_INTEGRATIONS,
        name: ROUTE_NAME.ADMIN_INTEGRATIONS,
        component: () => import("@/components/pages/Admin/AdminIntegrations.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_SETTING_MAVEN,
        name: ROUTE_NAME.ADMIN_SETTING_MAVEN,
        component: () => import("@/components/pages/Admin/AdminSettingMaven.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_SETTING_INTERFACE_LANGUAGE,
        name: ROUTE_NAME.ADMIN_SETTING_INTERFACE_LANGUAGE,
        component: () => import("@/components/pages/Admin/AdminSettingInterfaceLanguage.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_SETTING_PERMISSIONS,
        name: ROUTE_NAME.ADMIN_SETTING_PERMISSIONS,
        component: () => import("@/components/pages/Admin/AdminSettingPermissions.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_SETTING_MASTER_PASSWORD,
        name: ROUTE_NAME.ADMIN_SETTING_MASTER_PASSWORD,
        component: () => import("@/components/pages/Admin/AdminSettingMasterPassword.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_SETTING_IP_RESTRICTION,
        name: ROUTE_NAME.ADMIN_SETTING_IP_RESTRICTION,
        component: () => import("@/components/pages/Admin/AdminSettingIpRestriction.vue"),
      },
      {
        path: ROUTE_PATH.ADMIN_SETTING_QR_CODE,
        name: ROUTE_NAME.ADMIN_SETTING_QR_CODE,
        component: () => import("@/components/pages/Admin/AdminSettingQRCode.vue"),
      },
    ],
  },
];

export default AdminRoute;
