<template>
  <d-dropdown-menu :disabled="disabled" trigger="click" :offsetY="-20">
    <div class="DDropdown__btn text-xs-left">
      <d-text-field
        v-if="isBoxStyle"
        v-model="currentText"
        :dark="dark"
        :label="label"
        :rules="[rules.required]"
        readonly
      />
      <v-text-field v-else ref="dropdownText" v-model="currentText" :dark="dark" :rules="[rules.required]" readonly>
        <template slot="label">
          <span class="DDropdown__btnText">{{ label }}</span>
        </template>
      </v-text-field>
      <font-awesome-icon
        :icon="['fas', 'chevron-down']"
        class="DDropdown__btnIcon"
        :class="isBoxStyle && 'DDropdown__btnIcon--inBox'"
        size="sm"
      />
    </div>

    <template slot="dropdown">
      <div class="DDropdown__container">
        <div class="DDropdown__search" v-if="searchable" @click.stop>
          <d-search-input v-model="searchInput" :placeholder="searchPlaceholder" allowClear />
        </div>
        <d-dropdown-menu-item v-if="optionItems.length === 0" class="text-white text-no-wrap text-truncate">
          - {{ $t("admin.noResultsFound") }} -
        </d-dropdown-menu-item>
        <div class="DDropdown__optionContainer">
          <d-dropdown-menu-item
            class="DDropdown__options"
            v-for="option in optionItems"
            :key="option[optionKey]"
            :active="currentOption === option[optionKey]"
            @click="handleOptionSelected(option)"
          >
            {{ option[optionNameKey] }}
          </d-dropdown-menu-item>
        </div>
      </div>
    </template>
  </d-dropdown-menu>
</template>

<script>
import DTextField from "@/components/ui_components/DTextField.vue";

export default {
  name: "DDropdown",
  components: {
    DTextField,
  },
  props: {
    dark: {
      type: Boolean,
      default: () => true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    searchable: {
      type: Boolean,
      default: () => false,
    },
    searchAutoTest: {
      type: String,
      default: () => "",
    },
    searchPlaceholder: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    defaultOptionVal: {
      type: [String, Number],
    },
    optionKey: {
      type: String,
      require: true,
    },
    optionNameKey: {
      type: String,
      require: true,
    },
    customOptionsStyle: {
      type: Boolean,
      default: () => false,
    },

    /**
     * Toggles `DDropdown` styling between `DTextField` and `VTextField`.
     * - `false` (default): Uses `VTextField` styling.
     * - `true`: Uses `DTextField` styling.
     *
     * Temporary migration aid for UI transition.
     * We should replace all `VTextField` with `DTextField` or `DSelect` in the future.
     */
    isBoxStyle: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
      },
      currentText: "",
      currentOption: null,
      searchInput: "",
    };
  },
  methods: {
    handleOptionSelected(option) {
      this.currentText = option[this.optionNameKey];
      this.currentOption = option[this.optionKey];
      this.$emit("choose-option", option);
    },
    handleWorkspaceMenuEvent(workspaceId = "") {
      const matchOption = this.options.find((option) => option[this.optionKey] === workspaceId);
      if (matchOption) {
        this.handleOptionSelected(matchOption);
      }
    },
    setDefatultValue() {
      /** set default value */
      const matchOption = this.options.find((option) => option[this.optionKey] === this.defaultOptionVal);
      this.currentText = matchOption ? matchOption[this.optionNameKey] : "";
      this.currentOption = this.defaultOptionVal;
    },
  },
  created() {
    this.setDefatultValue();
  },
  watch: {
    defaultOptionVal() {
      this.setDefatultValue();
    },
  },
  computed: {
    optionItems() {
      if (!this.searchable) {
        return this.options;
      }
      return this.options.filter((option) =>
        option[this.optionNameKey].toLowerCase().includes(this.searchInput.toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.DDropdown {
  &__btn {
    position: relative;
    width: 100%;
    left: 0;
    font-size: 16px;
  }
  &__btnText {
    color: var(--dGrey6-color);
    line-height: 19px;
    width: 100%;
  }
  &__btnIcon {
    position: absolute;
    right: 0;
    bottom: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--dGrey6-color);
    &--inBox {
      right: 12px;
    }
  }
  &__container {
    background: var(--items-bg);
    max-width: 100%;
    max-height: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__options {
    max-width: 420px;
  }

  &__search {
    flex: 0 0 auto;
    width: 100%;
    height: 72px;
    padding: 16px;
    &--noResult {
      text-align: left;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 16px;
      color: var(--no-result-text-color);
    }
  }

  &__optionContainer {
    overflow: auto;
    @include scrollbar();
  }
}
</style>
