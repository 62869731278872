import { ROUTE_NAME, ROUTE_PATH } from "../route.js";

export const HOME_ROUTE_NAMES = [
  ROUTE_NAME.HOME,
  ROUTE_NAME.HOME_OVERVIEW,
  ROUTE_NAME.HOME_DISCOVER,
  ROUTE_NAME.HOME_SKILLS_TODO,
  ROUTE_NAME.HOME_SKILLS_COMPLETED,
  ROUTE_NAME.HOME_FAVORITES,
  ROUTE_NAME.SKILL_PLAYER,
];

const HomeRoute = [
  {
    path: ROUTE_PATH.HOME,
    name: ROUTE_NAME.HOME,
    component: () => import("@/components/pages/Home/Home.vue"),
    redirect: { name: ROUTE_NAME.HOME_OVERVIEW },
    meta: { auth: true },
    children: [
      {
        path: ROUTE_PATH.HOME_OVERVIEW,
        name: ROUTE_NAME.HOME_OVERVIEW,
        component: () => import("@/components/pages/Home/HomeOverview.vue"),
      },
      {
        path: ROUTE_PATH.HOME_DISCOVER,
        name: ROUTE_NAME.HOME_DISCOVER,
        component: () => import("@/components/pages/Home/HomeDiscover.vue"),
      },
      {
        path: ROUTE_PATH.HOME_SKILLS_TODO,
        name: ROUTE_NAME.HOME_SKILLS_TODO,
        component: () => import("@/components/pages/Home/HomeSkillsTodo.vue"),
      },
      {
        path: ROUTE_PATH.HOME_SKILLS_COMPLETED,
        name: ROUTE_NAME.HOME_SKILLS_COMPLETED,
        component: () => import("@/components/pages/Home/HomeSkillsCompleted.vue"),
        children: [],
      },
      {
        path: ROUTE_PATH.HOME_FAVORITES,
        name: ROUTE_NAME.HOME_FAVORITES,
        component: () => import("@/components/pages/Home/HomeFavorites.vue"),
        meta: { keepAlive: true },
      },
    ],
  },
  {
    path: `${ROUTE_PATH.SKILL_PLAYER}/:skillId`,
    name: ROUTE_NAME.SKILL_PLAYER,
    props: true,
    meta: { auth: true },
    component: () => import("@/components/DSkillPlayer/SkillPlayer"),
  },
  {
    path: ROUTE_PATH.SKILL_PLAYER_MM_SOP,
    name: ROUTE_NAME.SKILL_PLAYER_MM_SOP,
    props: true,
    meta: { auth: true },
    component: () => import("@/components/DMMSOP/DManageSmartInstruction/Home/TaskManagerHome.vue"),
  },
  {
    path: `${ROUTE_PATH.SMART_INSTRUCTION}/:smartInstructionId`,
    name: ROUTE_NAME.SMART_INSTRUCTION,
    meta: { auth: true },
    component: () => import("@/components/DMMSOP/DManageSmartInstruction/Home/TaskManagerHome.vue"),
  },
];
export default HomeRoute;
