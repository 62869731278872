import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPen,
  faSort,
  faAward,
  faUserTag,
  faIdCard,
  faGlobe,
  faDatabase,
  faShieldCheck,
  faCookie,
  faFilePdf,
  faFaceSmilePlus,
  faFilm,
  faGaugeSimpleMin,
  faPaintbrushPencil,
  faBullseyeArrow,
  faFileImport,
  faWandMagicSparkles,
  faBallotCheck,
  faEye,
} from "@fortawesome/pro-light-svg-icons";

const light = {
  addIcons() {
    library.add(
      faPen,
      faSort,
      faAward,
      faUserTag,
      faIdCard,
      faGlobe,
      faDatabase,
      faShieldCheck,
      faCookie,
      faFilePdf,
      faFaceSmilePlus,
      faFilm,
      faGaugeSimpleMin,
      faPaintbrushPencil,
      faBullseyeArrow,
      faFileImport,
      faWandMagicSparkles,
      faBallotCheck,
      faEye
    );
  },
};

export default light;
