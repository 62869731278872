import { IS_PROD_OR_SANDBOX } from "@/constants/env";

export const IS_BETA = ["deephow-beta.web.app", "beta.deephow.ai"].includes(window.location.hostname);

export const CLIENT_ENV = IS_BETA ? "prod_beta" : IS_PROD_OR_SANDBOX ? "prod" : "dev";

// whitelist domains for maven
const WHITELIST_DOMAINS = [
  "prod-maven.deephow.net",
  "maven.deephow.ai",
  "dev-maven.deephow.ai",
  "dev-maven.deephow.net:9981",
  "localhost",
];
export const IS_MAVEN_WHITELIST_DOMAIN = WHITELIST_DOMAINS.includes(window.location.hostname);
