import { IS_MOBILE } from "@/constants/device-version";

export const namespaced = true;
export const state = {
  dhDictionary: {},
  dhSettings: {},

  cachedAvatars: {}, // { [userId]: { url: blobUrl, userId: userId } }

  alertConfig: {
    index: 0,
    isOpen: false,
    manualClose: false, // if true, won't auto-close alert after 3 sec
    message: "",
    type: "",
    upper: false,
  },
  userProfileConfig: {
    uid: "",
    isOpen: false,
  },

  isSideMenuOpen: !window.location.href.includes("playerembed") && !IS_MOBILE,
  isTroublshootingOpen: false,
  isUserMenuOpen: false,
};

export const getters = {
  dh_dictionary(state) {
    return state.dhDictionary;
  },
  dh_settings(state) {
    return state.dhSettings;
  },
  cached_avatars(state) {
    return state.cachedAvatars;
  },

  getter_is_side_menu_open_desktop_ui(state) {
    return state.isSideMenuOpen && !IS_MOBILE;
  },
  getter_is_adjusted_position_by_opened_side_menu: (state, getters) => (routeName) => {
    return getters.getter_is_side_menu_open_desktop_ui && !state.userProfileConfig.isOpen;
  },
  getter_is_side_menu_open(state) {
    return state.isSideMenuOpen;
  },
  getter_is_user_menu_open(state) {
    return state.isUserMenuOpen;
  },
};

export const mutations = {
  SET_DICTIONARY(state, dictionary) {
    state.dhDictionary = dictionary;
  },
  SET_SETTINGS(state, settings) {
    state.dhSettings = settings;
  },
  CACHE_AVATAR(state, { userId, avatar }) {
    state.cachedAvatars[userId] = avatar;
  },
  UPDATE_AVATAR_URL(state, { userId, url }) {
    if (state.cachedAvatars[userId]) {
      state.cachedAvatars[userId].url = url;
    } else {
      state.cachedAvatars[userId] = { userId, url };
    }
  },

  MUTATE_ALERT_CLOSE(state) {
    state.alertConfig.isOpen = false;
  },
  MUTATE_ALERT_INFO(state, { type = "info", message, upper = false, manualClose = false }) {
    state.alertConfig.type = type;
    state.alertConfig.message = message;
    state.alertConfig.upper = upper;
    state.alertConfig.manualClose = manualClose;
    state.alertConfig.index += 1;
    state.alertConfig.isOpen = true;
  },
  SET_USER_PROFILE_CLOSE(state) {
    state.userProfileConfig.uid = "";
    state.userProfileConfig.isOpen = false;
  },
  SET_USER_PROFILE_OPEN(state, uid) {
    state.userProfileConfig.uid = uid;
    state.userProfileConfig.isOpen = true;
  },

  MUTATE_SIDE_MENU_OPEN(state, { isOpen }) {
    state.isSideMenuOpen = isOpen;
  },
  MUTATE_TROUBLESHOOTING_OPEN(state, isOpen) {
    state.isTroublshootingOpen = isOpen;
  },
  TOGGLE_USER_MENU(state, { isOpen }) {
    state.isUserMenuOpen = isOpen;
  },
};

export const actions = {
  setDictionary({ commit }, dictionary) {
    commit("SET_DICTIONARY", dictionary);
  },
  setSettings({ commit }, settings) {
    commit("SET_SETTINGS", settings);
  },

  cacheAvatarToStore({ commit }, { userId, avatar }) {
    commit("CACHE_AVATAR", { userId, avatar });
  },
  updateCachedAvatarUrl({ commit }, { userId, url }) {
    commit("UPDATE_AVATAR_URL", { userId, url });
  },

  closeAlert({ commit }) {
    commit("MUTATE_ALERT_CLOSE");
  },
  openAlert({ commit }, payload) {
    commit("MUTATE_ALERT_INFO", payload);
  },

  setUserProfileClose({ commit }) {
    commit("SET_USER_PROFILE_CLOSE");
  },
  setUserProfileOpen({ commit }, { uid }) {
    commit("SET_USER_PROFILE_OPEN", uid);
  },

  closeSideBar({ commit }) {
    commit("MUTATE_SIDE_MENU_OPEN", { isOpen: false });
  },
  openSideBar({ commit }) {
    commit("MUTATE_SIDE_MENU_OPEN", { isOpen: true });
  },

  closeTroubleshooting({ commit }) {
    commit("MUTATE_TROUBLESHOOTING_OPEN", false);
  },
  openTroubleshooting({ commit }) {
    commit("MUTATE_TROUBLESHOOTING_OPEN", true);
  },
};
