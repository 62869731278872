import store from "@/store";
import { ROUTE_NAME, ROUTE_PATH } from "../route.js";

export const SKILL_ROUTE_NAMES = [ROUTE_NAME.SKILL, ROUTE_NAME.SKILL_HOME];
export const SKILL_BUILDER_ROUTE_NAMES = [
  ROUTE_NAME.SKILL_BUILDER,
  ROUTE_NAME.SKILL_BUILDER_CONTENT,
  ROUTE_NAME.SKILL_BUILDER_USERS,
  ROUTE_NAME.SKILL_BUILDER_ASSESSMENT,
  ROUTE_NAME.SKILL_BUILDER_CERTIFICATION,
];
const SkillRoute = [
  {
    path: ROUTE_PATH.SKILL,
    name: ROUTE_NAME.SKILL,
    component: () => import("@/components/pages/Skill/Skill.vue"),
    redirect: { name: ROUTE_NAME.SKILL_HOME },
    meta: { auth: true },
    children: [
      {
        path: ROUTE_PATH.SKILL_HOME,
        name: ROUTE_NAME.SKILL_HOME,
        component: () => import("@/components/pages/Skill/SkillHome.vue"),
      },
    ],
  },
  {
    path: ROUTE_PATH.SKILL_BUILDER,
    name: ROUTE_NAME.SKILL_BUILDER,
    component: () => import("@/components/pages/Skill/SkillBuilder"),
    meta: { auth: true, previousPath: undefined },
    redirect: { name: ROUTE_NAME.SKILL_BUILDER_CONTENT },
    props: true,
    beforeEnter: (to, from, next) => {
      to.meta.previousPath = from.path === "/" ? ROUTE_PATH.SKILL : from.path;
      next();
    },
    children: [
      {
        path: ROUTE_PATH.SKILL_BUILDER_CONTENT,
        name: ROUTE_NAME.SKILL_BUILDER_CONTENT,
        component: () => import("@/components/pages/Skill/SkillBuilderContent"),
        props: true,
      },
      {
        path: ROUTE_PATH.SKILL_BUILDER_USERS,
        name: ROUTE_NAME.SKILL_BUILDER_USERS,
        component: () => import("@/components/pages/Skill/SkillBuilderUsers"),
        props: true,
      },
      {
        path: ROUTE_PATH.SKILL_BUILDER_ASSESSMENT,
        name: ROUTE_NAME.SKILL_BUILDER_ASSESSMENT,
        component: () => import("@/components/pages/Skill/SkillBuilderAssessment"),
        props: true,
      },
      {
        path: ROUTE_PATH.SKILL_BUILDER_CERTIFICATION,
        name: ROUTE_NAME.SKILL_BUILDER_CERTIFICATION,
        component: () => import("@/components/pages/Skill/SkillBuilderCertification"),
        props: true,
      },
    ],
  },
];
export default SkillRoute;
