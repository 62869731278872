import axios from "axios";
import services from "@/constants/env/services";
import authChoice from "../../auth";
import { CLIENT_ENV } from "../constants/envVersion";
import { apiWrapperGenerator } from "./lib";

const apiServer = axios.create({
  baseURL: services.deephowApiServer,
});

apiServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    // when re-transcription: backend will check language code is valid (capture.inputLang)
    config.headers["client-environment"] = CLIENT_ENV;
    return config;
  },
  (error) => Promise.resolve(error)
);

const apiWrapper = apiWrapperGenerator(apiServer);

export const authWorkspaceGroupPermission = ({ workspaceGroupId, params = { workspacesType: "view" } }) =>
  apiWrapper("get", `/v1/workspaces-groups/${workspaceGroupId}/permissions`, { params });

export const authWorkflowPermission = (workflowId) => apiWrapper("get", `/v1/workflows/${workflowId}/permissions`);

export const authGroupPermission = (groupId) => apiWrapper("get", `/v1/groups/${groupId}/permissions`);

export const fetchWorkspaceList = (params) => apiWrapper("get", "/v1/workspaces", { params });

export const fetchWorkspaceById = (groupId) => apiWrapper("get", `/v1/groups/${groupId}`);

export const fetchWorkflowByWorkspaceId = (groupId) => apiWrapper("get", `/v1/groups/${groupId}/workflows`);

export const fetchWorkflowByWorkflowId = (workflowId) => apiWrapper("get", `/v1/workflows/${workflowId}`);

export const fetchWorkflowReviewers = (workflowId) => apiWrapper("get", `/v1/workflows/${workflowId}/reviewers`);

export const fetchWorkflowSteps = (workflowId) => apiWrapper("get", `/v1/workflows/${workflowId}/steps`);

export const fetchAllWorkflows = (params = { page: 0, size: 100 }) =>
  apiWrapper("get", `/v1/workflows/analytics`, { params });

export const deleteWorkflow = async (workflowId) => apiWrapper("delete", `/v1/workflows/${workflowId}`);

export const duplicateWorkflow = ({ workflowId, groupIds }) =>
  apiWrapper("post", `/v1/workflows/${workflowId}/duplication`, { groupIds });

export const updateWorkflowPoster = ({ workflowId, imageUrl }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/poster`, { imageUrl });

export const updateWorkflowTitle = ({ workflowId, title, languageCode = "" }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/title`, { title, languageCode });

export const updateWorkflowGroup = ({ workflowId, workspaceId }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/workspace`, { workspaceId });

export const unpublishWorkflow = (workflowId) => apiWrapper("put", `/v1/workflows/${workflowId}/unpublish`);

export const fetchWorkflowPreviewStatus = (workflowId) =>
  apiWrapper("get", `/v1/workflows/${workflowId}/preview/status`);

export const postGenerateSubtitles = (workflowId) =>
  apiWrapper("post", `/v1/workflows/${workflowId}/preview/subtitles`);

export const postResetWorkflow = ({ workflowId, keepWorkflow }) =>
  apiWrapper("post", `/v1/workflows/${workflowId}/builder/reset`, { keepWorkflow });

export const putWorkflowBuilder = (workflowId, payload) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/builder`, payload);

export const putUpdateTranscription = ({ workflowId, title, sentences, terms, nextStep }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/transcription`, { title, sentences, terms, nextStep });

export const putUpdateSegmentation = ({ workflowId, title, sentences, steps, nextStep }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/segmentation`, { title, sentences, steps, nextStep });

export const putReTranscription = ({ workflowId, languageCode }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/language-code`, { languageCode });

export const postTeamListByWorkspaceId = ({ groupId }) => apiWrapper("post", `/v1/teams/teaminfo`, { groupId });

export const deleteWorkflowReaction = ({ workflowId }) =>
  apiWrapper("delete", `/v1/workflows/${workflowId}/reactions`, { data: { workflowId } });

export const updateWorkflowReaction = ({ workflowId, reaction }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/reactions`, { reaction });

export const fetchWorkflowReactions = ({ workflowId }) => apiWrapper("get", `/v1/workflows/${workflowId}/reactions`);

export const postInitializeWorkflow = (workflow) => apiWrapper("post", `/v1/workflows/initialize`, { workflow });

export const postWorkflowFeedback = ({ workflowId, feedback, languageCode, baseUrl }) =>
  apiWrapper("post", `/v1/workflows/${workflowId}/feedback`, { feedback, languageCode, baseUrl });

export const createTextToSpeechs = ({ workflowId, text2Speechs }) =>
  apiWrapper("post", `/v1/workflows/${workflowId}/text-2-speech`, { text2Speechs });

// Workflow comments:
export const fetchCommentsById = ({ level, id, withReactions = false, page = 0, size = 100 }) =>
  apiWrapper("post", `/v1/comments?page=${page}&size=${size}`, { level, id, withReactions });

export const postCreateNewComment = ({ id, level, comment }) =>
  apiWrapper("post", `/v1/comments/${id}`, { level, comment });

export const deleteCommentByCommentId = ({ commentId }) => apiWrapper("delete", `/v1/comments/${commentId}`);

export const updateCommentByCommentId = ({ commentId, comment }) =>
  apiWrapper("put", `/v1/comments/${commentId}`, { comment });

// Workflow comment reactions:
export const fetchCommentReactions = ({ commentId }) => apiWrapper("get", `/v1/comments/${commentId}/reactions`);

export const createCommentReaction = ({ commentId, emojiChar, emojiCode }) =>
  apiWrapper("post", `/v1/comments/${commentId}/reactions`, { emojiChar, emojiCode });

export const deleteCommentReaction = ({ commentId, reactionId }) =>
  apiWrapper("delete", `/v1/comments/${commentId}/${reactionId}`);

export const getUserProfileApi = () => apiWrapper("get", "/v1/profile/me");

export const createUserConsents = async (consents) => apiWrapper("post", "/v1/userConsents", { consents });
export const updateUserConsents = async (consents) => apiWrapper("put", "/v1/userConsents", { consents });
export const getUserConsents = async () => apiWrapper("get", "/v1/userConsents");

export const getEditedQuiz = async (workflowId) => apiWrapper("get", `v1/workflows/${workflowId}/quiz`);

export const postCreateQuiz = async ({ workflowId, questions }) =>
  apiWrapper("post", `v1/quiz`, { workflow: workflowId, questions });

export const putEditedQuiz = async ({ questionId, published, questions }) =>
  apiWrapper("put", `v1/quiz/${questionId}`, { published, questions });

export const updateMultimodalSOPQuiz = async (payload) => apiWrapper("post", "v1/user-quiz", payload);

export const postGenerateQuizByAI = async ({ workflowId, numberOfQuestions, questionTypes }) =>
  apiWrapper("post", `v1/workflows/${workflowId}/ai-generate-quiz`, { numberOfQuestions, questionTypes });

export const getWorkflowConversationsHistory = async ({ workflowId }) => {
  return apiWrapper("get", `/v1/workflows/${workflowId}/conversion-history`);
};

export const fetchWorkflows = (params) => apiWrapper("post", "/v1/workflows/search", params);
/** User Likes */
/**
 * Deprecated: 2024.12.04
 * Please use setWorkspaceFavorite({ workspaceId, like })
 */
export const postGroupLike = async ({ like, groupId }) =>
  apiWrapper("post", "/v1/user-likes/groups", { like, groupId });

export const setWorkspaceFavorite = ({ workspaceId, like }) =>
  apiWrapper("post", "/v1/user-likes/workspaces", { workspaceId, like });

export const postWorkflowLike = async ({ like, workflowId }) =>
  apiWrapper("post", "/v1/user-likes/workflow", { like, workflowId });

export const getUserLikesWorkflows = async ({ userId, keyword, page, size }) =>
  apiWrapper("get", `/v1/user-likes/${userId}/workflows`, { params: { keyword, page, size } });

/** AI Summary */
export const fetchWorkflowAISummary = ({ workflowId }) => apiWrapper("get", `/v1/workflows/${workflowId}/ai-summary`);

export const getWorkflowsByUserCreated = ({ userId, params }) =>
  apiWrapper("get", `/v1/users/${userId}/workflows/creator`, { params });

export const getMMSOPByUserCreated = ({ userId, params }) =>
  apiWrapper("get", `/v1/users/${userId}/multimodal-sops/creator`, { params });

export const getSkillsByUserCreated = ({ userId, params }) =>
  apiWrapper("get", `/v1/users/${userId}/skills/creator`, { params });

export const getTeamsByUserId = (userId) => apiWrapper("get", `/v1/users/${userId}/teams/creator`);

export const getOwnedByMeWorkflowsMetrics = ({ userId, params }) =>
  apiWrapper("get", `/v1/users/${userId}/workflows/metrics`, { params });

export const getCategoriesByWorkspaceIds = ({ workspaceIds, userId }) =>
  apiWrapper("get", `/v1/users/${userId}/workflows/categories`, {
    params: {
      workspaceIds,
    },
  });

export const getCategoriesByWorkspaceId = ({ workspaceId }) =>
  apiWrapper(
    "get",
    `/v1/workspaces/${workspaceId}/workflows/categories
`
  );

export const getUserListByWorkspaceId = ({ workspaceId, ...params }) =>
  apiWrapper("get", `/v1/workspaces/${workspaceId}/users`, { params });

export const getWorkflowStats = ({ page = 1, size = 500, workflows = [], workspaceId, ...params }) =>
  apiWrapper("get", "/v1/analytics/workflows/list", {
    params: { page, size, workflowIds: workflows, workspaceId, ...params },
  });

export const getOneWorkflowStats = ({ workflowId }) =>
  apiWrapper("get", `/v1/analytics/workflows/${workflowId}/users/view-history`);

/** Multimodal SOP APIs */
export const fetchMultimodalSOPs = ({ workspaceId, page = 0, size = 10, orderBy = "updatedAt", order = "desc" }) =>
  apiWrapper("get", "/v1/multimodal-sop", {
    params: {
      workspaceId,
      page,
      size,
      orderBy,
      order,
    },
  });

export const fetchMultimodalSOPById = ({ id, includeSubData = false }) =>
  apiWrapper("get", `/v1/multimodal-sop/${id}`, {
    params: { includeSubData: String(includeSubData) },
  });

export const createMultimodalSOP = (payload) => apiWrapper("post", "/v1/multimodal-sop", payload);

export const updateMultimodalSOP = ({ id, payload }) => apiWrapper("patch", `/v1/multimodal-sop/${id}`, payload);

export const updateWholeMultimodalSOP = ({ id, payload }) => apiWrapper("put", `/v1/multimodal-sop/${id}`, payload);

export const deleteMultimodalSOP = (id) => apiWrapper("delete", `/v1/multimodal-sop/${id}`);

/** Multimodal SOP Items APIs */
export const createMultimodalSOPItem = (payload) => apiWrapper("post", "/v1/multimodal-sop-items", payload);

export const updateMultimodalSOPItem = ({ id, ...payload }) =>
  apiWrapper("patch", `/v1/multimodal-sop-items/${id}`, payload);

export const deleteMultimodalSOPItem = (id) => apiWrapper("delete", `/v1/multimodal-sop-items/${id}`);

export const moveMultimodalSopToWorkspace = ({ workflowId, ...payload }) =>
  apiWrapper("put", `/v1/multimodal-sop/${workflowId}/workspace`, payload);

export default apiWrapper;
