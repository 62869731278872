<template>
  <v-menu
    transition="slide-y-transition"
    bottom
    left
    offset-y
    :open-on-hover="!isTabletDevice && !isMobileDevice"
    :open-on-click="isTabletDevice || isMobileDevice"
    close-on-click
    close-on-content-click
  >
    <button slot="activator" class="language-btn text-no-wrap">
      <icon-base width="22" height="22" :color="!isMobileDevice ? '#484848' : '#ffffff'" class="language-icon">
        <d-icon-translation />
      </icon-base>
      <span v-if="!isMobileDevice" class="body-2 white--text text-capitalize button-text">
        {{ getUILocaleName(language) }}
        <v-icon small class="ml-1" color="#ffffff">keyboard_arrow_down</v-icon>
      </span>
    </button>
    <div class="list-div">
      <div
        v-for="(lang, idx) in languages"
        :key="idx"
        class="list-item"
        :class="{ 'current-list-item': lang == language }"
        @click="changeLang(lang)"
      >
        {{ getUILocaleName(lang) }}
      </div>
    </div>
  </v-menu>
</template>

<script>
import DIconTranslation from "@/components/icons/DIconTranslation.vue";
import { UI_LOCALES, getUILocaleName } from "@/lang/i18n";

export default {
  name: "UserLanguageMenu",
  props: {
    userInviteCreate: Boolean,
    userNewPassword: Boolean,
    isMobileDevice: Boolean,
    isTabletDevice: Boolean,
  },
  data() {
    return {
      languages: UI_LOCALES,
      language: this.$i18n.locale || "en",
    };
  },
  watch: {},
  components: {
    DIconTranslation,
  },
  methods: {
    getUILocaleName,
    changeLang(newLanguage) {
      if (newLanguage == this.language) return;
      this.$i18n.locale = newLanguage;
      this.language = newLanguage;
      localStorage.setItem("locale", newLanguage);
      if (this.userInviteCreate || this.userNewPassword) {
        this.$emit("change-lang-url");
        return;
      }
      location.reload();
    },
  },
  computed: {},
};
</script>

<style scoped>
.language-btn {
  position: relative;
  height: 22px;
  color: #ffffff;
  padding-left: 22px;
}
.language-icon {
  position: absolute;
  left: 0;
  top: -1px;
}
.button-text {
  line-height: 22px;
  margin-left: 12px;
}
.list-div {
  background-color: #323338;
  border-radius: 2px;
  padding: 6px 0;
}
.list-item {
  color: #ffffff;
  text-align: left;
  padding: 8px 24px 8px 24px;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}
.list-item:hover {
  background-color: #52545d !important;
}
.current-list-item {
  color: #4689f3;
}
</style>
