<template>
  <button class="DIconButton" :class="{ 'DIconButton--active': active }" @click="$emit('click')">
    <slot />
  </button>
</template>

<script>
export default {
  name: "DIconButton",
  props: {
    active: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.DIconButton {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px;
  font-size: 16px;
  border: 1px solid var(--dGrey5-color);
  border-radius: 80px;
  color: var(--dGrey6-color);
  height: 40px;
  width: 40px;

  &:hover {
    border: 1px solid var(--brand-blue);
  }
  &--active {
    border: 1px solid var(--brand-blue);
    color: var(--brand-white);
  }
}
</style>
