<template>
  <section class="d-avatar" :class="{ 'avatar-border': border }" @click="$emit('click')">
    <v-avatar :size="size">
      <img v-if="blobAvatar" :src="blobAvatar" />
      <img v-else src="@/img/user-light.png" />
    </v-avatar>
  </section>
</template>

<script>
import MixinAvatar from "@/components/MixinAvatar.vue";
import { getImageBlob } from "@/js/common/imageLib";

export default {
  name: "d-avatar",
  props: {
    userId: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 36,
    },
    border: {
      type: Boolean,
      default: false,
    },
    avatarUrl: {
      type: String,
      default: "",
    },
  },
  components: {},
  watch: {
    userId: function () {
      //console.log("watch", this.userId);
      this.blobAvatar = "";
      this.getUserAvatar(
        this.userId,
        function (avatar) {
          //console.log("url", avatar.url, avatar.userId, this.userId);
          if (this.userId === avatar.userId) {
            this.blobAvatar = avatar.url;
          }
        }.bind(this)
      );
    },
  },
  async mounted() {
    if (this.avatarUrl) {
      if (this.avatarUrl instanceof Blob) {
        this.blobAvatar = this.avatarUrl;
        return;
      }
      const { ok, url } = await getImageBlob(this.avatarUrl);
      if (!ok) {
        console.error(`getImageBlob occurred error`);
      }
      this.blobAvatar = url;
      return;
    }
    this.getUserAvatar(
      this.userId,
      function (avatar) {
        if (this.userId === avatar.userId) {
          this.blobAvatar = avatar.url;
        }
      }.bind(this)
    );
  },
  mixins: [MixinAvatar],
  data() {
    return {
      blobAvatar: "",
    };
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.d-avatar {
  display: inline-block;
}
.avatar-border {
  border: var(--brand-blue) 2px solid;
  border-radius: 50%;
}
</style>
