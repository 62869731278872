// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import { mapActions } from "vuex";
import router from "./router";
import store from "./store";
import App from "./App";
import { initializeFirebase } from "./firebaseService";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import axios from "axios";
import auth from "../auth.js";
import Analytics from "./js/analytics/analytics";
import userGuide from "./js/userGuide";

import "@/assets/css/variables.css";
import "@/assets/css/utils.scss";
import "@/assets/css/dtailwind.scss";

import FaIcon from "@/plugin/fa-icon";
import DeephowUI from "@/plugin/deephow-ui";

import VueI18n from "vue-i18n";
import { loggingError } from "@/server/error-log-server.js";
import { i18nConfig } from "@/lang/i18n";
import downloadFiles from "@/constants/env/downloadFiles";
import ElementUI from "@/plugin/element-ui";

userGuide.initialize();
Analytics.initialize();

Vue.use(FaIcon);
Vue.use(DeephowUI);
Vue.use(VueI18n);
export const i18n = new VueI18n(i18nConfig);

// Initialize Element UI with i18n
Vue.use(ElementUI, { i18n });

Vue.use(Vuetify, {
  theme: {
    primary: "#4689f4",
    secondary: "#191919",
    accent: "#888888",
    error: "#888888",
    dBlue: "#4689f4",
    dDarkGrey: "#454545",
    dGrey: "#4A4A4A",
    dRed: "#e03535",
    dWhite: "#FBFBFB",
    dDarkBg: "#1E1F22",
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});

Vue.config.productionTip = false;

// Error handling for Vue hooks
Vue.config.errorHandler = async function (error, vm, info) {
  try {
    await loggingError(error);
  } catch (error) {
    console.log(error);
  }
};

// Error handling for unhandled Promise rejections
window.onunhandledrejection = async function (event) {
  try {
    await loggingError(event.reason);
  } catch (error) {
    console.log(error);
  }
};

// Global Mixin
Vue.mixin({
  methods: {
    ...mapActions("global", ["openAlert"]),
  },
});

initializeFirebase();

const eventbus = new Vue();
export default eventbus;

let vueInitialized = false;
auth().onAuthStateChanged((user) => {
  if (user) {
    //TODO: get and set user locale and settings before initVue
    initVue(true);

    auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {})
      .catch(function (error) {
        // Handle error
      });
  } else {
    initVue(false);
  }
});

function initVue(authenticated) {
  if (vueInitialized) {
    return;
  }
  vueInitialized = true;
  new Vue({
    i18n,
    router,
    el: "#app",
    store,
    render: (h) => h(App),
    created() {
      this.initDictionary();
      this.initSetting();
    },
    methods: {
      initDictionary() {
        axios
          .get(downloadFiles.dhDictionary)
          .then((data) => {
            const dictionary = data.data;
            this.$store.dispatch("global/setDictionary", dictionary);
          })
          .catch((error) => console.error({ error }));
      },
      initSetting() {
        axios
          .get(downloadFiles.dhSettings)
          .then((data) => {
            const settings = data.data;
            this.$store.dispatch("global/setSettings", settings);
          })
          .catch((error) => console.error({ error }));
      },
    },
  });
}
