<template lang="html">
  <section class="mixin-analytics"></section>
</template>

<script>
import MixinDB from "./MixinDB.vue";
import { csv } from "d3-fetch";
import SyncPlayerTracking from "@/js/player-tracking/sync-player-tracking-plugin.js";
import {
  getSkillPlaylistProgress,
  getSkillWorkflowProgress,
  getManySkillPlaylistProgress,
  fetchWorkspaceStates,
  getGroupUserStats,
  getOneWorkflowOverview,
  getOneUserOverview,
  getTopGroups,
  getOrgOverview,
  getTopUsers,
} from "@/server/analytics-server";
import { getOneWorkflowStats, getWorkflowStats } from "@/server/api-server";

import { loggingError } from "@/server/error-log-server.js";
import { getHourMinuteSecond } from "@/js/time/format";
import { mapGetters } from "vuex";

export default {
  name: "mixin-analytics",
  props: [],
  mixins: [MixinDB],
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["getter_user_organization"]),
  },
  methods: {
    getManySkillPlaylistProgress(playlists) {
      return getManySkillPlaylistProgress({
        playlists,
      }).then(({ ok, data }) => {
        return (ok && data.items) || [];
      });
    },
    getSkillPlaylistProgress(uid) {
      return getSkillPlaylistProgress({
        user: uid,
      }).then(({ ok, data }) => {
        if (ok) {
          return data.item;
        }
      });
    },
    getSkillWorkflowProgress(uid, playlistId) {
      return getSkillWorkflowProgress({
        playlistId,
        user: uid,
      }).then(({ ok, data }) => {
        if (ok) {
          return data.item;
        }
      });
    },
    async syncIndexedDb() {
      SyncPlayerTracking.forceSyncAllTrackingInfoToServer();
    },
    getWorkflowLikes(workflowId) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        self
          .getDocumentByQuery("likes", [
            {
              field: "workflowId",
              comparison: "==",
              value: workflowId,
            },
            {
              field: "like",
              comparison: "==",
              value: true,
            },
            {
              field: "organization",
              comparison: "==",
              value: self.getter_user_organization,
            },
          ])
          .then((data) => {
            resolve(data);
          })
          .catch((err) => loggingError(err));
      });
    },

    readCSVToJSON(file) {
      return new Promise(function (resolve, reject) {
        csv(file)
          .then(function (data) {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getGroupUserStats(groupId) {
      return getGroupUserStats({
        groupId,
      }).then(({ ok, data }) => {
        return (ok && data.item) || {};
      });
    },
    getOneWorkflowStats(workflowId) {
      return getOneWorkflowStats({
        workflowId,
      }).then(({ ok, data }) => {
        return (ok && data.items) || [];
      });
    },
    getOneWorkflowOverview(workflowId) {
      return getOneWorkflowOverview({
        workflowId,
      }).then(({ ok, data }) => {
        return (ok && data.item) || {};
      });
    },
    getOneUserOverview(uid) {
      return getOneUserOverview({
        uid,
      }).then(({ ok, data }) => {
        return (ok && data.item) || {};
      });
    },
    getGroupsStats(workspaces) {
      return fetchWorkspaceStates({
        workspaces,
        size: workspaces.length,
      }).then(({ ok, data }) => {
        return (ok && data.items) || [];
      });
    },
    getTopGroups() {
      return getTopGroups().then(({ ok, data }) => {
        return (ok && data.item) || {};
      });
    },
    getOrgOverview() {
      return getOrgOverview().then(({ ok, data }) => {
        return (ok && data.item) || {};
      });
    },

    getTopUsers() {
      return getTopUsers().then(({ ok, data }) => {
        return (ok && data.item) || {};
      });
    },
    getWorkflowStats(workflows) {
      return getWorkflowStats({
        size: workflows.length,
        workflows: workflows,
      }).then(({ ok, data }) => {
        return (ok && data.items) || [];
      });
    },
    getMinuteSecond(sec) {
      if (!sec || sec === Infinity) return "0:00:00";
      const hours = Math.floor(sec / 3600);
      sec %= 3600;
      const minutes = Math.floor(sec / 60);
      const seconds = Math.floor(sec - minutes * 60);
      const padding = minutes < 10 ? "0" : "";
      const paddingSec = seconds < 10 ? "0" : "";
      return minutes + ":" + paddingSec + seconds;
    },
    getHourMinuteSecond,
    getHourMinute(sec) {
      if (!sec || sec === Infinity) return "0:00";
      const hours = Math.floor(sec / 3600);
      sec %= 3600;
      const minutes = Math.floor(sec / 60);
      const padding = minutes < 10 ? "0" : "";
      return hours + ":" + padding + minutes;
    },
    getHours(sec) {
      return sec ? Math.round(sec / 3600) : "N/A";
    },
    getNameInitials(name) {
      if (name.length > 0) {
        let initials = "";
        const nameArray = name.split(" ");
        initials = nameArray[0].substring(0, 1).toUpperCase();
        if (nameArray.length > 1) {
          initials += nameArray[nameArray.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      }
    },
    downloadTableAsCsvFile(data, fileName) {
      const universalBOM = "\ufeff";
      // const testText = "简体繁體อักษรไทย한국어Español";
      let csvContent = universalBOM;
      data.forEach(function (infoArray, index) {
        // Building the CSV from the Data two-dimensional array
        // Each column is separated by "," and new line "\n" for next row
        const dataString = infoArray.join(",");
        csvContent += index < data.length ? dataString + "\n" : dataString;
      });
      const a = document.createElement("a");
      let blob = new Blob(
        [
          new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
          csvContent,
        ],
        { type: "text/csv; charset=utf-8" }
      );
      a.href = URL.createObjectURL(blob);
      // a.href ="data:text/csv; charset=utf-8," + encodeURIComponent(csvContent); // only this mime type is supported
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      //////////
      // The download function takes a CSV string, the filename and mimeType as parameters
      // Scroll/look down at the bottom of this snippet to see how download is called
      /////////////////
      // const download = function (content, fileName, mimeType) {
      //     const a = document.createElement("a");
      //     mimeType = mimeType || "application/octet-stream";
      //     if (navigator.msSaveBlob) {
      //     // IE10
      //     navigator.msSaveBlob(
      //         new Blob([content], {
      //         type: mimeType,
      //         }),
      //         fileName
      //     );
      //     } else if (URL && "download" in a) {
      //     //html5 A[download]
      //     a.href = URL.createObjectURL(
      //         new Blob([content], {
      //         type: mimeType,
      //         })
      //     );
      //     a.setAttribute("download", fileName);
      //     document.body.appendChild(a);
      //     a.click();
      //     document.body.removeChild(a);
      //     } else {
      //     location.href =
      //         "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      //     }
      // };
      // download(csvContent, fileName, "text/csv;encoding:utf-8");
    },
    downloadFile(signedUrl, fileName) {
      const a = document.createElement("a");
      a.href = signedUrl;
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>
