// category-api-doc:
// https://docs.google.com/document/d/17aqWgBYSV1x2hBWolnTVUTm9M_LTT50e/edit#heading=h.21j8b7uq9c0f
import deephowApiServerWrapper from "@/server/api-server";

export const createCategory = (payload) => deephowApiServerWrapper("post", "/v1/categories", payload);

export const deleteCategory = (categoryId) => deephowApiServerWrapper("delete", `/v1/categories/${categoryId}`);

export const fetchCategories = (params) => deephowApiServerWrapper("get", "/v1/categories", { params });

export const fetchCategory = (categoryId) => deephowApiServerWrapper("get", `/v1/categories/${categoryId}`);

export const updateCategory = (categoryId, payload) =>
  deephowApiServerWrapper("patch", `/v1/categories/${categoryId}`, payload);
