<template>
  <div class="DEmptyBlock">
    <slot name="icon" />
    <div v-if="$slots.title" class="DEmptyBlock__title">
      <slot name="title" />
    </div>
    <div v-if="$slots.description" class="DEmptyBlock__description">
      <slot name="description" />
    </div>
    <div v-if="$slots.tools" class="DEmptyBlock__tools">
      <slot name="tools" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DEmptyBlock",
  components: {},
};
</script>

<style lang="scss" scoped>
.DEmptyBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: var(--spacing-04);
  padding: var(--spacing-07) 0px;

  &__title {
    color: var(--brand-white);
    font-size: 20px;
    text-align: center;
  }
  &__description {
    color: var(--dGrey6-color);
    font-size: 16px;
    text-align: center;
  }
  &__tools {
    margin: var(--spacing-03) 0;
  }
}
</style>
