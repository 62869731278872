<template>
  <div class="NotificationCard" :class="{ NotificationCard__mobile: isMobileDevice }" @click="onclickNotificationCard">
    <!-- /// for Workflows /// -->

    <template v-if="cardType === notifyCardType.WORKFLOW">
      <notification-card-base
        :message="message"
        :timeSince="timeSince"
        :read="notification.read"
        :poster="notification.poster"
        :title="notification.workflow.title"
        :time="videoTime"
        :info="videoInfo"
      >
        <template slot="avatar" v-if="senders.length > 0">
          <!-- "workflow-reaction" -->
          <div v-if="notification.type === notifyType.WORKFLOW_REACTION" class="NotificationCard__avatar">
            <template v-if="senders.length === 1">
              <d-avatar :size="32" :userId="senders[0].senderId" />
            </template>
            <template v-else-if="senders.length >= 2">
              <d-avatar :size="24" :userId="senders[1].senderId" />
              <d-avatar :size="24" :userId="senders[0].senderId" class="NotificationCard__avatar--reaction" />
            </template>
          </div>
          <div v-else-if="notification.type === notifyType.USER_WORKFLOW_RETRAIN" class="NotificationCard__avatar">
            <div class="NotificationCard__avatar--iconSkillLg NotificationCard__avatar--iconContentUpdate">
              <font-awesome-icon :icon="['far', 'rotate-exclamation']" />
            </div>
          </div>
          <!-- "share-workflow" "review-workflow" -->
          <div v-else class="NotificationCard__avatar">
            <d-avatar :size="32" :userId="senders[0].senderId" />
            <div
              class="NotificationCard__avatar--icon"
              :class="{
                'NotificationCard__avatar--iconShareWorkflow': notification.type === notifyType.SHARE_WORKFLOW,
                'NotificationCard__avatar--iconReviewWorkflow': notification.type === notifyType.REVIEW_WORKFLOW,
              }"
            >
              <font-awesome-icon
                v-if="notification.type === notifyType.SHARE_WORKFLOW"
                :icon="['far', 'layer-group']"
              />
              <font-awesome-icon
                v-else-if="notification.type === notifyType.REVIEW_WORKFLOW"
                :icon="['far', 'comments']"
              />
            </div>
          </div>
        </template>

        <!-- for "share-workflow" only, share message -->
        <template
          slot="workflow-message"
          v-if="notification.type === notifyType.SHARE_WORKFLOW && notification.message"
        >
          <div class="NotificationCard__workflowMessage">{{ notification.message }}</div>
        </template>

        <template
          slot="workflow-info"
          v-if="[notifyType.WORKFLOW_REACTION, notifyType.USER_WORKFLOW_RETRAIN].includes(notification.type)"
        >
          <!-- "workflow-reaction"- reactions icons and number of colleagues -->
          <reactions-info
            v-if="notification.type === notifyType.WORKFLOW_REACTION"
            :reactions="notification.workflow.reactions"
            :usersNum="senders.length"
            class="NotificationCard__ReactionInfo"
          />
          <div v-else-if="notification.type === notifyType.USER_WORKFLOW_RETRAIN">
            {{ $t("skills.updatedBy") }} <span class="white--text">{{ notification.activity[0].senderName }} </span>
          </div>
        </template>
      </notification-card-base>
    </template>

    <!-- /// for Skills /// -->
    <template v-else-if="[notifyCardType.SKILL, notifyCardType.SKILL_SYSTEM].includes(cardType)">
      <notification-card-base
        :message="message"
        :timeSince="timeSince"
        :read="notification.read"
        :poster="notification.poster"
        :title="notification.playlist.playlistTitle"
        :time="videoTime"
        :info="videoInfo"
      >
        <template slot="avatar">
          <!-- "assign-skill", "publish-skill", "unpublish-skill", "remind-skill", "assign-skill-due" -->
          <div v-if="cardType === notifyCardType.SKILL" class="NotificationCard__avatar">
            <d-avatar :size="32" :userId="senders[0].senderId" />
            <div class="NotificationCard__avatar--icon NotificationCard__avatar--iconSkill">
              <font-awesome-icon :icon="['fas', 'bullseye-arrow']" />
            </div>
          </div>
          <!-- "remind-skill-overdue", "remind-skill-left-days" -->
          <div v-else class="NotificationCard__avatar">
            <div class="NotificationCard__avatar--iconSkillLg">
              <font-awesome-icon :icon="['fas', 'bullseye-arrow']" />
            </div>
          </div>
        </template>
      </notification-card-base>
    </template>
  </div>
</template>

<script>
import ReactionsInfo from "@/components/DNotification/card/ReactionsInfo.vue";
import NotificationCardBase from "@/components/DNotification/card/NotificationCardBase.vue";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import DImage from "@/components/ui_components/DImage.vue";
import MixinDB from "@/components/MixinDB.vue";
import { mapActions } from "vuex";
import { formatIsoStrTimeSince } from "@/js/time/timeSince.js";
import { getFormattedVideoDuration } from "@/js/common/formatText.js";
import Analytics from "@/js/analytics/analytics";
import { NOTIFY_CARD_GROUP, NOTIFY_CARD_TYPE, NOTIFY_TYPE } from "@/constants/notification";
import { ROUTE_NAME, ROUTE_PATH } from "@/router/route";
export default {
  name: "NotificationCard",
  components: {
    ReactionsInfo,
    NotificationCardBase,
    DAvatar,
    DImage,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isMobileDevice: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [MixinDB],

  data() {
    return {
      notifyCardType: NOTIFY_CARD_TYPE,
      notifyType: NOTIFY_TYPE,
    };
  },

  methods: {
    ...mapActions("notification", ["updateReadInNotifications"]),
    onclickNotificationCard() {
      this.changNotificationRead();
      const type = this.notification.type;
      const workflowId = this.notification.workflowId;
      switch (type) {
        case NOTIFY_TYPE.SHARE_WORKFLOW:
        case NOTIFY_TYPE.WORKFLOW_REACTION:
        case NOTIFY_TYPE.USER_WORKFLOW_RETRAIN:
          this.$emit("close-notifications");
          this.$router.push({
            name: "PlayerMain",
            params: { workflowId: workflowId },
          });
          break;
        case NOTIFY_TYPE.REVIEW_WORKFLOW:
          if (this.isMobileDevice) return;
          this.$emit("close-notifications");
          this.$router.push({
            name: ROUTE_NAME.BUILDER_MAIN,
            params: { workflowId: workflowId },
          });
          break;
        case NOTIFY_TYPE.ASSIGN_SKILL:
        case NOTIFY_TYPE.PUBLISH_SKILL:
        case NOTIFY_TYPE.UNPUBLISH_SKILL:
        case NOTIFY_TYPE.REMIND_SKILL:
        case NOTIFY_TYPE.ASSIGN_SKILL_DUE:
        case NOTIFY_TYPE.REMIND_SKILL_OVERDUE:
        case NOTIFY_TYPE.REMIND_SKILL_LEFT_DAYS:
          // because WorkflowPlayerMain would go back to the last path in beforeDestroy().
          // so add setTimeout here for changing router
          setTimeout(() => {
            this.$emit("close-notifications");
            this.$router.replace(`${ROUTE_PATH.SKILL_PLAYER}/${this.notification.skillId}`);
          }, 300);
          break;
        default:
          break;
      }
      Analytics.setTrack({
        category: "Navigator",
        action: "Notification",
        name: "Click Notification Content in the Notice Bell",
      });
    },
    async changNotificationRead() {
      if (this.notification.read) return;

      // change read in db
      // TODO: use API to change read in db
      const promises = [];
      this.senders.forEach((user) => {
        if (!user.read && user.notificationId) {
          const promise_fn = async () => {
            const notificationId = user.notificationId;
            await this.updateDocument("notifications", notificationId, {
              read: true,
            }).catch((err) => {
              console.warn("Faild to update notification read", err);
              return null;
            });
          };
          promises.push(promise_fn());
        }
      });
      await Promise.all(promises);

      //  change read in Vuex
      this.updateReadInNotifications({ index: this.index });
    },
  },
  computed: {
    cardType() {
      const type = this.notification.type;
      const matchedGroup = Object.entries(NOTIFY_CARD_GROUP).find(([_, values]) => values.includes(type));
      return matchedGroup ? matchedGroup[0] : "";
    },
    message() {
      const type = this.notification.type;
      const senderName = this.senders && this.senders[0] ? this.senders[0].senderName : "";
      switch (type) {
        case NOTIFY_TYPE.WORKFLOW_REACTION:
          const userNum = this.senders.length;
          if (userNum === 1) {
            return this.$t("notification.reactedWorkflowMsg", { user: senderName });
          } else if (userNum >= 2) {
            const senderName2 = this.senders[1].senderName;
            if (userNum === 2) {
              return this.$t("notification.reactedWorkflowMsgTwoUsers", { user1: senderName, user2: senderName2 });
            }
            const more = userNum - 2;
            return this.$t("notification.reactedWorkflowMsgMoreUsers", {
              user1: senderName,
              user2: senderName2,
              count: more,
            });
          } else {
            return this.$t("notification.reactedWorkflowMsg", { user: "" });
          }
        case NOTIFY_TYPE.SHARE_WORKFLOW:
          return `<span>${senderName}</span> ${this.$t("notification.sharedWorkflow")}`;
        case NOTIFY_TYPE.REVIEW_WORKFLOW:
          return `<span>${senderName}</span> ${this.$t("notification.askToReviewWorkflow")}`;
        case NOTIFY_TYPE.ASSIGN_SKILL:
          return `<span>${senderName}</span> ${this.$t("notification.assignedNewSkill")}`;
        case NOTIFY_TYPE.PUBLISH_SKILL:
          return `<span>${senderName}</span> ${this.$t("notification.updatedSkill")}`;
        case NOTIFY_TYPE.UNPUBLISH_SKILL:
          return `<span>${senderName}</span> ${this.$t("notification.unpublishedSkill")}`;
        case NOTIFY_TYPE.REMIND_SKILL:
          return `<span>${senderName}</span> ${this.$t("notification.sentAReminder")}`;
        case NOTIFY_TYPE.ASSIGN_SKILL_DUE:
          let timeText = "";
          const days = parseInt(this.notification.dueDays);
          if (days > 30) {
            const months = Math.floor(days / 30);
            timeText = this.$tc("time.numMonths", months);
          } else if (days > 7) {
            const weeks = Math.floor(days / 7);
            timeText = this.$tc("time.numWeeks", weeks);
          } else {
            timeText = this.$tc("time.numDays", days);
          }
          return `<span>${senderName}</span> ${this.$t("notification.assignedNewSkillDue", { time: timeText })}`;
        case NOTIFY_TYPE.REMIND_SKILL_OVERDUE:
          return this.$t("notification.delayedSkillMsg");
        case NOTIFY_TYPE.REMIND_SKILL_LEFT_DAYS:
          return this.$t("notification.daysLeftMsg", { time: this.$tc("time.numDays", this.notification.dueDaysLeft) });
        case NOTIFY_TYPE.USER_WORKFLOW_RETRAIN:
          return `<span>${this.$t("notification.workflowUpdatedContent")}</span>`;

        default:
          return "";
      }
    },
    timeSince() {
      return formatIsoStrTimeSince({
        isoStr: this.notification.latestNotificationCreatedAt,
        vueInstanceRef: this,
        simplify: true,
      });
    },
    videoTime() {
      if (this.cardType === NOTIFY_CARD_TYPE.WORKFLOW) {
        return getFormattedVideoDuration({ videoDuration: this.notification.workflow.videoDuration });
      }
      if ([NOTIFY_CARD_TYPE.SKILL, NOTIFY_CARD_TYPE.SKILL_SYSTEM].includes(this.cardType)) {
        return getFormattedVideoDuration({ videoDuration: this.notification.playlist.totalVideoDuration });
      }
    },
    videoInfo() {
      if (this.cardType === NOTIFY_CARD_TYPE.WORKFLOW) {
        return this.$tc("all.numStep", this.notification.workflow.numSteps);
      }
      if ([NOTIFY_CARD_TYPE.SKILL, NOTIFY_CARD_TYPE.SKILL_SYSTEM].includes(this.cardType)) {
        return this.$tc("skills.numVideo", this.notification.playlist.totalInList);
      }
    },
    senders() {
      return this.notification.activity.filter(Boolean);
    },
  },
};
</script>

<style lang="scss" scoped>
.NotificationCard {
  position: relative;
  width: 100%;
  padding: 16px 10px 20px 20px;
  border-bottom: 1px solid var(--dGrey3-color);
  background: var(--dGrey2-color);
  cursor: pointer;
  &:hover {
    background: var(--dGrey3-color);
  }
  &__mobile {
    background: transparent;
  }
  &__avatar {
    position: relative;
    height: 34px;
    width: 34px;
    &--icon {
      position: absolute;
      height: 20px;
      width: 20px;
      bottom: 0;
      right: -10px;
      border-radius: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--brand-white);
      font-size: 12px;
    }
    &--iconShareWorkflow {
      background-color: var(--success-color);
    }
    &--iconReviewWorkflow {
      padding-top: 2.5px;
      background: linear-gradient(180deg, #5966c2 0%, #4552ae 100%);
    }
    &--iconSkill {
      background-color: var(--danger-color);
    }
    &--iconSkillLg {
      height: 36px;
      width: 36px;
      border-radius: 18px;
      background-color: var(--danger-color);
      font-size: 22px;
      color: var(--brand-white);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--iconContentUpdate {
      background-color: var(--warning-color);
    }
    &--reaction {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
  &__workflowMessage {
    position: relative;
    width: 100%;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 18px;
    text-align: left;
    margin-top: 18px;
    overflow-wrap: break-word;
    hyphens: auto;
    color: var(--dGrey-6-color);
  }
  $iconSize: 24px;
  &__ReactionInfo {
    z-index: 0;
    position: relative;
    height: $iconSize;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 8px;
    top: 10px;
    & ::v-deep .ReactionsInfo__icon {
      position: relative;
      height: $iconSize;
      width: $iconSize;
      min-width: $iconSize;
      border-radius: 50%;
      background: var(--dGrey1-color);
      padding: 4px 0 0 5px;
      margin-left: -8px;
    }
  }
}
</style>
