<template>
  <label
    v-bind="attrs"
    class="checkbox__label gap-2 text-white"
    :class="{ 'checkbox__label-disabled': isDisabled }"
    :for="checkboxId"
  >
    <input
      :id="checkboxId"
      :name="name"
      type="checkbox"
      :checked="checked"
      :disabled="isDisabled"
      class="sr-only"
      @change="handleChange"
    />
    <span
      class="checkbox__icon"
      :class="[
        checked || indeterminate ? 'checkbox__icon-checked' : 'checkbox__icon',
        circle && 'checkbox__icon-circle',
        'text-white',
      ]"
    >
      <font-awesome-icon v-if="checked" :icon="['far', 'check']" />
      <font-awesome-icon v-else-if="indeterminate" :icon="['far', 'minus']" />
    </span>
    <span v-if="$slots.default || label">
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<script>
import { getRandomId } from "@/js/common/getRandom.js";

export default {
  name: "Checkbox",
  props: {
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    attrs() {
      return this.$attrs;
    },
    isDisabled() {
      return this.disabled || false;
    },
    name() {
      return `checkbox-${getRandomId()}`;
    },
    checkboxId() {
      return `${this.name}-${this.value}`;
    },
  },
  methods: {
    handleChange() {
      if (this.isDisabled || this.readonly) return;
      this.$emit("change", !this.checked);
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox__label {
  display: flex;
  cursor: pointer;

  &-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}
.checkbox__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #a5acbb;
  &.checkbox__icon-checked {
    background-color: var(--dBlue-color);
    border: none;
  }
  &.checkbox__icon-circle {
    border-radius: 50%;
  }
}
</style>
