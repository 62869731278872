import axios from "axios";
import services from "@/constants/env/services";
import deephowApiServerWrapper from "@/server/api-server";

export async function syncPrivatePlayerTrackingToServer({ trackingInfo }) {
  const payload = {
    ...trackingInfo,
    location: {},
  };
  const { ok, data, errorMessage } = await deephowApiServerWrapper("post", "/v1/tracking", payload);
  if (ok) {
    return { ok: true, data };
  } else {
    const customErrorMessage = `Failed to sync Private player tracking info to server - error: ${errorMessage}`;
    console.log(customErrorMessage);
    return { ok: false, errorMessage: customErrorMessage };
  }
}

const publicVideoTracker = axios.create({
  baseURL: services.deephowApiServer,
});

export async function syncPublicPlayerTrackingToServer({ trackingInfo }) {
  try {
    const payload = {
      ...trackingInfo,
      location: {},
    };
    const { status, statusText } = await publicVideoTracker.post("/v1/tracking", payload);
    const isSuccess = status === 200;
    if (isSuccess) {
      return { ok: true };
    }
    const error = `status: ${status}, statusText: ${statusText}`;
    throw error;
  } catch (error) {
    const errorMessage = `Failed to sync Public player tracking info to server -  error: ${error}`;
    console.log(errorMessage);
    return { ok: false, errorMessage };
  }
}

export const createMultimodalSOPTracking = (payload) =>
  deephowApiServerWrapper("post", `/v1/tracking/multimodal-sop`, payload);
