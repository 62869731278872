import deephowApiServerWrapper from "@/server/api-server";

export const getQuiz = async (quizId) => deephowApiServerWrapper("get", `/v1/quiz/${quizId}/`);

export const getUserQuizByQuizId = async ({ id, skillId }) =>
  deephowApiServerWrapper("get", `/v1/quiz/${id}/user-quiz`, { params: { userOrTeamSkillId: skillId } });

export const getWorkflowWithQuiz = async () => {
  return deephowApiServerWrapper("get", "/v1/quiz/published-status");
};

export const postCreateQuiz = async ({ id, questions }) =>
  deephowApiServerWrapper("post", `v1/quiz`, { workflow: id, questions });

export const putEditedQuiz = async ({ questionId, published, questions }) =>
  deephowApiServerWrapper("put", `v1/quiz/${questionId}`, { published, questions });
