import { USER_ROLE } from "./userRole";

export const INITIAL_ACCESS = {
  admin: false,
  analytics: false,
  categories: true,
  categoriesCreation: false,
  companyLogo: false,
  editor: false,
  isOrgAdmin: false,
  languages: false,
  masterPassword: false,
  permissions: false,
  player: false,
  skills: false,
  ssoMapping: false,
  teams: false,
};

export const ROLE_ACCESS = {
  [USER_ROLE.ADMIN_ORG]: {
    admin: true,
    analytics: true,
    categoriesCreation: true,
    companyLogo: true,
    editor: true,
    isOrgAdmin: true,
    languages: true,
    masterPassword: true,
    permissions: true,
    player: true,
    skills: true,
    ssoMapping: true,
    teams: true,
  },
  [USER_ROLE.ADMIN_SUPER]: {
    admin: true,
    analytics: true,
    categoriesCreation: true,
    companyLogo: true,
    editor: true,
    isOrgAdmin: true,
    languages: true,
    masterPassword: true,
    permissions: true,
    player: true,
    skills: true,
    ssoMapping: true,
    teams: true,
  },
  [USER_ROLE.ADMIN_WORKSPACE]: {
    admin: true,
    analytics: true,
    editor: true,
    player: true,
    skills: true,
    teams: true,
  },
  [USER_ROLE.PUBLISHER]: {
    player: true,
    editor: true,
    skills: true,
  },
  [USER_ROLE.VIEWER]: {
    player: true,
  },
};
