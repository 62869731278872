import axios from "axios";
import services from "@/constants/env/services";
import authChoice from "../../auth";
import Analytics from "../js/analytics/analytics";
import apiWrapper from "@/server/api-server";

const searchServer = axios.create({
  baseURL: services.search,
});

searchServer.interceptors.request.use(
  async (config) => {
    config.data.sessionId = Analytics.getSessionId();
    return config;
  },
  (error) => Promise.resolve(error)
);

export const postSearch = async ({ payload, path }) => {
  if (!payload.token) {
    payload.token = await authChoice().currentUser.getIdToken();
  }
  const { status, data, statusText } = await searchServer.post(path, payload);
  if (status === 200) {
    return { ok: true, data };
  } else {
    return {
      ok: false,
      errorMessage: `statusText: ${statusText}, status: ${status}`,
    };
  }
};

export const fetchWorkflows = (params) => apiWrapper("post", "/v1/workflows/search", params);

export const fetchMMSOPs = (params) => apiWrapper("get", "/v1/search/mmsop", { params });

export const fetchWorkflowSOPs = (params) => apiWrapper("get", "/v1/search/workflows", { params });

export default searchServer;
