import axios from "axios";
import { apiWrapperGenerator } from "./lib";
import services from "@/constants/env/services";

// NOTICE: this API is not support china version, so the value is null
const summarizationServer = axios.create({
  baseURL: services.summarizationServer,
});

summarizationServer.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.resolve(error)
);
const apiWrapper = apiWrapperGenerator(summarizationServer);

export const postGetWorkflowKeypoints = (
  payload = {
    workflow_id: "",
    oid: "",
    token: "",
  }
) => apiWrapper("post", `/workflow/keypoints`, payload);
