import db from "../../../db.js";

const FIELDS = {
  ORGANIZATION: "organization",
};

export const namespaced = true;

export const state = {
  businesses: [],
};

export const getters = {
  businessNameMap: (state) => state.businesses.reduce((result, { name, id }) => ({ ...result, [id]: name }), {}),
  businessList: (state) => state.businesses,
};

export const mutations = {
  SET_BUSINESSES(state, businesses) {
    state.businesses = businesses;
  },
  ADD_BUSINESS(state, business) {
    state.businesses.push(business);
  },
  UPDATE_BUSINESS_NAME(state, { id, data }) {
    const index = state.businesses.findIndex((b) => b.id === id);
    if (index !== -1) {
      state.businesses.splice(index, 1, { ...state.businesses[index], ...data });
    }
  },
};

export const actions = {
  async fetchBusinesses({ commit }, { organization }) {
    const data = { field: FIELDS.ORGANIZATION, comparison: "==", value: organization };
    const businesses = await db.getDocumentByQuery("businesses", [data]);

    commit("SET_BUSINESSES", businesses);
  },

  async createBusiness({ commit }, { name, organization }) {
    const id = db.generateDocId("businesses");
    const data = { id, name, organization };
    await db.addDocument("businesses", id, data);

    commit("ADD_BUSINESS", data);
  },

  async updateBusiness({ commit }, { id, name }) {
    await db.updateDocument("businesses", id, { name });

    commit("UPDATE_BUSINESS_NAME", { id, data: { name } });
  },
};
