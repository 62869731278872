<template>
  <d-dialog
    class="DCreateSkillPopup"
    :isConfirmDisabled="!formValid || !noWhiteSpaceRule.test(playlistTitle) || isCreating"
    :confirmText="$t('skills.create')"
    @cancel="close"
    @close="close"
    @confirm="createPlaylist"
    height="80%"
    :autotest="{
      close: 'create-skill-close',
      cancel: 'create-skill-cancel',
      confirm: 'create-skill-create',
    }"
  >
    <template slot="header">
      <d-dialog-icon-header :title="$t('skills.createSkill')" :subtitle="$t('skills.createSkillSubtitle')">
        <template slot="icon">
          <d-icon-create-skill />
        </template>
      </d-dialog-icon-header>
    </template>

    <v-form v-model="formValid" autocomplete="off">
      <d-text-field
        v-model="playlistTitle"
        :label="$t('skills.skillName')"
        :rules="[rules.required, rules.noWhiteSpace, rules.max, rules.playlistTitleAllowed]"
        :counter="90"
        autofucus
        dark
        autotest="create-skill-skill-name"
        class="skill-name-text-field"
        @keydown.native="noSpace($event)"
      />
      <d-composite-workspace-dropdown
        :workspaceId="currentWorkspaceId"
        :workspaceLabel="$t('new.addToWorkspace')"
        :workspaceGroupId="currentWorkspaceGroupId"
        init-default-workspaces
        isBoxStyle
        @change:workspaceId="(value) => (currentWorkspaceId = value)"
        @change:workspaceGroupId="(value) => (currentWorkspaceGroupId = value)"
      />
    </v-form>
  </d-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import MixinVideo from "@/components/MixinVideo.vue";
import MixinDB from "@/components/MixinDB.vue";
import { postPlaylists } from "@/server/skill-server.js";
import DDialogIconHeader from "@/components/DDialog/DDialogIconHeader.vue";
import DIconCreateSkill from "@/components/icons/composite/DIconCreateSkill.vue";
import DTextField from "@/components/ui_components/DTextField.vue";
import DCompositeWorkspaceDropdown from "@/components/DStudio/DCompositeWorkspaceDropdown.vue";
import { checkPlaylistTitleCharacters } from "@/js/character-validator/character-validator.js";
import { ROUTE_NAME } from "@/router/route";

export default {
  name: "DCreateSkillPopup",
  mixins: [MixinVideo, MixinDB],

  components: {
    DIconCreateSkill,
    DCompositeWorkspaceDropdown,
    DDialogIconHeader,
    DTextField,
  },
  data() {
    return {
      isCreating: false,
      playlistTitle: "",
      currentWorkspaceId: "",
      currentWorkspaceGroupId: "",
      formValid: false,
      noWhiteSpaceRule: /\s*\S+/,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        max: (v) => (v && v.length <= 90) || this.$t("rules.max90"),
        noWhiteSpace: (value) => {
          const pattern = /\s*\S+/;
          return pattern.test(value) || this.$t("rules.noWhiteSpace");
        },
        playlistTitleAllowed: () => !this.playlistTitleNotAllowed || this.$t("rules.invalidName"),
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["getter_user_organization", "getter_org_default_workspace"]),
    ...mapGetters("workspaceGroups", ["getter_get_edit_workspace_group_by_workspace_id"]),
    playlistTitleNotAllowed() {
      return checkPlaylistTitleCharacters(this.playlistTitle);
    },
  },

  methods: {
    ...mapActions("workspace", ["setEditorWorkspace"]),
    close() {
      this.$emit("close");
    },
    async createPlaylist() {
      this.setEditorWorkspace(this.currentWorkspaceId);
      this.isCreating = true;
      const { ok, data } = await postPlaylists({
        organization: this.getter_user_organization,
        group: this.currentWorkspaceId,
        playlistTitle: this.playlistTitle,
      });
      if (ok) {
        this.$router.push({
          name: ROUTE_NAME.SKILL_BUILDER_CONTENT,
          params: {
            playlistId: data.item.id,
          },
        });
        this.close();
      } else {
        this.openAlert({
          type: "error",
          message: this.$t("skills.alertFailedSavedSkill"),
        });
      }
      this.isCreating = false;
    },
    setCurrentWorkspace() {
      this.currentWorkspaceId = this.$route.params.workspaceId
        ? this.$route.params.workspaceId
        : this.getter_org_default_workspace;
      this.currentWorkspaceGroupId = this.getWorkspaceGroupId(this.currentWorkspaceId);
    },
    getWorkspaceGroupId(workspaceId) {
      const workspaceGroup = this.getter_get_edit_workspace_group_by_workspace_id(workspaceId);
      return (workspaceGroup && workspaceGroup.id) || "";
    },
    noSpace(event) {
      if (event.key === " " && this.playlistTitle == "") {
        event.preventDefault();
      }
    },
  },
  created() {
    this.setCurrentWorkspace();
  },
};
</script>
<style lang="scss" scoped></style>
