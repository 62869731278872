<template>
  <div class="PlayAnnotation">
    <div
      class="PlayAnnotation__inner"
      :style="{
        width: fixedVideoWidthHeightRatioSize.width + 'px',
        height: fixedVideoWidthHeightRatioSize.height + 'px',
      }"
    >
      <canvas
        id="PlayAnnotation__canvas"
        ref="PlayAnnotation__canvas"
        class="PlayAnnotation__canvas"
        :width="fixedVideoWidthHeightRatioSize.width + 'px'"
        :height="fixedVideoWidthHeightRatioSize.height + 'px'"
        :style="{ opacity: +!isHideCanvas }"
      >
      </canvas>
      <div v-for="blurItem in blurItems" :key="blurItem.id" class="blurItem" :style="blurItem.style" />
    </div>
  </div>
</template>

<script>
import { AnnotationFabric } from "@/js/annotation/AnnotationFabric";
import { mapGetters } from "vuex";
import { debounce } from "lodash-es";
import { CANVAS_HEIGHT, ANNOTATION_TYPE, SHAPE } from "@/constants/annotationStatus";
import { checkNotoSerifFontFamilyLoaded, getBlurItems } from "@/js/annotation";

export default {
  name: "PlayerAnnotation",
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    videoData: {
      type: Object,
      default: () => ({
        clientWidth: 1920,
        clientHeight: 944,
        currentStepIndex: 0,
        currentVideoTime: 0,
      }),
    },
    isHideCanvas: {
      type: Boolean,
      required: true,
    },
  },
  async mounted() {
    this.isFontReady = await checkNotoSerifFontFamilyLoaded();
    this.AnnotationFabric = new AnnotationFabric({
      canvasId: "PlayAnnotation__canvas",
      sourceCanvas: this.$refs["PlayAnnotation__canvas"],
      isHideCanvas: this.isHideCanvas,
    });
  },
  data() {
    return {
      AnnotationFabric: null,
      isFontReady: false,
    };
  },
  methods: {
    loadAnnotationData(dataArray) {
      this.$emit("update:isHideCanvas", true);
      this.AnnotationFabric.loadData(dataArray);
    },
  },
  computed: {
    ...mapGetters("annotation", ["getters_get_annotation_by_id"]),
    currentStepId() {
      return this.workflow.steps[this.videoData.currentStepIndex];
    },
    fixedVideoWidthHeightRatioSize() {
      const { clientHeight, clientWidth } = this.videoData;
      const maxWidth = clientHeight * (16 / 9);
      const maxHeight = clientWidth * (9 / 16);

      if (maxWidth <= clientWidth) {
        return { width: maxWidth, height: clientHeight };
      } else {
        return { width: clientWidth, height: maxHeight };
      }
    },
    sourceDataArray() {
      const targetAnnotation = this.getters_get_annotation_by_id(this.currentStepId);
      return targetAnnotation?.elements ?? [];
    },
    dataArray() {
      return this.sourceDataArray.filter(({ type }) => type !== ANNOTATION_TYPE.BLUR);
    },
    blurItems() {
      const ratio = this.fixedVideoWidthHeightRatioSize.height / CANVAS_HEIGHT;
      return getBlurItems(this.sourceDataArray, ratio, this.videoData.currentVideoTime);
    },
  },
  watch: {
    isFontReady(isFontReady) {
      if (isFontReady) {
        this.loadAnnotationData(this.dataArray);
      }
    },
    dataArray(dataArray) {
      this.loadAnnotationData(dataArray);
    },
    fixedVideoWidthHeightRatioSize: debounce(function (fixedVideoWidthHeightRatioSize) {
      if (this.AnnotationFabric) {
        const { currentVideoTime, currentStepIndex } = this.videoData;
        this.AnnotationFabric.handleResize(fixedVideoWidthHeightRatioSize);
        this.AnnotationFabric.loadData(this.dataArray);
        this.AnnotationFabric.detectIsAnnotationShowUp(currentVideoTime, this.currentStepId);
      }
    }, 500),
    "videoData.currentVideoTime"(currentVideoTime) {
      if (this.AnnotationFabric) {
        this.AnnotationFabric.detectIsAnnotationShowUp(currentVideoTime, this.currentStepId);
        this.$emit("update:isHideCanvas", false);
      }
    },
  },
};
</script>

<style scoped>
.PlayAnnotation {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PlayAnnotation__inner {
  position: relative;
}

.blurItem {
  position: absolute;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(20px);
}
</style>
