import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faVideo,
  faAngleLeft,
  faBan,
  faFill,
  faBorderBottomRight,
  faBallotCheck,
  faBolt,
  faBookmark,
  faBullseyeArrow,
  faPaperclip,
  faCheck,
  faListUl,
  faA,
  faHighlighter,
  faPause,
  faBold,
  faItalic,
  faClosedCaptioning,
  faClosedCaptioningSlash,
  faCalendar,
  faChartColumn,
  faChartPie,
  faChartSimple,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faClipboardListCheck,
  faEllipsis,
  faEye,
  faGear,
  faHouse,
  faKey,
  faListDropdown,
  faUnlock,
  faLock,
  faLockKeyhole,
  faMicrochipAi,
  faPen,
  faPlay,
  faQuoteLeft,
  faRobot,
  faScrewdriverWrench,
  faTriangleExclamation,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faStarOfLife,
  faStopwatch,
  faArrowRight,
  faIndustryWindows,
  faCircleChevronLeft,
  faCircleChevronRight,
  faMessageCheck,
  faCrown,
  faUsers,
  faFilter,
  faPlus,
  faCamera,
  faFileExport,
  faHeart,
  faMessagePen,
  faThumbsUp,
  faLayerGroup,
  faCirclePlay,
  faCirclePause,
  faForwardStep,
  faArrowRotateRight,
  faBlockQuestion,
  faFilePdf,
  faHighDefinition,
  faStandardDefinition,
  faGauge,
  faLanguage,
  faRepeat,
  faCircleInfo,
  faMagnifyingGlassWaveform,
  faFileInvoice,
  faEllipsisVertical,
  faThumbTack,
} from "@fortawesome/pro-solid-svg-icons";

const solid = {
  addIcons() {
    library.add(
      faVideo,
      faPaperclip,
      faBan,
      faFill,
      faBorderBottomRight,
      faCheck,
      faListUl,
      faA,
      faHighlighter,
      faPause,
      faBold,
      faItalic,
      faClosedCaptioning,
      faClosedCaptioningSlash,
      faCalendar,
      faStopwatch,
      faChartColumn,
      faCircle,
      faEllipsis,
      faSort,
      faSortUp,
      faSortDown,
      faSpinner,
      faArrowRight,
      faIndustryWindows,
      faChevronDown,
      faChevronUp,
      faScrewdriverWrench,
      faAngleLeft,
      faKey,
      faCircleCheck,
      faCircleXmark,
      faClipboardListCheck,
      faEye,
      faPlay,
      faBolt,
      faGear,
      faChartSimple,
      faBullseyeArrow,
      faRobot,
      faChartPie,
      faStarOfLife,
      faLockKeyhole,
      faListDropdown,
      faTriangleExclamation,
      faQuoteLeft,
      faMicrochipAi,
      faHouse,
      faPen,
      faUnlock,
      faLock,
      faBookmark,
      faCircleChevronLeft,
      faCircleChevronRight,
      faBallotCheck,
      faMessageCheck,
      faCrown,
      faUsers,
      faFilter,
      faPlus,
      faCamera,
      faFileExport,
      faHeart,
      faMessagePen,
      faThumbsUp,
      faLayerGroup,
      faCirclePlay,
      faCirclePause,
      faForwardStep,
      faArrowRotateRight,
      faBlockQuestion,
      faFilePdf,
      faHighDefinition,
      faStandardDefinition,
      faGauge,
      faLanguage,
      faRepeat,
      faListDropdown,
      faCircleInfo,
      faMagnifyingGlassWaveform,
      faFileInvoice,
      faEllipsisVertical,
      faThumbTack
    );
  },
};

export default solid;
