import { ROUTE_NAME, ROUTE_PATH } from "../route.js";

const WorkspaceRoute = [
  {
    path: `${ROUTE_PATH.WORKSPACE}/:workspaceId`,
    name: ROUTE_NAME.WORKSPACE,
    component: () => import("@/components/pages/Workspace/Workspace.vue"),
    redirect: { name: ROUTE_NAME.WORKSPACE_WORKFLOW },
    meta: { auth: true },
    children: [
      {
        path: ROUTE_PATH.WORKSPACE_WORKFLOW,
        name: ROUTE_NAME.WORKSPACE_WORKFLOW,
        component: () => import("@/components/pages/Workspace/WorkspaceWorkflow.vue"),
        meta: { withoutFooterNav: true },
      },
      {
        path: ROUTE_PATH.WORKSPACE_MM_SOP,
        name: ROUTE_NAME.WORKSPACE_MM_SOP,
        component: () => import("@/components/pages/Workspace/WorkspaceMMSOP.vue"),
        meta: { withoutFooterNav: true },
      },
      {
        path: ROUTE_PATH.WORKSPACE_SKILLS,
        name: ROUTE_NAME.WORKSPACE_SKILLS,
        component: () => import("@/components/pages/Workspace/WorkspaceSkill.vue"),
      },
      {
        path: ROUTE_PATH.WORKSPACE_SKILL_MATRIX,
        name: ROUTE_NAME.WORKSPACE_SKILL_MATRIX,
        component: () => import("@/components/pages/Workspace/WorkspaceSkillMatrix.vue"),
      },
      {
        path: ROUTE_PATH.WORKSPACE_INSIGHTS,
        name: ROUTE_NAME.WORKSPACE_INSIGHTS,
        component: () => import("@/components/pages/Workspace/WorkspaceInsight.vue"),
      },
    ],
  },
];
export default WorkspaceRoute;
