<template>
  <div class="NavWorkspaceItem" :class="{ 'NavWorkspaceItem--active': isWorkspaceActive }" @click="goToWorkspace">
    <div class="NavWorkspaceItem__cube" :style="{ background: getWorkspaceColor() }"></div>
    <div class="NavWorkspaceItem__content">
      <div v-if="workspaceGroup" class="NavWorkspaceItem__content-groupName">{{ workspaceGroup }}</div>
      <div class="NavWorkspaceItem__content-name">
        <span class="NavWorkspaceItem__content-name--name">{{ workspace.name }}</span>
        <font-awesome-icon
          v-if="workspace.private"
          class="NavWorkspaceItem__content-name--icon"
          :icon="['fas', 'lock']"
        />
      </div>
    </div>
    <font-awesome-icon
      class="NavWorkspaceItem__favorite"
      :class="{
        'NavWorkspaceItem__favorite--active': workspace.isLike,
      }"
      :icon="['fas', 'bookmark']"
      @click.stop="setFavoriteWorkspace"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { getWorkspaceColorCss } from "@/js/workspace-color/workspace-color";

import { setWorkspaceFavorite } from "@/server/user-likes-server";
import { loggingError } from "@/server/error-log-server";
import { ROUTE_PATH } from "@/router/route";

import eventbus from "@/main";
import { EVENT } from "@/constants/globalEvents";
import { IS_MOBILE } from "@/constants/device-version";

export default {
  name: "NavWorkspaceItem",
  props: {
    workspace: {
      type: Object,
      required: true,
    },
    workspaceGroup: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("workspaceColor", ["workspaceColorsConfig"]),
    isWorkspaceActive() {
      return this.$route.params.workspaceId === this.workspace.id;
    },
  },
  methods: {
    ...mapActions("global", ["closeSideBar"]),
    goToWorkspace() {
      const path = `${ROUTE_PATH.WORKSPACE}/${this.workspace.id}`;
      this.$router.push(path);
      if (IS_MOBILE) {
        this.closeSideBar();
      }
    },
    getWorkspaceColor() {
      const defaultCss = "linear-gradient(180deg, #4A8CF1 0%, #3678DD 100%)";
      if (!this.workspaceColorsConfig || Object.keys(this.workspaceColorsConfig).length === 0) {
        return defaultCss;
      }
      const selectedColor = this.workspace.color || "blue";
      return getWorkspaceColorCss(selectedColor, this.workspaceColorsConfig);
    },
    async setFavoriteWorkspace() {
      try {
        const { ok, errorMessage } = await setWorkspaceFavorite({
          workspaceId: this.workspace.id,
          like: !this.workspace.isLike,
        });
        if (!ok) {
          console.error({ errorMessage });
          return;
        }
        eventbus.$emit(EVENT.UPDATE_WORKSPACE_INFO);
      } catch (error) {
        console.error({ error });
        loggingError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.NavWorkspaceItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;

  cursor: pointer;
  padding: 15px 24px;
  color: var(--brand-white);

  &--active,
  &:hover {
    background: var(--dGrey3-color);
    .NavWorkspaceItem__favorite {
      display: block;
    }
  }

  &__cube {
    flex: 0 0 auto;
    width: 30px;
    height: 30px;
    border-radius: var(--spacing-02);
  }
  &__content {
    flex: 1;
    overflow: hidden;
  }

  &__favorite {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    padding: 4px;
  }
}

.NavWorkspaceItem__content {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-02);
  &-groupName {
    text-align: start;
    font-size: 12px;
    font-weight: 700;
    color: #f9fafb80;
    @include truncate-text();
  }
  &-name {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--name {
      font-size: 14px;
      @include truncate-text();
    }
    &--icon {
      font-size: 12px;
      color: var(--dGrey6-color);
      margin-left: var(--spacing-03);
    }
  }
}

.NavWorkspaceItem__favorite {
  display: none;
  color: var(--dLigthGrey400-color);
  &--active,
  &:hover {
    color: var(--brand-blue);
  }
}
</style>
