import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCirclePlus,
  faCircleMinus,
  faBullseyeArrow,
  faPhotoFilmMusic,
  faTriangleExclamation,
  faFile,
  faBallotCheck,
} from "@fortawesome/pro-thin-svg-icons";

const thin = {
  addIcons() {
    library.add(
      faCirclePlus,
      faCircleMinus,
      faBullseyeArrow,
      faPhotoFilmMusic,
      faTriangleExclamation,
      faFile,
      faBallotCheck
    );
  },
};

export default thin;
