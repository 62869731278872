import { ROUTE_NAME, ROUTE_PATH } from "../route";

export const ANALYTICS_ROUTE_NAMES = [
  ROUTE_NAME.ANALYTICS,
  ROUTE_NAME.ANALYTICS_ORG,
  ROUTE_NAME.ANALYTICS_WORKSPACES,
  ROUTE_NAME.ANALYTICS_WORKFLOWS,
  ROUTE_NAME.ANALYTICS_WORKFLOW,
  ROUTE_NAME.ANALYTICS_QUIZ,
  ROUTE_NAME.ANALYTICS_QUIZ_WORKFLOW,
  ROUTE_NAME.ANALYTICS_QUIZ_WORKFLOW_USER,
  ROUTE_NAME.ANALYTICS_USERS,
  ROUTE_NAME.ANALYTICS_USER,
  ROUTE_NAME.ANALYTICS_WORKFLOWS_DETAIL,
  ROUTE_NAME.ANALYTICS_WORKFLOWS_TREND,
  ROUTE_NAME.ANALYTICS_MM_SOPS_DETAIL,
  ROUTE_NAME.ANALYTICS_MM_SOPS_TREND,
  ROUTE_NAME.ANALYTICS_SKILLS_PERFORMANCE,
  ROUTE_NAME.ANALYTICS_QUIZ_PERFORMANCE,
];

const AnalyticsRoute = [
  {
    path: ROUTE_PATH.ANALYTICS,
    name: ROUTE_NAME.ANALYTICS,
    component: () => import("@/components/pages/Analytics/Analytics.vue"),
    redirect: { name: ROUTE_NAME.ANALYTICS_ORG },
    meta: { auth: true },
    children: [
      {
        path: ROUTE_PATH.ANALYTICS_ORG,
        name: ROUTE_NAME.ANALYTICS_ORG,
        component: () => import("@/components/pages/Analytics/AnalyticsOrganization.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_WORKSPACES,
        name: ROUTE_NAME.ANALYTICS_WORKSPACES,
        component: () => import("@/components/pages/Analytics/AnalyticsWorkspaces.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_WORKFLOWS,
        name: ROUTE_NAME.ANALYTICS_WORKFLOWS,
        meta: { keepAlive: true },
        component: () => import("@/components/pages/Analytics/AnalyticsWorkflows.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_WORKFLOWS_TREND,
        name: ROUTE_NAME.ANALYTICS_WORKFLOWS_TREND,
        meta: { keepAlive: true },
        component: () => import("@/components/pages/Analytics/AnalyticsWorkflowsTrend.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_WORKFLOWS_DETAIL,
        name: ROUTE_NAME.ANALYTICS_WORKFLOWS_DETAIL,
        meta: { keepAlive: true },
        component: () => import("@/components/pages/Analytics/AnalyticsWorkflowsDetail.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_MM_SOPS_TREND,
        name: ROUTE_NAME.ANALYTICS_MM_SOPS_TREND,
        meta: { keepAlive: true },
        component: () => import("@/components/pages/Analytics/AnalyticsMMSOPsTrend.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_MM_SOPS_DETAIL,
        name: ROUTE_NAME.ANALYTICS_MM_SOPS_DETAIL,
        meta: { keepAlive: true },
        component: () => import("@/components/pages/Analytics/AnalyticsMMSOPsDetail.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_WORKFLOW,
        name: ROUTE_NAME.ANALYTICS_WORKFLOW,
        component: () => import("@/components/pages/Analytics/AnalyticsWorkflow.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_SKILLS_PERFORMANCE,
        name: ROUTE_NAME.ANALYTICS_SKILLS_PERFORMANCE,
        component: () => import("@/components/pages/Analytics/AnalyticsSkillsPerformance.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_QUIZ,
        name: ROUTE_NAME.ANALYTICS_QUIZ,
        component: () => import("@/components/pages/Analytics/AnalyticsQuizWorkflows.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_QUIZ_PERFORMANCE,
        name: ROUTE_NAME.ANALYTICS_QUIZ_PERFORMANCE,
        component: () => import("@/components/pages/Analytics/AnalyticsQuizPerformance.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_QUIZ_WORKFLOW,
        name: ROUTE_NAME.ANALYTICS_QUIZ_WORKFLOW,
        component: () => import("@/components/pages/Analytics/AnalyticsQuizWorkflow.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_QUIZ_WORKFLOW_USER,
        name: ROUTE_NAME.ANALYTICS_QUIZ_WORKFLOW_USER,
        component: () => import("@/components/pages/Analytics/AnalyticsQuizWorkflowUser.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_USERS,
        name: ROUTE_NAME.ANALYTICS_USERS,
        meta: { keepAlive: true },
        component: () => import("@/components/pages/Analytics/AnalyticsUsers.vue"),
      },
      {
        path: ROUTE_PATH.ANALYTICS_USER,
        name: ROUTE_NAME.ANALYTICS_USER,
        component: () => import("@/components/pages/Analytics/AnalyticsUser.vue"),
      },
    ],
  },
];

export default AnalyticsRoute;
