<template>
  <d-dropdown-menu :trigger="isTabletDevice || isMobileDevice ? 'click' : 'hover'">
    <button class="language-btn text-no-wrap">
      <font-awesome-icon class="hidden-sm-and-up language-icon" :icon="['fal', 'globe']" />
      <span class="body-2 text-capitalize hidden-xs-only button-text">
        {{ getUILocaleName(language) }}
        <font-awesome-icon class="language-btn__icon" :icon="['fas', 'chevron-down']" />
      </span>
    </button>

    <template slot="dropdown">
      <d-dropdown-menu-item v-for="lang in languages" :key="lang" :active="lang === language" @click="changeLang(lang)">
        {{ getUILocaleName(lang) }}
      </d-dropdown-menu-item>
    </template>
  </d-dropdown-menu>
</template>

<script>
import { UI_LOCALES, getUILocaleName } from "@/lang/i18n";

export default {
  name: "DAuthHeaderLanguageMenu",
  props: {
    isEmitChangeUrl: Boolean,
    isMobileDevice: Boolean,
    isTabletDevice: Boolean,
  },
  data() {
    return {
      languages: UI_LOCALES,
      language: this.$i18n.locale || "en",
      isMenuOpened: false,
    };
  },
  methods: {
    onFocusLanguageMenu() {
      this.isMenuOpened = true;
    },
    getUILocaleName,
    changeLang(newLanguage) {
      if (newLanguage == this.language) return;
      this.$i18n.locale = newLanguage;
      this.language = newLanguage;
      localStorage.setItem("locale", newLanguage);
      if (this.isEmitChangeUrl) {
        this.$emit("change-lang-url");
      }
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.language-btn {
  position: relative;
  height: 22px;
  color: var(--brand-white);
  padding-left: 22px;
  &__icon {
    color: var(--brand-blue);
    font-size: 12px;
    margin-left: var(--spacing-03);
  }

  &:focus-visible {
    outline: var(--focus-visible-outline);
  }
}
.language-icon {
  color: var(--dLigthGrey400-color);
  position: absolute;
  left: 0;
  top: -1px;
  font-size: 22px;
}
.button-text {
  color: var(--dLigthGrey400-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-left: 12px;
}
</style>
