<template>
  <div class="CommentInputBox">
    <router-link :to="{ name: 'UserProfileMain', params: { uid: getter_user_id } }" target="_blank">
      <div class="CommentInputBox__avatar">
        <d-avatar :size="32" :userId="getter_user_id" />
      </div>
    </router-link>
    <div class="CommentInputBox__text">
      <div class="CommentInputBox__input">
        <div class="CommentInputBox__input--textarea">
          <textarea
            v-model.trim="commentInput"
            rows="1"
            ref="commentInput"
            :placeholder="$t('player.writeAComment')"
            :disabled="isSending"
            @focus="(isEditing = true), setIsStartTyping()"
            @blur="(isEditing = false), setIsEndTyping()"
            @input="autoSetTextareaHeight()"
          ></textarea>
          <div v-if="commentInput.length > maxCharacters" class="CommentInputBox__errorText">
            {{ $tc("rules.numMaxCharacters", this.maxCharacters) }}
          </div>
        </div>
      </div>
      <div class="CommentInputBox__btns" :class="{ 'CommentInputBox__btns--hide': hideBtns }">
        <d-button
          dark
          depressed
          round
          small
          color="#454545"
          class="text-capitalize mb-0 mr-1 mt-3"
          :disabled="isSending"
          @click="cancelComment()"
          >{{ $t("all.cancel") }}</d-button
        >
        <d-button
          dark
          depressed
          round
          small
          color="#4689F3"
          class="white--text text-capitalize mr-0 mb-0 mt-3"
          :disabled="disableSendBtn"
          @click="sendComment()"
        >
          <v-progress-circular v-if="isSending" size="12" width="1" class="mr-1" indeterminate></v-progress-circular>
          {{ $t("player.comment") }}
        </d-button>
      </div>
    </div>
  </div>
</template>

<script>
import DAvatar from "@/components/ui_components/DAvatar.vue";
import { postCreateNewComment } from "@/server/api-server.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CommentInputBox",
  components: {
    DAvatar,
  },
  props: {
    workflowId: String,
  },
  data() {
    return {
      commentInput: "",
      isSending: false,
      isEditing: false,
      maxCharacters: 1000,
    };
  },
  methods: {
    ...mapActions("workflowPlayer", ["setIsStartTyping", "setIsEndTyping"]),
    openUserProfile() {
      this.$emit("close-player");
      // because WorkflowPlayerMain would go back to the last path in beforeDestroy().
      // so add setTimeout here for changing router
      setTimeout(() => {
        this.$router.push({
          name: "UserProfileMain",
          params: { uid: this.comment.uid },
        });
      }, 300);
    },
    cancelComment() {
      this.isEditing = false;
      this.commentInput = "";
      this.$nextTick(() => {
        this.autoSetTextareaHeight();
      });
    },
    async sendComment() {
      this.isSending = true;
      let comment = this.commentInput;
      const params = {
        id: this.workflowId,
        level: "workflows",
        comment,
      };
      const { ok, errorMessage } = await postCreateNewComment(params);
      if (ok) {
        this.$emit("send-comment-success");
        this.commentInput = "";
        this.$nextTick(() => {
          this.autoSetTextareaHeight();
        });
      } else {
        console.error(errorMessage);
      }
      this.isSending = false;
    },
    autoSetTextareaHeight() {
      const ref = "commentInput";
      this.$refs[ref].style.height = "auto";
      const textareaHeight = this.$refs[ref].scrollHeight;
      this.$refs[ref].style.height = textareaHeight + "px";
    },
  },
  computed: {
    ...mapGetters("auth", ["getter_user_id"]),
    hideBtns() {
      return !this.isEditing && !this.commentInput;
    },
    disableSendBtn() {
      return !this.commentInput || this.isSending || this.commentInput.length > this.maxCharacters;
    },
  },
};
</script>

<style lang="scss" scoped>
.CommentInputBox {
  position: relative;
  display: flex;
  padding-top: 12px;
  padding-right: 12px;
  border-bottom: 1px solid #33353a;
  &__avatar {
    padding: 8px 8px 8px 12px;
    cursor: pointer;
  }
  &__text {
    position: relative;
    width: 100%;
    padding-bottom: 16px;
  }
  &__input {
    position: relative;
    width: 100%;
    background: #121314;
    border: 1px solid #33353a;
    box-shadow: inset 0px 1px 0px #121212;
    border-radius: 4px;
    text-align: left;
    &--textarea {
      position: relative;
      padding: 12px 16px 8px 16px;
      width: 100%;
      textarea {
        pointer-events: auto;
        padding: 0;
        width: 100%;
        resize: none;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        overflow-y: auto;
      }
      textarea::placeholder {
        color: #919191;
      }
      textarea:focus {
        outline: none;
      }
    }
  }
  &__errorText {
    position: relative;
    padding: 4px 0 0 0;
    color: #888888;
    font-size: 12px;
    line-height: 14px;
  }
  &__btns {
    height: 46px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    transition: 0.3s;
    &--hide {
      height: 0px;
      transition: 0.3s;
    }
  }
}
</style>
