<template>
  <nav-collapse ref="myWorkspacesRef" class="NavMyWorkspaceMenu" :title="$t('sidemenu.myWorkspaces')">
    <nav-workspace-item
      v-for="(workspace, idx) in fullWorkspaceList"
      :key="`${workspace.id}-${idx}`"
      :workspace="workspace"
      :workspaceGroup="workspace.workspaceGroupName"
    />
    <div v-if="fullWorkspaceList.length === 0" class="NavMyWorkspaceMenu__noResult">
      {{ $t("sidemenu.noWorkspaces") }}
    </div>
  </nav-collapse>
</template>

<script>
import { mapGetters } from "vuex";

import eventbus from "@/main";
import { EVENT } from "@/constants/globalEvents";

import { loggingError } from "@/server/error-log-server";
import { getUserWorkspaceList } from "@/server/user-server";

import NavCollapse from "./NavCollapse.vue";
import NavWorkspaceItem from "./NavWorkspaceItem.vue";

export default {
  name: "NavMyWorkspaceMenu",
  components: {
    NavCollapse,
    NavWorkspaceItem,
  },
  props: {
    keyword: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fullWorkspaceList: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["getter_user_id"]),
  },
  watch: {
    keyword: {
      handler: function () {
        this.fetchMyWorkspaces();
        this.$refs.myWorkspacesRef?.forceExpand();
      },
      immediate: true,
    },
  },
  methods: {
    async fetchMyWorkspaces() {
      try {
        const { ok, data, errorMessage } = await getUserWorkspaceList({
          userId: this.getter_user_id,
          keyword: this.keyword || undefined,
        });
        if (!ok) {
          console.error({ errorMessage });
          this.fullWorkspaceList = [];
          return;
        }
        this.fullWorkspaceList = data.items;
      } catch (error) {
        console.error({ error });
        loggingError(error);
      }
    },
  },
  created() {
    eventbus.$on(EVENT.UPDATE_WORKSPACE_INFO, () => {
      this.fetchMyWorkspaces();
    });
  },
  beforeDestroy() {
    eventbus.$off(EVENT.UPDATE_WORKSPACE_INFO);
  },
};
</script>

<style lang="scss" scoped>
.NavMyWorkspaceMenu {
  &__noResult {
    color: var(--dGrey6-color);
    margin: 24px;
  }
}
</style>
