import { toTimeFormat } from "@/js/video-player/video-player-time.js";

export function getNumberWithZero({ num, targetLength = 2 }) {
  return String(num).padStart(targetLength, "0");
}

export function getPagingNumber({ current, total }) {
  const currentNum = getNumberWithZero({ num: current });
  const totalNum = getNumberWithZero({ num: total });
  return `${currentNum} / <span>${totalNum}</span>`;
}

export function formatBytes({ bytes, decimals = 2 }) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getLimitFileName({ nameLength, fileName }) {
  if (fileName.length <= nameLength) {
    return fileName;
  } else {
    return fileName.substring(0, nameLength - 8) + "..." + fileName.substring(fileName.length - 8, fileName.length);
  }
}

export function toDisplayDate(dateString) {
  return new Date(dateString).toLocaleDateString();
}

export function getFormattedDisplayDate({ timestamp }) {
  if (!timestamp) {
    return "-";
  }
  let seconds = 0;
  const isNum = isNumber(timestamp);
  if (isNum) {
    // unix timestamp: like 1666749413
    seconds = timestamp;
  } else if (Object.keys(timestamp).length === 0) {
    // {}
    return "-";
  } else {
    // { "_seconds": 1671675639, "_nanoseconds": 20000000 } { "seconds": 1671675639, "nanoseconds": 20000000 } { "seconds": 1671675639 }
    seconds = timestamp._seconds || timestamp.seconds;
  }
  const enrolledDate = new Date(seconds * 1000).toLocaleDateString();
  return enrolledDate;
}

export function isNumber(num) {
  if (num !== null && num !== "" && num !== undefined) {
    return !isNaN(num);
  }
  return false;
}

// TODO: change all refs to toTimeFormat
export function getFormattedVideoDuration({ videoDuration }) {
  return toTimeFormat(videoDuration);
}

export function formatNumberWithOverflowMax(count, max = 10) {
  if (count >= max) return `${max}+`;
  return `${count}`;
}
export function toPercent(num, total) {
  if (!num) {
    return "0%";
  } else if (num >= total) {
    return "100%";
  } else {
    return `${Math.round((num / total) * 100)}%`;
  }
}
