<template>
  <teleport :to="to">
    <slot />
  </teleport>
</template>

<script>
import Teleport from "vue2-teleport";

export default {
  name: "DTeleport",
  components: {
    Teleport,
  },
  props: {
    to: {
      type: String,
      required: true,
    },
  },
};
</script>
