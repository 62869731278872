import deephowApiServerWrapper from "@/server/api-server";
import { adminServer } from "@/server/admin-server";

const serverToken = "rG5kXk0CDbhgF4RBlNoV";

// TODO: Confirm `serverToken` or `configToken` is needed.
export const postWorkspace = async ({ data }) =>
  await adminServer.post("/admin", { action: "add", type: "new-group", token: serverToken, data });

export const putWorkspace = async ({ data }) =>
  adminServer.post("/admin", { action: "change", type: "group-change", token: serverToken, data });

/** Revamped Workspace: use deephow server */
export const getWorkspaceUserList = async ({ workspaceId, page, size, keyword }) => {
  // TODO: active/inactive
  const params = { page, size, keyword: keyword || undefined };
  return await deephowApiServerWrapper("get", `/v1/workspaces/${workspaceId}/users`, { params });
};
export const getWorkspaceTeamList = async ({ workspaceId }) =>
  await deephowApiServerWrapper("get", `/v1/workspaces/${workspaceId}/teams`);

export const getWorkspaceWorkflowList = async ({ workspaceId, ...params }) => {
  return await deephowApiServerWrapper("get", `/v1/workspaces/${workspaceId}/workflows`, { params });
};

export const getWorkspaceSkillList = async ({ workspaceId, ...params }) =>
  await deephowApiServerWrapper("get", `/v1/workspaces/${workspaceId}/skills`, { params });

export const getWorkspaceSkillMatrix = async ({ workspaceId }) =>
  await deephowApiServerWrapper("get", `/v1/workspaces/${workspaceId}/skills-matrix`);

export const getWorkspaceInsight = async ({ workspaceId }) =>
  await deephowApiServerWrapper("get", `/v1/workspaces/${workspaceId}/insights`);

export const getWorkspaceMMSOPList = async ({ workspaceId, ...params }) =>
  await deephowApiServerWrapper("get", `/v1/workspaces/${workspaceId}/multimodal-sops`, { params });
