<template>
  <div class="NavCollapse" :class="!isExpand && 'NavCollapse--collapse'">
    <div class="NavCollapse__title-block" @click="toggleExpand">
      <div class="NavCollapse__title">{{ title }}</div>
      <font-awesome-icon
        :icon="['fas', 'chevron-up']"
        class="NavCollapse__expand-icon"
        :class="!isExpand && 'NavCollapse__expand-icon--collapse'"
      />
    </div>

    <div v-show="isExpand" class="NavCollapse__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "NavCollapse",
  props: {
    title: {
      type: String,
      default: "",
    },
    defaultExpand: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isExpand: this.defaultExpand,
    };
  },
  methods: {
    toggleExpand() {
      this.isExpand = !this.isExpand;
    },
    // expose
    forceExpand() {
      this.isExpand = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.NavCollapse {
  display: flex;
  flex-direction: column;
  user-select: none;
  flex: 1;
  &--collapse {
    min-height: 54px;
    flex: 0 0 auto;
  }
  .NavCollapse__title-block {
    flex: 0 0 54px;
  }
}

.NavCollapse__title-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 24px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  .NavCollapse__title {
    flex: 1 1 auto;
    text-align: start;
    font-size: 16px;
    font-weight: 500;
    color: var(--brand-white);
    @include truncate-text();
  }
  .NavCollapse__expand-icon {
    flex: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: var(--brand-white);
    transition: transform 0.2s;
    &--collapse {
      transform: rotate(90deg);
    }
  }
}
</style>
