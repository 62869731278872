<template>
  <section class="DPageContent" :class="{ 'DPageContent--stickyTabs': isPageLayoutStickyTabs }">
    <div v-if="showToolbar()" class="DPageContent__toolbar">
      <slot name="toolbar" />
    </div>
    <d-loading-block v-if="isLoading" class="DPageContent__loading" height="100%" />
    <template v-else>
      <div class="DPageContent__content" :style="contentStyle">
        <slot />
        <div v-if="detectScrolledToBottom" :ref="scrollBottomMarkRefName" class="DPageContent__scrollBottomMark"></div>
      </div>
      <footer v-if="showFooter()" class="DPageContent__footer">
        <slot name="footer" />
      </footer>
    </template>
  </section>
</template>
<script>
export default {
  name: "DPageContent",
  inject: ["isPageLayoutStickyTabs"],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    uniqueId: {
      type: String,
      default: Math.random().toString(16).slice(2),
    },
    detectScrolledToBottom: {
      type: Boolean,
      default: false,
    },
    contentStyle: {
      type: Object, // css object
      default: () => ({}),
    },
  },
  data() {
    return {
      observer: null,
    };
  },
  computed: {
    scrollBottomMarkRefName() {
      return `scrollBottomMark-${this.uniqueId}`;
    },
  },
  watch: {
    isLoading() {
      if (!this.isLoading && this.detectScrolledToBottom) {
        this.initIntersectionObserver();
      }
    },
  },

  methods: {
    showToolbar() {
      return !!this.$slots.toolbar;
    },
    showFooter() {
      return !!this.$slots.footer;
    },
    initIntersectionObserver() {
      this.cleanIntersectionObserver();

      // use nextTick to ensure DOM is ready
      this.$nextTick(() => {
        const options = {
          root: null, // viewport
          rootMargin: "0px",
          threshold: 1.0, // when scrollBottomMark is visible
        };
        this.observer = new IntersectionObserver((entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) this.$emit("scrolled-to-bottom", this.uniqueId);
        }, options);
        this.observer.observe(this.$refs[this.scrollBottomMarkRefName]);
      });
    },

    cleanIntersectionObserver() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    },
  },

  mounted() {
    if (this.detectScrolledToBottom) {
      this.initIntersectionObserver();
    }
  },
  beforeDestroy() {
    this.cleanIntersectionObserver();
  },
};
</script>

<style lang="scss" scoped>
$padding-x: var(--content-padding-lg);

.DPageContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  text-align: initial;
  &--stickyTabs {
    overflow: unset;
    height: auto;
    flex: 1;
    .DPageContent__content {
      overflow-y: unset;
    }
  }
}

.DPageContent__loading {
  flex: 1;
}

.DPageContent__content {
  flex: 1;
  color: var(--brand-white);
  padding: 20px $padding-x;
  @include mobile {
    padding: 15px 24px 70px 24px;
  }
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include scrollbar();
}
.DPageContent__scrollBottomMark {
  height: 0px;
}

.DPageContent__toolbar,
.DPageContent__footer {
  flex: 0 0 auto;
  padding: 20px $padding-x;
  @include mobile {
    padding: 15px 24px 110px 24px;
  }
}
</style>
