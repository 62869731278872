import deephowApiServerWrapper from "@/server/api-server";

/** User Likes - Workspace */
export const setWorkspaceFavorite = ({ workspaceId, like }) =>
  deephowApiServerWrapper("post", "/v1/user-likes/workspaces", { workspaceId, like });

/** User Likes - Workflow */
export const setWorkflowLike = async ({ like, id }) =>
  deephowApiServerWrapper("post", "/v1/user-likes/workflow", { like, workflowId: id });

export const getUserLikesWorkflows = async ({ userId, keyword, page, size }) =>
  deephowApiServerWrapper("get", `/v1/user-likes/${userId}/workflows`, { params: { keyword, page, size } });

/** User Likes - MMSOP */
export const setMMSOPLike = async ({ like, id }) =>
  deephowApiServerWrapper("post", "/v1/user-likes/multimodal-sops", { like, multimodalSopId: id });

export const getUserLikesMMSOPs = async ({ userId, keyword, page, size }) =>
  deephowApiServerWrapper("get", `/v1/user-likes/${userId}/multimodal-sops`, { params: { keyword, page, size } });
