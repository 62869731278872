<template>
  <div class="CommentReactAddBtn">
    <button
      ref="addReactBtn"
      class="CommentReactAddBtn__addBtn"
      :class="{ 'CommentReactAddBtn__addBtn--active': showPickerPopup }"
      @touchstart="ontouchstartReactBtn()"
      @click="onclickReactBtn()"
    >
      <font-awesome-icon :icon="['fal', 'face-smile-plus']" class="text-white" />
    </button>
    <div v-if="showPickerPopup">
      <comment-reaction-picker
        @add-reaction="$emit('add-reaction', $event)"
        @close-picker="showPickerPopup = false"
        :pickerPosition="pickerPosition"
        :isMobileDevice="isMobileDevice"
      />
    </div>
  </div>
</template>

<script>
import CommentReactionPicker from "@/components/DWorkflowPlayer/comments/CommentReactionPicker.vue";
import { mapGetters } from "vuex";

export default {
  name: "CommentReactAddBtn",
  components: { CommentReactionPicker },
  props: {
    commentId: String,
    isMobileDevice: Boolean,
  },
  data() {
    return {
      pickerPosition: {
        top: null,
        left: null,
      },
      showPickerPopup: false,
    };
  },
  methods: {
    ontouchstartReactBtn() {
      this.showPickerPopup = true;
    },
    onclickReactBtn() {
      if (this.isMobileDevice) return;
      this.showPickerPopup = true;
      this.setPickerPosition();
    },
    setPickerPosition() {
      const btn = this.$refs.addReactBtn;
      if (btn) {
        const pickerWidth = 346;
        const pickerHeight = 308;
        const margin = 10;
        const btnHeight = 32;
        const pageWidth = window.innerWidth;
        const pageHeight = window.innerHeight;
        const btnPosition = btn.getBoundingClientRect();
        if (pageWidth - btnPosition.left > pickerWidth) {
          this.pickerPosition.left = btnPosition.left;
        } else {
          this.pickerPosition.left = pageWidth - pickerWidth - margin;
        }
        const isOpenedSideMenu = this.getter_is_adjusted_position_by_opened_side_menu(this.$route.name);
        if (isOpenedSideMenu) {
          const SIDE_BAR_WIDTH = 252; // css --side-bar-width: 252px
          this.pickerPosition.left = this.pickerPosition.left - SIDE_BAR_WIDTH;
        }

        if (pageHeight - btnPosition.top - btnHeight - margin > pickerHeight) {
          this.pickerPosition.top = btnPosition.top + btnHeight + 4; // 4 = margin top to btn
        } else {
          this.pickerPosition.top = pageHeight - pickerHeight - margin;
        }
      }
    },
  },
  computed: {
    ...mapGetters("global", ["getter_is_adjusted_position_by_opened_side_menu"]),
  },
};
</script>

<style lang="scss" scoped>
.CommentReactAddBtn {
  position: relative;
  width: 32px;
  height: 24px;
  &__addBtn {
    position: relative;
    height: 32px;
    width: 32px;
    padding-top: 4px;
    margin-top: -5px;
    border-radius: 16px;
    font-size: 20px;
    &:hover {
      background-color: #333437;
    }
    &--active {
      background-color: #333437;
    }
  }
}
</style>
