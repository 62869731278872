<template>
  <div
    v-bind="$attrs"
    ref="triggerRef"
    class="DDropdownMenu"
    :class="{ disabled: disabled }"
    @mouseenter="handleTriggerHover(true)"
    @mouseleave="handleTriggerHover(false)"
    @click="handleTriggerClick"
  >
    <slot />

    <d-teleport to="body">
      <div
        v-if="isVisible"
        ref="menuRef"
        class="DDropdownMenu__menuWrapper"
        :style="{ ...menuStyles }"
        @mouseenter="handleMenuHover(true)"
        @mouseleave="handleMenuHover(false)"
        v-click-outside="handleClickOutside"
      >
        <div class="DDropdownMenu__menu">
          <slot name="dropdown" />
        </div>
      </div>
    </d-teleport>
  </div>
</template>

<script>
import DTeleport from "./DTeleport.vue";
import clickOutsideDirective from "@/directives/click-outside-directive.js";

export default {
  name: "DDropdownMenu",
  directives: {
    "click-outside": clickOutsideDirective,
  },
  emits: ["open-change"],
  components: {
    DTeleport,
  },
  mixins: [],
  provide() {
    return {
      getDropdown: () => ({
        disabled: this.disabled,
        hideOnClick: this.hideOnClick,
        hideDropdown: this.hideDropdown,
      }),
    };
  },
  props: {
    trigger: {
      type: String, // 'hover' | 'click',
      default: "hover",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideOnClick: {
      type: Boolean,
      default: true,
    },
    offsetY: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      isMenuHovered: false,
      isVisible: false,
      menuRef: null,
      menuStyles: {
        top: "0px",
        left: "0px",
        maxHeight: "200px",
        width: "auto",
      },
      hoverTimer: null,
    };
  },
  computed: {
    triggerRef() {
      return this.$refs.triggerRef;
    },
  },
  watch: {
    isVisible() {
      if (this.isVisible) {
        setTimeout(() => {
          this.menuRef = this.$refs.menuRef;
          this.updateMenuPosition();
          this.addListeners();
        }, 0);
      } else {
        this.menuRef = null;
        this.isMenuHovered = false;
        this.cleanupListeners();
      }
      this.$emit("open-change", this.isVisible);
    },
  },
  methods: {
    handleClickOutside($event) {
      this.hideDropdown();
    },
    handleMenuHover(hovered) {
      if (this.trigger !== "hover") return;
      this.isMenuHovered = hovered;
      if (!hovered) {
        this.hideDropdown();
      }
    },
    handleTriggerHover(isOpen) {
      clearTimeout(this.hoverTimer);
      if (this.disabled || this.trigger !== "hover") return;

      this.hoverTimer = setTimeout(() => {
        if (this.isMenuHovered) return;
        this.handleToggleDropdown(isOpen);
      }, 200); // Add a delay before hiding to detect isMenuHovered
    },
    handleTriggerClick() {
      if (this.disabled) return;
      this.handleToggleDropdown(!this.isVisible);
    },
    hideDropdown() {
      this.isVisible = false;
    },
    handleToggleDropdown(isOpen) {
      this.isVisible = isOpen;
    },
    addListeners() {
      window.addEventListener("resize", this.updateMenuPosition);
      document.addEventListener("scroll", this.updateMenuPosition, true);
    },
    cleanupListeners() {
      window.removeEventListener("resize", this.updateMenuPosition);
      document.removeEventListener("scroll", this.updateMenuPosition, true);
    },
    updateMenuPosition() {
      if (!this.triggerRef || !this.menuRef) {
        return;
      }

      // handle offsetY < 0 will effect menu position
      const paddingY = Math.max(this.offsetY, 0);
      const offsetY = Math.min(this.offsetY, 0);

      const TOLERANCE = 20;
      const triggerRect = this.triggerRef.getBoundingClientRect();
      const menuRect = this.menuRef.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const maxWidth = viewportWidth - TOLERANCE * 2;
      const menuWidth = Math.max(triggerRect.width, Math.min(menuRect.width, maxWidth));
      let top = triggerRect.bottom + offsetY;
      let left = triggerRect.left;
      let maxHeight = viewportHeight - triggerRect.bottom - TOLERANCE;

      // overflow right side
      if (triggerRect.left + menuWidth > viewportWidth) {
        left = Math.max(viewportWidth - menuWidth - TOLERANCE, TOLERANCE);
      }
      // overflow bottom
      if (triggerRect.bottom + menuRect.height > viewportHeight) {
        top = triggerRect.top - menuRect.height;
        maxHeight = triggerRect.top - TOLERANCE;
      }

      this.menuStyles = {
        padding: `${paddingY}px 0`,
        top: `${top}px`,
        left: `${left}px`,
        maxHeight: `${maxHeight}px`,
        width: `${menuWidth}px`,
      };
    },
  },
  beforeDestroy() {
    this.cleanupListeners();
  },
};
</script>

<style lang="scss" scoped>
.DDropdownMenu {
  display: inline-flex;
  cursor: pointer;
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.DDropdownMenu__menuWrapper {
  position: fixed;
  z-index: var(--z-index-dropdown);
}
.DDropdownMenu__menu {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid var(--dGrey4-color);
  background-color: var(--dGrey2-color);
  border-radius: 8px;
  padding: 8px;

  @include scrollbar();
}
</style>
