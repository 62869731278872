<template>
  <!--
    Component: DPageLayout
    Description: always use this component along with "DPageContent" to achieve the best layout effect
    Example:
    <d-page-layout title="page title" routerTabs="tabs">
      <template slot="header">If use custom header</template>
      <template slot="tabs"> If use custom tabs that don't require router effect </template>
      <d-page-content>
        <template slot="footer"> If need fixed footer, e.g., pagination </template>
      <d-page-content>
    </d-page-layout>
  -->
  <div class="DPageLayout" :class="{ 'DPageLayout--stickyTabs': isStickyTabs }">
    <header v-if="showHeader()" class="DPageLayout__header">
      <slot name="header">
        <div class="DPageLayout__header-title">{{ title }}</div>
      </slot>
    </header>

    <d-loading-block v-if="isLoading" height="100%" class="DPageLayout__loading" />
    <template v-else>
      <section v-if="showTabs()" class="DPageLayout__tabs" :style="tabStyle">
        <slot name="tabs">
          <d-tabs
            :value="currentTabIndex"
            :ids="ids"
            :autotest="autotests"
            :tabNames="tabNames"
            height="44"
            autoWidth
            @input="handleTabIndexChanged"
          />
        </slot>
      </section>

      <slot />
    </template>
  </div>
</template>

<script>
import DTabs from "@/components/ui_components/DTabs.vue";

export default {
  name: "DPageLayout",
  components: {
    DTabs,
  },
  mixins: [],
  provide() {
    return {
      isPageLayoutStickyTabs: this.isStickyTabs,
    };
  },
  props: {
    isStickyTabs: {
      type: Boolean,
      default: false,
    },
    tabStyle: {
      type: Object, // css object
      default: () => ({}),
    },
    title: {
      type: String,
      default: "",
    },
    routerTabs: {
      type: Array, // { key: string; name: string; autotest?: string }[]
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTabKey: this.routerTabs[0]?.key || "",
    };
  },
  computed: {
    autotests() {
      return this.routerTabs.map((tab) => tab.autotest || "");
    },
    tabNames() {
      return this.routerTabs.map((tab) => tab.name);
    },
    currentTabIndex() {
      return this.routerTabs?.findIndex((tab) => tab.key === this.currentTabKey);
    },
    ids() {
      return this.routerTabs.map((tab) => tab.id || undefined);
    },
  },
  watch: {
    $route: {
      handler: function (to) {
        if (!this.routerTabs) return;
        if (this.$route.query.dontChangeTab) return;

        const targetTabIndex = this.routerTabs.findIndex((tab) => tab.key === to.name);
        if (targetTabIndex === this.currentTabIndex) return; // same index, don't trigger event
        if (targetTabIndex === -1) targetTabIndex = this.currentTabIndex; // target not exist, default to current one
        this.handleTabIndexChanged(targetTabIndex);
      },
      immediate: true,
    },
  },
  methods: {
    showHeader() {
      return !!this.$slots.header || !!this.title;
    },
    showTabs() {
      return !!this.$slots.tabs || this.routerTabs.length > 0;
    },
    handleTabIndexChanged(index) {
      if (this.routerTabs[index]) {
        this.currentTabKey = this.routerTabs[index].key;
        this.$emit("tab-change", this.currentTabKey);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$padding-x: var(--content-padding-lg);

.DPageLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  text-align: initial;
  &--stickyTabs {
    overflow-y: scroll;
    .DPageLayout__tabs {
      background-color: var(--dGrey1-color);
      position: sticky;
      top: 0;
      z-index: var(--z-index-page-layout-sticky-tabs);
    }
  }
}

.DPageLayout__header {
  flex: 0 0 auto;
  margin: 12px 0px;
  padding: 0px $padding-x;
  @include mobile {
    padding: 0 24px;
  }
  &-title {
    color: var(--brand-white);
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.4px;
    line-height: normal;
  }
}
.DPageLayout__loading {
  flex: 1;
}
.DPageLayout__tabs {
  flex: 0 0 auto;
  margin-top: 20px;
  padding: 0px $padding-x;
  @include mobile {
    padding: 0 24px;
  }
}
.DPageLayout__content {
  flex: 1;
  overflow: hidden;
}
</style>
