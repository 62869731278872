import services from "@/constants/env/services";
import deephowApiServerWrapper from "@/server/api-server";
import axios from "axios";
import authChoice from "../../auth";
import { apiWrapperGenerator } from "./lib";

const workflowServer = axios.create({
  baseURL: services.workflow,
});

workflowServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

const workflowServerApiWrapper = apiWrapperGenerator(workflowServer);

export const downloadScormFormat = async (workflowId, resolution) =>
  deephowApiServerWrapper("post", `/v1/workflows/${workflowId}/download-scorm`, { resolution });

export const getUserQuizByWorkflowId = async ({ id, skillId }) =>
  deephowApiServerWrapper("get", `/v1/workflows/${id}/user-quiz`, { params: { userOrTeamSkillId: skillId } });

export const getWorkflowSummary = async (workflowId) =>
  deephowApiServerWrapper("get", `/v1/workflows/${workflowId}/ai-summary`);

export const getWokrflowQuiz = async (workflowId) => deephowApiServerWrapper("get", `v1/workflows/${workflowId}/quiz`);

export const postGenerateQuizByAI = async ({ workflowId, numberOfQuestions, questionTypes }) =>
  deephowApiServerWrapper("post", `v1/workflows/${workflowId}/ai-generate-quiz`, { numberOfQuestions, questionTypes });

// TODO: COMBINE TO DeephowApiServer
export const resetWorkflow = (workflowId) => workflowServerApiWrapper("post", `/v1/workflows/${workflowId}/reset`);
